import styled from 'styled-components';
import Tag from '@pedidosya/web-fenix/atoms/Tag';
import IconButton from '@pedidosya/web-fenix/atoms/IconButton';

export const VendorsSwiperTitle = styled.div`
  text-align: left;
  margin: 0;
  padding: ${({ theme }) => `${theme.space('spacing-15')} ${theme.space('spacing-15')} 0`};
`;

export const VendorSwiperContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: ${({ theme }) => `0 ${theme.space('spacing-08')} ${theme.space('spacing-09')}`};
  gap: ${({ theme }) => theme.space('spacing-06')};

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const VendorsSwiperContent = styled.div`
  background-image: url(${({ backgroundImage }) => backgroundImage});
  box-shadow: inset -70px 0 30px 20px #f9f9f9;
  background-size: cover;
  padding: ${({ theme }) => `${theme.space('spacing-17')} 0`};
`;

export const VendorWrapper = styled.div`
  margin: ${({ theme }) => `0 ${theme.space('spacing-2.5')} 0`};
  min-width: 78px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AdditionalVendorButton = styled(IconButton)`
  margin: ${({ theme }) => `${theme.space('spacing-4.5')} ${theme.space('spacing-02')}`};
  border-radius: ${({ theme }) => theme.borderRadius('border-radius-circle')}};
`;

export const TagWrapper = styled(Tag)`
  text-transform: uppercase;
  margin-top: -4px;
  white-space: nowrap;
`;

export const ThumbnailImageContent = styled.div`
  > div {
    outline-width: 2px;
    outline-offset: -2px;
  }
`;
