import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import fenixTheme from '@pedidosya/web-fenix/theme';
import { BottomSheetTitle, BottomSheetSubtitle } from '../styled-components';
import messages from '../../messages';

export const CongratsBottomSheetComponent = ({ user, intl }) => {
  const getTitle = () => {
    const message = intl.formatMessage(messages.title);

    return `¡${message}!`;
  };

  return (
    <div>
      <BottomSheetTitle
        component="h1"
        token="font-headline-highcontrast-small"
        color={fenixTheme.color('text-color-primary')}
      >
        {user.first_name && (
          <>
            {user.first_name} <br />
          </>
        )}
        {getTitle()}
      </BottomSheetTitle>

      <BottomSheetSubtitle
        component="h2"
        token="font-body-midcontrast-sentence-medium"
        color={fenixTheme.color('text-color-secondary')}
      >
        <FormattedMessage {...messages.subtitle} />
      </BottomSheetSubtitle>
    </div>
  );
};

CongratsBottomSheetComponent.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
  }),
};

export const CongratsBottomSheet = injectIntl(CongratsBottomSheetComponent);
