import React from 'react';

const PromotionItemContainer = ({ promotion, onShowInternalTC, className, children, ...props }) => {
  return (
    <>
      {promotion.terms_and_conditions?.is_external ? (
        <a href={promotion.terms_and_conditions.url} className={className} {...props}>
          {children}
        </a>
      ) : (
        <div
          onClick={onShowInternalTC}
          className={className}
          {...props}
          role="button"
          tabIndex="-1"
        >
          {children}
        </div>
      )}
    </>
  );
};

export default PromotionItemContainer;
