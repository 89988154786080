import { BrowserClient, Hub } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Severity } from '@sentry/browser';
import { PROD_ENVIRONMENT } from '@appscore/web-constants/environments';
import SentryLogger from './logger';

let sentryInstance = null;
let logger = null;

export const init = ({ environment }) => {
  try {
    const isLive = environment === PROD_ENVIRONMENT;

    const client = new BrowserClient({
      dsn: SENTRY_DSN,
      environment,
      integrations: [
        new Integrations.BrowserTracing({
          _metricOptions: {
            _reportAllChanges: true,
          },
        }),
      ],
      release: `${APP_NAME}@${APP_VERSION}`,
      tracesSampleRate: isLive ? 0.2 : 1,
      sampleRate: isLive ? 0.2 : 1,
    });

    sentryInstance = new Hub(client);
    logger = new SentryLogger(sentryInstance, isLive ? Severity.Error : Severity.Info);

    return sentryInstance;
  } catch (error) {
    // Log to console.error because at this point the Sentry instance did not work
    console.error(error);
    throw error;
  }
};

export const log = (level, message, data) => {
  if (!sentryInstance) throw new Error('[Sentry] Cannot log before init');

  logger.log(level, message, data);
};

export const setTag = (key, value) => {
  if (!sentryInstance) throw new Error('[Sentry] Cannot set tag before init');

  sentryInstance.setTag(key, value);
};

export const setUser = (user) => {
  if (!sentryInstance) throw new Error('[Sentry] Cannot set user before init');

  sentryInstance.setUser(user);
};
