import React from 'react';
import PropTypes from 'prop-types';
import { HeaderWrapper, Content } from './styled-components';
import { FixedHeader } from './FixedHeader';
import useBackNavigation from '../../hooks/useBackNavigation';
import { useIsWebviewIos } from '@app/contexts/deviceInfo/hooks';
import { IMAGE_URL_DOMAIN } from '@commons/constants/imagesDomain';

const backgroundImage = `${IMAGE_URL_DOMAIN}/image/pedidosya/loyalty/loyalty-pedidosya-header.png`;

export const Header = ({ isCancelled, children }) => {
  const handleBack = useBackNavigation({ isHistoryRoot: true });
  const isWebviewIos = useIsWebviewIos();

  return (
    <>
      <FixedHeader
        backgroundImage={backgroundImage}
        handleBack={handleBack}
        isWebviewIos={isWebviewIos}
      />
      <HeaderWrapper
        backgroundImage={backgroundImage}
        isActive={!isCancelled}
        isWebviewIos={isWebviewIos}
      >
        <Content>{children}</Content>
      </HeaderWrapper>
    </>
  );
};

Header.propTypes = {
  children: PropTypes.node,
};
