import React from 'react';
import PropTypes from 'prop-types';
import { useIntersect } from '@app/hooks/useIntersect';
import { HeaderWrapper, Content } from './styled-components';
import { useIsWebviewIos } from '@app/contexts/deviceInfo/hooks';
import { FixedHeader } from './FixedHeader';
import { IMAGE_URL_DOMAIN } from '@commons/constants/imagesDomain';

const backgroundImage = `${IMAGE_URL_DOMAIN}/image/pedidosya/loyalty/loyalty-pedidosya-header.png`;

export const Header = ({ onGoBack, children }) => {
  const isWebviewIos = useIsWebviewIos();
  const [intersectRef, entry] = useIntersect({ threshold: 0 });

  return (
    <>
      <FixedHeader
        backgroundImage={backgroundImage}
        handleBack={onGoBack}
        isWebviewIos={isWebviewIos}
        showTitle={entry.isIntersecting === false}
      />
      <HeaderWrapper
        backgroundImage={backgroundImage}
        isWebviewIos={isWebviewIos}
        ref={intersectRef}
      >
        <Content>{children}</Content>
      </HeaderWrapper>
    </>
  );
};

Header.propTypes = {
  onGoBack: PropTypes.func,
  children: PropTypes.node,
};
