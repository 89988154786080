import axios from 'axios';
import logger from '@app/logger';

export const fetchStatusPayments = async (planId) => {
  try {
    const statusResponse = await axios.get(`/loyalty/api/status/payments`, { params: { planId } });
    return statusResponse.data;
  } catch (error) {
    logger.error('Error getting status payments data');
    throw error;
  }
};
