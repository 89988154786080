import styled from 'styled-components';

export const BenefitList = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;
`;

export const BenefitListItem = styled.li`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.space('spacing-component-large')} 0`};
  gap: ${({ theme }) => theme.space('spacing-component-medium')};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${({ theme }) => `0 0 0 ${theme.space('spacing-component-small')}`};
`;

export const BenefitDetailIconContainer = styled.div`
  min-width: 64px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space('spacing-component-small')};
  padding-bottom: ${({ theme }) => theme.space('spacing-component-medium')};
`;
