import React from 'react';
import PropTypes from 'prop-types';
import Image from '@pedidosya/web-fenix/foundations/Image';
import { NavbarWrapper, TopAppBarWrapper } from './styled-components';
import { IMAGE_URL_DOMAIN } from '@commons/constants/imagesDomain';

const pedidosYaPlus = `${IMAGE_URL_DOMAIN}/image/pedidosya/loyalty/brand-peya-plus.png`;

export const FixedHeader = ({ backgroundImage, handleBack, isWebviewIos }) => {
  return (
    <NavbarWrapper backgroundImage={backgroundImage} isWebviewIos={isWebviewIos}>
      <TopAppBarWrapper
        onGoBack={handleBack}
        category="inverted"
        title={<Image src={pedidosYaPlus} alt="PedidosYa Plus" width={167} />}
      />
    </NavbarWrapper>
  );
};

FixedHeader.propTypes = {
  backgroundImage: PropTypes.string,
  handleBack: PropTypes.func,
};
