import { Severity } from '@sentry/browser';

const levels = {
  error: Severity.Error,
  warn: Severity.Warning,
  info: Severity.Info,
  debug: Severity.Debug,
};

const sentryLevels = {
  [Severity.Error]: 0,
  [Severity.Warning]: 1,
  [Severity.Info]: 2,
  [Severity.Debug]: 3,
};

class SentryLogger {
  constructor(sentry, logLevel) {
    this.sentry = sentry;
    this.logLevel = levels[logLevel];
  }

  log(logLevel, message, data) {
    const sentryLevel = levels[logLevel];

    if (sentryLevels[sentryLevel] > sentryLevels[this.logLevel]) return;

    this.sentry.withScope((scope) => {
      scope.setLevel(sentryLevel);
      scope.setFingerprint([message]);
      if (data) scope.setExtra('data', data);
      this.sentry.captureMessage(message);
    });
  }
}

export default SentryLogger;
