import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Card from '@pedidosya/web-fenix/molecules/CardContainer';
import Divider from '@pedidosya/web-fenix/atoms/Divider';
import Button from '@pedidosya/web-fenix/atoms/Button';
import FenixBottomSheet from '@pedidosya/web-fenix/organisms/BottomSheet';
import Image from '@pedidosya/web-fenix/foundations/Image';
import {
  Container,
  PlanPriceContainer,
  PriceContainer,
  CardContent,
  ButtonContainer,
} from './styled-components';
import PaymentMethod from '@app/components/PaymentMethod';
import getPaymentMethodLogo from '@app/utils/images/paymentMethodLogo';
import { TRACKING_CLICK_LOCATIONS } from '@commons/constants/tracker';
import { DISCOUNT_TYPE } from '@commons/models/discountTypeEnum';
import { getUnitIdFromValue } from '@commons/models/durationUnitEnum';
import differenceInCalendarDays from '@commons/utils/date/differenceInCalendarDays';
import { formatDateToDayMonth } from '@commons/utils/date/date';
import { isPercentage, isFreeTrial } from '@commons/utils/markdowns';
import { reactivateSubscription } from '@app/services/loyalty/reactivateSubscription';
import ReactivateSubsFeedback from './ReactivateSubsFeedback';
import { PlanTitle } from '@app/components/PlanTitle';
import { Price } from '@app/components/PlanPrice';

import messages from '../../messages';
import landingMessage from '../../../landing/components/plan/messages';

const paymentMethodTypes = {
  CREDIT: 'Crédito',
  DEBIT: 'Débito',
};

const getDiscountText = (amount, discountType) => {
  if (discountType === DISCOUNT_TYPE.PERCENTAGE) {
    return `${amount}%`;
  } else {
    return amount;
  }
};

const BUTTON_LABEL = 'Reactivar suscripción';

export const ReactivateSubscription = ({
  open,
  onClose,
  subscription,
  onSubscriptionUpdate,
  trackClick,
}) => {
  const [isOpenReactivateMessage, setIsOpenReactivateMessage] = useState(false);
  const [reactivateMessageType, setReactivateMessageType] = useState('positive');
  const intl = useIntl();

  const { currency_symbol, monthly_fee, purchase, next_charge_date, markdown } = subscription;
  const { brand, last_digits, type } = purchase;
  const {
    discount_amount,
    discount_type,
    duration_value,
    duration_unit,
    for_reactivation,
    monthly_fee_after_markdown,
  } = markdown || {};
  const freeTrialMarkdown = isFreeTrial(markdown);
  const percentageMarkdowns = isPercentage(markdown);

  const icon = getPaymentMethodLogo(brand);

  const getDiscount = (discount) => {
    if (freeTrialMarkdown) {
      if (for_reactivation) {
        return '1 mes gratis';
      } else return 'Gratis';
    } else return discount;
  };

  const getPlanDescription = () => {
    const discount = percentageMarkdowns
      ? intl.formatMessage(messages.planDiscountTitle, {
          discountAmountText: getDiscountText(Math.floor(discount_amount), discount_type),
        })
      : null;

    return (
      <PlanTitle
        title={
          <Typography token="font-subtitle-highcontrast-sentence-medium" color="text-color-primary">
            <FormattedMessage {...messages.planTitle} />
          </Typography>
        }
        discount={getDiscount(discount)}
      />
    );
  };

  const getPlanAmount = () => {
    const actualAmount = markdown ? monthly_fee_after_markdown : monthly_fee;
    const beforeAmount = markdown ? monthly_fee : null;
    return (
      <Price
        symbol={currency_symbol}
        actualAmount={actualAmount}
        beforeAmount={beforeAmount}
        amountSize="medium"
      />
    );
  };

  const getSubtitleMessage = () => {
    if (!markdown) return intl.formatMessage(messages.reactivateSubscriptionSubtitle);
    if (!for_reactivation)
      return intl.formatMessage(messages.reactivateSubscriptionSubtitleMarkdown, {
        remainingDate: formatDateToDayMonth(next_charge_date),
      });
    return freeTrialMarkdown
      ? intl.formatMessage(messages.reactivateSubscriptionSubtitleMarkdownNewFree, {
          duration: intl.formatMessage(landingMessage.durationText, {
            value: duration_value,
            unit: getUnitIdFromValue(duration_unit),
          }),
        })
      : intl.formatMessage(messages.reactivateSubscriptionSubtitleMarkdownNewPerc, {
          duration: intl.formatMessage(landingMessage.durationText, {
            value: duration_value,
            unit: getUnitIdFromValue(duration_unit),
          }),
          percentage: discount_amount,
        });
  };

  const remainingDays = differenceInCalendarDays(new Date(next_charge_date), new Date(Date.now()));

  const mutation = useMutation({
    mutationFn: () => reactivateSubscription(),
    onSuccess: () => {
      onSubscriptionUpdate();
      setReactivateMessageType('positive');
      setIsOpenReactivateMessage(true);
    },
    onError: () => {
      setReactivateMessageType('error');
      setIsOpenReactivateMessage(true);
    },
  });

  const onReactivateSubs = () => {
    trackClick(TRACKING_CLICK_LOCATIONS.REACTIVATE_SUBSCRIPTION);
    mutation.mutate();
    onClose();
  };

  return (
    <>
      {open && (
        <FenixBottomSheet
          handler={false}
          open={open}
          onClickOut={onClose}
          onClose={onClose}
          title={<FormattedMessage {...messages.reactivateSubscription} />}
        >
          <Container>
            <Typography token="text-base-large">{getSubtitleMessage()}</Typography>
            <Card withShadow={false}>
              <CardContent>
                <PlanPriceContainer>
                  {getPlanDescription()}
                  <PriceContainer>{getPlanAmount()}</PriceContainer>
                </PlanPriceContainer>
                <Divider />
              </CardContent>
              <PaymentMethod
                lastDigits={last_digits}
                type={paymentMethodTypes[type]}
                subtitle={
                  remainingDays >= 0 && (
                    <FormattedMessage
                      {...messages.activeSubtitle}
                      values={{ isFirst: false, remainingDays }}
                    />
                  )
                }
                icon={<Image height={24} width={41} alt="card-logo" src={icon} />}
                changeInstrument={{
                  enabled: false,
                  reason: null,
                }}
              />
            </Card>
            <ButtonContainer>
              <Button
                label={BUTTON_LABEL}
                size="large"
                fullWidth
                onClick={() => onReactivateSubs()}
              />
            </ButtonContainer>
          </Container>
        </FenixBottomSheet>
      )}
      <ReactivateSubsFeedback
        type={reactivateMessageType}
        onCloseSnackbar={() => setIsOpenReactivateMessage(false)}
        showSnackbar={isOpenReactivateMessage}
      />
    </>
  );
};

ReactivateSubscription.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  subscription: PropTypes.object,
  onSubscriptionUpdate: PropTypes.func,
  trackClick: PropTypes.func,
};
