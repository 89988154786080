import { MARKDOWN_TYPE } from '@commons/models/makdownTypeEnum';

export function hasMarkdown(markdowns) {
  return markdowns && markdowns.length > 0;
}

export function hasFreeTrial(markdowns) {
  return hasMarkdown(markdowns) && markdowns.some((x) => isFreeTrial(x));
}

export function hasPercentage(markdowns) {
  return hasMarkdown(markdowns) && markdowns.some((x) => isPercentage(x));
}

export function isFreeTrial(markdown) {
  return markdown && markdown.type === MARKDOWN_TYPE.FREE_TRIAL;
}

export function isPercentage(markdown) {
  return markdown && markdown.type === MARKDOWN_TYPE.PERCENTAGE;
}
