import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { changePaymentInstrument } from '@app/services/loyalty/changePaymentInstrument';
import useLocationQuery from '@app/hooks/useLocationQuery';
import { ROUTES } from '@commons/services/routes.service';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import Spinner from '../../components/Spinner';
import { useInstrumentChangeSuccessTracker } from './tracking/PageTracker';

export const PaymentInstrumentChangeSuccess = () => {
  const history = useHistory();
  const { paymentInstrumentsSelected } = useLocationQuery();
  const queryClient = useQueryClient();
  const { trackInstrumentChange } = useInstrumentChangeSuccessTracker();

  const mutation = useMutation({
    mutationFn: () => changePaymentInstrument(paymentInstrumentsSelected),
    onSuccess: () => {
      trackInstrumentChange(paymentInstrumentsSelected);
      queryClient.removeQueries(queryKeyBuilders.statusDetail());
      history.replace(ROUTES.STATUS_DETAILS, {
        userFeedback: {
          label: 'Cambiaste el medio de pago de tu suscripción.',
          type: 'positive',
        },
      });
    },
    onError: () => {
      trackInstrumentChange(paymentInstrumentsSelected, true);
      history.replace(ROUTES.STATUS_DETAILS, {
        userFeedback: {
          label: 'No pudimos actualizar el medio de pago.',
          type: 'error',
        },
      });
    },
  });

  useEffect(() => {
    mutation.mutate();
    // Force doing it only once at component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Spinner />;
};
