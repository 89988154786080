import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.landing.faq.title',
    defaultMessage: 'Sumate a Plus sin dudarlo',
  },
  conditionsQuestion: {
    id: 'app.faq.conditionsQuestion',
    defaultMessage: '¿Qué tener en cuenta para los envíos gratis o con descuento?',
  },
  conditionsAnswer: {
    id: 'app.faq.conditionsAnswer',
    defaultMessage:
      'Tu pedido debe cumplir con los montos mínimos establecidos tanto para restaurantes como para mercados, el pago puede ser online o en efectivo y la entrega debe estar a cargo de PedidosYa. La mayoría de los locales disponibles en la app cumplen con estas condiciones.',
  },
  marketsQuestion: {
    id: 'app.faq.marketsQuestion',
    defaultMessage: '¿Qué tener en cuenta para los beneficios de PedidosYa Market?',
  },
  marketsAnswer: {
    id: 'app.faq.marketsAnswer',
    defaultMessage:
      'Podrás utilizar el beneficio siempre y cuando tengas un Market disponible en tu zona. Casi siempre tendrás uno a disposición, pero lo tenemos que aclarar por las dudas',
  },
  unsubscribeQuestion: {
    id: 'app.faq.unsubscribeQuestion',
    defaultMessage: '¿Puedo cancelar cuando quiera?',
  },
  unsubscribeAnswer: {
    id: 'app.faq.unsubscribeAnswer',
    defaultMessage:
      'Sí. Estamos seguros de que vas a querer ser Plus por siempre, pero si necesitás dar de baja la suscripción, podrás hacerlo en cualquier momento y en menos de 1 minuto.',
  },
  citiesQuestion: {
    id: 'app.faq.citiesQuestion',
    defaultMessage: '¿En qué ciudades funciona PedidosYa Plus?',
  },
  citiesAnswer: {
    id: 'app.faq.citiesAnswer',
    defaultMessage:
      'El programa está habilitado solo en algunas ciudades del país: {cities}. Fuera de estas ciudades los beneficios no estarán disponibles.',
  },
  allCitiesAnswer: {
    id: 'app.faq.allCitiesAnswer',
    defaultMessage:
      'El programa está habilitado para todas las ciudades de {country}. Fuera del país los beneficios no estarán disponibles.',
  },
  freeTrialQuestion: {
    id: 'app.faq.freeTrialQuestion',
    defaultMessage: '¿Cómo funciona la prueba gratuita?',
  },
  freeTrialAnswer: {
    id: 'app.faq.freeTrialAnswer',
    defaultMessage:
      'Suscribite y probá gratis el programa. Al confirmar, se generará un cargo de {amount} en tu tarjeta pero será devuelto automáticamente. Podrás cancelar en cualquier momento.',
  },
});
