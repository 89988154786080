import axios from 'axios';
import logger from '@app/logger';

export const fetchPlanTC = async ({ planId }) => {
  try {
    const tcResponse = await axios.get(`/loyalty/api/plans/${planId}/tyc`);
    return tcResponse.data;
  } catch (error) {
    logger.error(`Error getting T&C for plan ${planId}`);
    throw error;
  }
};
