import styled, { css } from 'styled-components';

export const StyledSpinner = styled.div`
  ${({ fullScreen }) =>
    fullScreen
      ? css`
          right: 50%;
          bottom: 50%;
          transform: translate(50%, 50%);
          position: absolute;
        `
      : css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
        `}}
`;
