import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Container, DetailPageWrapper as Wrapper } from './styled-components';
import Divider from '@pedidosya/web-fenix/atoms/Divider';
import Button from '@pedidosya/web-fenix/atoms/Button';
import FixedButtonsFooter from '@pedidosya/web-fenix/organisms/FixedButtonsFooter';
import Card from '@pedidosya/web-fenix/molecules/CardContainer';
import Image from '@pedidosya/web-fenix/foundations/Image';
import { Header } from './Header';
import { Cancel } from './Cancel';
import { PlanDetail } from './PlanDetail';
import { PaymentLink } from '../Payments';
import { useDeviceInfo } from '@app/contexts/deviceInfo';
import { useIsWebviewIos } from '@app/contexts/deviceInfo/hooks';
import { FwfFlagVariation } from '@app/contexts/FwfFlags/components';
import getPaymentMethodLogo from '@app/utils/images/paymentMethodLogo';
import { TRACKING_CLICK_LOCATIONS } from '@commons/constants/tracker';
import differenceInCalendarDays from '@commons/utils/date/differenceInCalendarDays';
import { hasFreeTrial } from '@commons/utils/markdowns';
import { SUBSCRIPTION_STATUS } from '@commons/models/subscriptionStatusEnum';
import { BOOLEAN_FLAGS } from '@commons/fwf/constants';
import messages from '../../messages';
import { SUPPORT_LINKS } from './constants';
import PaymentMethod from '@app/components/PaymentMethod';
import { Feedback } from '@app/components/Feedback';
import PaymentIssueNotification from './PaymentIssueNotification';
import AlternatePaymentCard from './AlternatePaymentCard';

const paymentMethodTypes = {
  CREDIT: 'Crédito',
  DEBIT: 'Débito',
};

const changeOtherCardsTypes = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
};

const getSupportLink = (deviceInfo) => {
  if (!deviceInfo.isWebview()) return SUPPORT_LINKS.WEB;
  if (deviceInfo.isIos()) return SUPPORT_LINKS.IOS;
  if (deviceInfo.isAndroid()) return SUPPORT_LINKS.ANDROID;

  return '';
};

export const DetailPage = ({
  subscription,
  onClose,
  trackClick = () => {},
  purchase,
  paymentInformation,
  changeInstrument,
  notification,
  paymentConfiguration,
  showCoachmark,
  onChangePaymentConfig,
  onCancelPageOpen,
}) => {
  const { plan_id, next_charge_date, status, country_id } = subscription;
  const { markdowns } = purchase;
  const intl = useIntl();
  const deviceInfo = useDeviceInfo();
  const isWebviewIos = useIsWebviewIos();

  const icon = getPaymentMethodLogo(paymentInformation.brand);

  const freeTrialMarkdown = hasFreeTrial(markdowns);
  const [first] = markdowns || [];
  const { campaign_period_valid_to } = first || {};
  const { charge_other_payment_instruments, show_terms_and_conditions } =
    paymentConfiguration || {};
  const showOtherCards = !!charge_other_payment_instruments;

  const remainingDays = differenceInCalendarDays(
    new Date(freeTrialMarkdown ? campaign_period_valid_to : next_charge_date),
    new Date(Date.now()),
  );

  const isActive = status === SUBSCRIPTION_STATUS.ACTIVE;

  const clickWithTrack = (clickLocation, clickHandler) => (args) => {
    if (trackClick && typeof trackClick === 'function') trackClick(clickLocation);
    if (clickHandler && typeof clickHandler === 'function') clickHandler(args);
  };

  return (
    <Wrapper isWebviewIos={isWebviewIos}>
      <div>
        <Header onClickGoBack={onClose} />
        <Container>
          <PlanDetail subscription={subscription} purchase={purchase} />
        </Container>
        <FwfFlagVariation
          flagKey={BOOLEAN_FLAGS.WEB_LOYALTY_PROGRAM_BILLS.KEY}
          variation={
            <PaymentLink
              planId={plan_id}
              onShowPayments={clickWithTrack(TRACKING_CLICK_LOCATIONS.PAYMENTS)}
            />
          }
          control={<></>}
        />
        {isActive && (
          <Container>
            <Divider />
            {notification && (
              <PaymentIssueNotification
                title={notification?.title}
                description={notification?.description}
                severity={notification?.severity}
              />
            )}
            <Card withShadow={false}>
              <PaymentMethod
                lastDigits={paymentInformation.last_digits}
                instrumentId={paymentInformation.instrument_id}
                type={paymentMethodTypes[paymentInformation.type]}
                changeInstrument={changeInstrument}
                subtitle={
                  remainingDays >= 0 && (
                    <FormattedMessage
                      {...messages.activeSubtitle}
                      values={{ isFirst: !!freeTrialMarkdown, remainingDays }}
                    />
                  )
                }
                icon={<Image height={24} width={41} alt="card-logo" src={icon} />}
                onChangePaymentInstrumentClick={clickWithTrack(
                  TRACKING_CLICK_LOCATIONS.CHANGE_PAYMENT_INSTRUMENT,
                )}
              />
            </Card>
            {showOtherCards && (
              <AlternatePaymentCard
                planId={plan_id}
                countryId={country_id}
                checked={charge_other_payment_instruments === changeOtherCardsTypes.ENABLED}
                showCoachmark={showCoachmark}
                onChangeMultiplePaymentCardClick={clickWithTrack(
                  TRACKING_CLICK_LOCATIONS.CHANGE_MULTIPLE_PAYMENT_CARD,
                )}
                showTerms={show_terms_and_conditions}
                onTermAndConditionClick={clickWithTrack(
                  TRACKING_CLICK_LOCATIONS.MULTIPLE_PAYMENT_CARD_TERMS,
                )}
                onChangePaymentConfig={onChangePaymentConfig}
              />
            )}
          </Container>
        )}
      </div>

      <div>
        <Feedback stateValue="userFeedback" />
        <FixedButtonsFooter withShadow={false}>
          <FixedButtonsFooter.Primary>
            <Button
              label={intl.formatMessage(messages.helpNeeded)}
              size="large"
              fullWidth
              hierarchy="primary"
              onClick={clickWithTrack(TRACKING_CLICK_LOCATIONS.HELP)}
              href={getSupportLink(deviceInfo)}
            />
          </FixedButtonsFooter.Primary>
          <FixedButtonsFooter.Secondary>
            {isActive && (
              <Cancel
                onClick={clickWithTrack(TRACKING_CLICK_LOCATIONS.CANCEL_CLICK)}
                onCancelPageOpen={onCancelPageOpen}
              />
            )}
          </FixedButtonsFooter.Secondary>
        </FixedButtonsFooter>
      </div>
    </Wrapper>
  );
};

DetailPage.propTypes = {
  subscription: PropTypes.shape({
    plan_id: PropTypes.number,
    next_charge_date: PropTypes.string,
    status: PropTypes.string,
    start_date: PropTypes.string,
    currency_symbol: PropTypes.string,
  }),
  purchase: PropTypes.shape({
    markdowns: PropTypes.array,
    amount: PropTypes.number,
  }),
  paymentInformation: PropTypes.shape({
    brand: PropTypes.string,
    last_digits: PropTypes.string,
    type: PropTypes.string,
    instrument_id: PropTypes.string,
  }),
  changeInstrument: PropTypes.object,
  onClose: PropTypes.func,
  onUnsubscribe: PropTypes.func,
  notification: PropTypes.object,
};
