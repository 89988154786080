import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@pedidosya/web-fenix/atoms/Divider';
import { Benefit } from './Benefit';
import { BenefitList } from './styled-components';

export const Benefits = ({ benefits, onConditionsClick }) => {
  return (
    <BenefitList aria-label="benefits">
      {benefits.map((benefit, index) => (
        <div key={benefit.type}>
          <Benefit {...benefit} onConditionsClick={() => onConditionsClick(benefit)} />
          {index !== benefits.length - 1 && <Divider />}
        </div>
      ))}
    </BenefitList>
  );
};

Benefits.propTypes = {
  benefits: PropTypes.arrayOf(PropTypes.object),
  planId: PropTypes.number,
  onConditionsClick: PropTypes.func,
  onBenefitsClick: PropTypes.func,
  onSeeAllClick: PropTypes.func,
};
