import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FenixBottomSheet from '@pedidosya/web-fenix/organisms/BottomSheet';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { ConditionContainer } from '@app/components/ConditionContainer';
import PromotionsList from '../promotions/PromotionsList';
import { ItemCard } from './ItemCard';
import { RealStateContainer } from './styled-components';
import { REAL_STATE_STYLE } from '@commons/models/stylesType';
import useDetectFirstHorizontalScroll from '@app/hooks/useDetectFirstHorizontalScroll';

export const RealState = ({ realState, promotions, onItemClick, onSwiped }) => {
  const [showPartnershipTyC, setShowPartnershipTyC] = useState(false);
  const [showPromotions, setShowPromotions] = useState(false);
  const [termAndConditions, setTermAndConditions] = useState(null);
  const [promotionTitle, setPromotionTitle] = useState(null);

  const realStateRef = useDetectFirstHorizontalScroll(onSwiped);

  const onCardClick = (termAndConditions, title) => {
    if (termAndConditions) {
      setShowPartnershipTyC(true);
      setTermAndConditions(termAndConditions);
      setPromotionTitle(title);
    } else {
      setShowPromotions(true);
      onItemClick(title);
    }
  };

  return Boolean(realState?.length) ? (
    <>
      <RealStateContainer ref={realStateRef} role="real-estate-swimlane">
        {realState.map((promo, idx) => {
          const isYellowMarkStyle = promo.description?.style === REAL_STATE_STYLE.YELLOW_MARK;
          const discountTitle = isYellowMarkStyle ? promo.description?.text : null;
          const categoryTitle = !isYellowMarkStyle ? promo.description?.text : null;
          const termAndConditions = promo.content?.terms_and_conditions;

          return (
            <ItemCard
              key={idx}
              image={promo.icon}
              vendorName={promo.title}
              discountTitle={discountTitle}
              categoryTitle={categoryTitle}
              onClick={() => onCardClick(termAndConditions, promo.title)}
            />
          );
        })}
      </RealStateContainer>
      <FenixBottomSheet
        handler={true}
        open={showPartnershipTyC}
        title={
          <Typography token="text-title-small" component="h3">
            {promotionTitle}
          </Typography>
        }
        onClickOut={() => {
          setShowPartnershipTyC(!showPartnershipTyC);
        }}
        onClose={() => {
          setShowPartnershipTyC(!showPartnershipTyC);
        }}
        scrollEnabled={true}
      >
        <ConditionContainer content={termAndConditions} />
      </FenixBottomSheet>
      {promotions && promotions.length > 0 && (
        <PromotionsList
          open={showPromotions}
          promotions={promotions}
          onClose={() => setShowPromotions(false)}
        />
      )}
    </>
  ) : null;
};

RealState.propTypes = {
  realState: PropTypes.array,
  promotions: PropTypes.array,
  onItemClick: PropTypes.func,
  onSwiped: PropTypes.func,
};
