import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import fenixTheme from '@pedidosya/web-fenix/theme';
import BrandError from '@pedidosya/web-fenix/pages/Error';
import logger from '@app/logger';

export const ErrorBoundary = ({ error, ...props }) => {
  if (error?.__sentry_captured__) {
    // Allow the hub to re-catch the error if the main sentry instance already caught it
    delete error.__sentry_captured__;
  }

  // TODO: Use fatal
  logger.error(error);

  return (
    <ThemeProvider theme={fenixTheme}>
      <BrandError {...props} />
    </ThemeProvider>
  );
};

ErrorBoundary.propTypes = {
  error: PropTypes.instanceOf(Error),
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
