import { useRef, useEffect } from 'react';

/**
 * Hook to set the height of a parent element to the height of its child.
 */
const useHeightFromChild = () => {
  const parentRef = useRef(null);

  useEffect(() => {
    const parentElement = parentRef.current;

    if (parentElement) {
      //Set height to 0 if there are more than one child
      if (parentElement.children.length !== 1) {
        parentElement.style.height = `0px`;
      }

      // Set height of parent element to height of child
      const height = parentElement.firstChild.offsetHeight;
      parentElement.style.height = `${height}px`;
    }
  }, []);

  return [parentRef];
};

export default useHeightFromChild;
