import React from 'react';
import PropTypes from 'prop-types';
import Tag from '@pedidosya/web-fenix/atoms/Tag';
import { PlanTitle as Wrapper } from './styled-components';

export const PlanTitle = ({ title, discount }) => {
  return (
    <Wrapper>
      {title}
      {discount && <Tag label={discount} category="deals-and-discounts" />}
    </Wrapper>
  );
};

PlanTitle.propTypes = {
  title: PropTypes.node,
  discount: PropTypes.string,
};

export default PlanTitle;
