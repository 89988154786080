import axios from 'axios';
import logger from '@app/logger';

export const fetchStatusBill = async ({ billId, planId } = {}) => {
  try {
    const billResponse = await axios.get(`/loyalty/api/status/bill/${billId}`, {
      params: { planId },
      responseType: 'blob',
    });

    return billResponse.data;
  } catch (error) {
    logger.error('Error getting status bill data');
    throw error;
  }
};
