/* eslint-disable no-console */
import axios from 'axios';
import logger from '@app/logger';

export const sendPaymentConfigurationStrategy = async ({ planId, strategy }) => {
  try {
    const paymentConfiguration = await axios.post(
      `/loyalty/api/status/paymentConfiguration/strategy`,
      {
        planId,
        strategy,
      },
    );
    return paymentConfiguration.data;
  } catch (error) {
    logger.error(`Error sending payment configuration strategy`);
    throw error;
  }
};
