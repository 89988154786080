import React from 'react';
import Image from '@pedidosya/web-fenix/foundations/Image';
import { replaceString } from '@app/utils/jsx/replaceString';

export function hasFreeTrial(promotions) {
  return promotions && promotions.some((x) => x.has_free_trial);
}

export const decorateTitle = (title) =>
  replaceString(title, {
    VISA: (
      <Image
        src="https://img.pystatic.com/payment-methods/online-payments/results/pay-visa.png"
        alt="VISA"
        width={30}
        height={10}
        style={{ verticalAlign: 'baseline', display: 'inline' }}
      />
    ),
  });
