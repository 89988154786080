import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@tanstack/react-query';
import ThumbnailImage from '@pedidosya/web-fenix/atoms/ThumbnailImage';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import FenixBottomSheet from '@pedidosya/web-fenix/organisms/BottomSheet';
import Divider from '@pedidosya/web-fenix/atoms/Divider';
import Skeleton from './Skeleton';
import {
  Container,
  RowContainer,
  NameContainer,
  VendorNameText,
  DiscountText,
  DividerContent,
} from './styled-components';
import { fetchFavoriteVendors } from '@app/services/loyalty/fetchFavoriteVendors';
import { DISCOUNT_TYPE } from '@commons/models/discountTypeEnum';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import messages from '../plan/messages';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller';

export const Vendors = ({ open, onClose }) => {
  const [bottomSheetRef, setBottomSheetRef] = useState(null);
  const { isLoading, data, isError } = useQuery(
    queryKeyBuilders.favoriteVendors(),
    () => fetchFavoriteVendors(),
    { enabled: open },
  );

  const getDiscountText = (amount, discountType) => {
    switch (discountType) {
      case DISCOUNT_TYPE.PERCENTAGE:
        return `${amount}%`;
      default:
        amount;
    }
  };

  return (
    <FenixBottomSheet
      handler={true}
      open={open}
      title="Locales con beneficios Plus"
      scrollEnabled={true}
      onClickOut={onClose}
      onClose={onClose}
      ref={setBottomSheetRef}
    >
      {isLoading ? (
        <Skeleton />
      ) : (
        <Container>
          <div style={{ width: '100%', height: '100vh' }}>
            <WindowScroller scrollElement={bottomSheetRef?.children[0].children[1]}>
              {({ height, isScrolling, onChildScroll, scrollTop }) => (
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <List
                      autoHeight
                      isScrolling={isScrolling}
                      onScroll={onChildScroll}
                      scrollTop={scrollTop}
                      height={height}
                      width={width}
                      rowHeight={75}
                      rowCount={data?.vendors?.length ?? 0}
                      rowRenderer={({ key, index, style }) => {
                        const vendor = data.vendors[index];
                        const amount = vendor.discount.value;
                        const discountAmountText = getDiscountText(amount, vendor.discount.type);
                        return (
                          <div key={key} style={style}>
                            <RowContainer>
                              <NameContainer>
                                <ThumbnailImage src={vendor.icon} stroke shape="square" size="S" />
                                <VendorNameText>
                                  <Typography token="text-title-small">{vendor.name}</Typography>
                                </VendorNameText>
                              </NameContainer>
                              <DiscountText
                                category={amount === 100 ? 'deals-and-discounts' : 'quiet'}
                                label={
                                  <FormattedMessage
                                    {...messages.discountTitle}
                                    values={{
                                      discountAmountText,
                                      discountAmount: amount,
                                    }}
                                  />
                                }
                              ></DiscountText>
                            </RowContainer>
                            <DividerContent>
                              <Divider />
                            </DividerContent>
                          </div>
                        );
                      }}
                    />
                  )}
                </AutoSizer>
              )}
            </WindowScroller>
          </div>
        </Container>
      )}
    </FenixBottomSheet>
  );
};

Vendors.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
