import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BoxMessage from '@pedidosya/web-fenix/molecules/BoxMessage';

const PaymentIssueNotification = ({ title, description, severity }) => {
  const [open, setOpen] = useState(true);

  return (
    open && (
      <BoxMessage
        variant="loud"
        type={severity}
        title={title}
        message={description}
        onClose={() => setOpen(false)}
      />
    )
  );
};

PaymentIssueNotification.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  severity: PropTypes.oneOf(['warning', 'error']),
};

export default PaymentIssueNotification;
