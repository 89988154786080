import styled, { css } from 'styled-components';

export const Content = styled.div`
  padding-bottom: ${({ theme }) => `${theme.space('spacing-10')}`};
  border-bottom: 1px solid ${({ theme }) => theme.color('shape-color-stroke-primary')};
  ${({ expanded }) =>
    expanded &&
    css`
      overflow: hidden;
      animation: fadeInFromNone 0.3s ease-out;
      @keyframes fadeInFromNone {
        0% {
          height: 0;
        }
        100% {
          height: 100px;
        }
      }
    `}
`;

export const Title = styled.div`
  flex-grow: 1;
  padding: ${({ theme, expanded }) =>
    `${theme.space('spacing-12')} 0  ${
      expanded ? theme.space('spacing-02') : theme.space('spacing-12')
    }`};
  margin-right: ${({ theme }) => `${theme.space('spacing-20')}`};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color('shape-color-stroke-primary')};
  cursor: pointer;

  ${({ expanded }) => expanded && 'border: 0;'}
`;
