import styled, { css } from 'styled-components';

const ribbonStyles = css`
  display: inline-flex;
  align-items: center;
  padding: 4px;
  line-height: 1.25;
  width: fit-content;
  background: ${({ theme }) => theme.color('shape-color-surface-communication-deal')};
  transform: skew(-8deg);

  > * {
    transform: skew(8deg);
  }
`;

export const Ribbon = styled.div`
  ${ribbonStyles}

  ${(props) =>
    props.size === 'small' &&
    `
  padding: 2px;
  line-height: 1;
  `}
`;

export const PlanWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  color: ${({ theme }) => theme.color('shape-color-surface-secondary')};
  background-color: #f9f9f9;
`;

export const BrandWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Header = styled.header`
  width: 100%;
  height: ${({ headerHeight }) => headerHeight + 'px'};
  min-height: ${({ headerHeight }) => headerHeight + 'px'};
  position: initial;
  top: 0;
  left: 0;
  right: 0;
  background-color: #02000e;
  display: flex;
  z-index: 99;
  padding-bottom: ${({ theme }) => theme.space('spacing-06.5')};

  > :not(div[role='button'], div[role='loyalty_brand']) {
    position: absolute;
    width: auto;
    padding-top: 0;
  }

  div[role='button'] {
    padding-top: 0;
  }

  > div:first-child {
    top: auto;
    padding-top: ${({ isChangeHeight }) => (isChangeHeight ? '4px' : '15px')};
  }
`;

export const InitialSection = styled.section`
  margin-top: ${({ marginTop }) => marginTop + 'px'};
`;

export const Section = styled.section`
  padding: ${({ theme }) => `0 ${theme.space('spacing-10')} ${theme.space('spacing-06')}`};
`;

export const BenefitsSection = styled(Section)`
  text-align: center;
`;

export const BenefitDetailsTitle = styled.h2`
  text-align: left;
  margin: 0;
  padding: ${({ theme }) => `${theme.space('spacing-15')} 0`};
`;

export const BenefitDetailsWrapper = styled.div`
  padding: ${({ theme }) => `0 ${theme.space('spacing-15')} ${theme.space('spacing-20')}`};
`;

export const List = styled.ul`
  text-align: start;
  list-style: none;
  padding: ${({ theme }) => `0 ${theme.space('spacing-02')}`};
`;

export const ListItem = styled.li`
  display: flex;
  margin-top: ${({ theme }) => theme.space('spacing-15')};

  &:first-child {
    margin-top: 0;
  }
`;
