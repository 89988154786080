import axios from 'axios';
import { isNotFoundError } from '@app/utils/error';
import { SUBSCRIPTION_STATUS } from '@commons/models/subscriptionStatusEnum';
import logger from '@app/logger';

export const fetchSubscription = async () => {
  try {
    const subscriptionsResponse = await axios.get('/loyalty/api/subscriptions/latest');
    return subscriptionsResponse.data;
  } catch (error) {
    if (isNotFoundError(error)) {
      return { status: SUBSCRIPTION_STATUS.UNSUBSCRIBED };
    }
    logger.error('Error getting latest user subscription');
    throw error;
  }
};
