import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.congrats.title',
    defaultMessage: 'Ya sos Plus',
  },
  subtitle: {
    id: 'app.congrats.subtitle',
    defaultMessage: 'Empezá a ahorrar con tus beneficios.',
  },
});
