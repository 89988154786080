import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import fenixTheme from '@pedidosya/web-fenix/theme';
import GlobalStyles from '@pedidosya/web-fenix/theme/GlobalStyles';
import { ThemeProvider } from 'styled-components';
import { useDeviceInfo } from '@app/contexts/deviceInfo';
import { getHomeLink } from '@commons/utils/deeplinks';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { fetchLanding } from '@app/services/loyalty/fetchLanding';
import logger from '@app/logger';
import { useLandingPageTracker } from './tracking/LandingPageTracker';
import { createSubscriptionIntent } from '../../services/loyalty/createSubscriptionIntent';
import { BrandError, DialogBrandError } from '../../components/Error';
import { Plan } from './components/plan';
import Spinner from '../../components/Spinner';
import messages from './components/plan/messages';
import { useHeaderConfiguration } from '../../hooks/useHeaderConfiguration';

const landingHasVendorChannel = (landingData) => {
  return (landingData?.benefits_summary?.details || []).some((item) =>
    item.description.includes('channel_link'),
  );
};

export const LandingPage = () => {
  const queryClient = useQueryClient();
  const deviceInfo = useDeviceInfo();
  const landingQuery = useQuery(queryKeyBuilders.landing(), fetchLanding);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const isLoading = landingQuery.isLoading;
  const isError = landingQuery.isError;

  const { trackClick, trackSwiped } = useLandingPageTracker({
    isPageReady: !isLoading,
    isErrorPage: isError,
    hasVendorChannel: landingHasVendorChannel(landingQuery?.data),
  });

  useHeaderConfiguration({ isError });

  const subscriptionIntentMutation = useMutation((planId) => createSubscriptionIntent({ planId }), {
    onSuccess: (intent) =>
      new Promise(() => {
        const checkoutBasePath = deviceInfo.isWebview() ? '/loyalty/checkout' : '';
        const url = `${checkoutBasePath}/checkout-mfe?basketId=${intent.basket_id}&type=LOYALTY_SUBSCRIPTION&dismiss=back&onExpiredCheckoutRedirectTo=/loyalty-program`;
        queryClient.removeQueries(queryKeyBuilders.subscription());
        if (deviceInfo.isWebview()) {
          window.singleSpaNavigate(url);
        } else {
          window.location.href = url;
        }
      }),
    onError: (error) => {
      logger.error(error);
      setOpenErrorModal(true);
    },
  });

  const onCloseModalError = () => {
    setOpenErrorModal(false);
  };

  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    return (
      <BrandError
        message={<FormattedMessage {...messages.brandError} />}
        primaryActionLabel="Intentar nuevamente"
        primaryActionClick={landingQuery.refetch}
        secondaryActionLabel="Ir al inicio"
        secondaryActionClick={() => {
          window.location = getHomeLink(deviceInfo);
        }}
      />
    );
  }

  return (
    <ThemeProvider theme={fenixTheme}>
      <GlobalStyles />
      <Plan
        planId={landingQuery.data?.plan_id}
        onSubscribeClick={() => subscriptionIntentMutation.mutate(landingQuery.data?.plan_id)}
        data={landingQuery.data}
        isLoading={subscriptionIntentMutation.isLoading}
        trackClick={trackClick}
        trackSwiped={trackSwiped}
      />
      <DialogBrandError
        id="loyalty-error-modal"
        open={openErrorModal}
        onClose={onCloseModalError}
        message={<FormattedMessage {...messages.genericError} />}
      />
    </ThemeProvider>
  );
};
