import React from 'react';
import PropTypes from 'prop-types';
import CardContainer from '@pedidosya/web-fenix/molecules/CardContainer';
import { Container, ContentCard, BodyContainer } from './styled-components';
import { Orders } from './Orders';
import { Savings } from './Savings';
import { Benefits } from './Benefits';

export const DetailCard = ({ orders, savings, monthlyFee, benefitsSummary, currencySymbol }) => {
  return (
    <Container>
      <CardContainer withShadow={false}>
        <ContentCard>
          <BodyContainer>
            {orders && <Orders orders={orders} isLowText={!!benefitsSummary} />}
            {savings && (
              <Savings savings={savings} monthlyFee={monthlyFee} currencySymbol={currencySymbol} />
            )}
            {benefitsSummary && <Benefits benefitsSummary={benefitsSummary} />}
          </BodyContainer>
        </ContentCard>
      </CardContainer>
    </Container>
  );
};

DetailCard.propTypes = {
  orders: PropTypes.object,
  savings: PropTypes.object,
  monthlyFee: PropTypes.object,
  benefitsSummary: PropTypes.object,
  currencySymbol: PropTypes.string,
};
