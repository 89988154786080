import React from 'react';
import FenixSkeleton from '@pedidosya/web-fenix/atoms/Skeleton';
import { Paragraph } from './styled-components';

const Skeleton = () => {
  return (
    <>
      <Paragraph>
        <FenixSkeleton shape="square" height="shape-size-action-3" />
      </Paragraph>
      <Paragraph>
        <FenixSkeleton shape="square" height="shape-size-action-3" />
        <FenixSkeleton shape="square" height="shape-size-action-3" />
        <FenixSkeleton shape="square" height="shape-size-action-3" />
        <FenixSkeleton shape="square" height="shape-size-action-3" />
        <FenixSkeleton shape="square" height="shape-size-action-3" />
      </Paragraph>
      <Paragraph>
        <FenixSkeleton shape="square" height="shape-size-action-3" />
        <FenixSkeleton shape="square" height="shape-size-action-3" />
        <FenixSkeleton shape="square" height="shape-size-action-3" />
        <FenixSkeleton shape="square" height="shape-size-action-3" />
        <FenixSkeleton shape="square" height="shape-size-action-3" />
      </Paragraph>
    </>
  );
};

Skeleton.propTypes = {};

export default Skeleton;
