import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import BrandPeyaPlusInverted from '@pedidosya/web-fenix/icons/BrandPeyaPlusInverted';
import { TextContainer, TitleContainer } from './styled-components';

export const HeaderContent = ({ title, subtitle }) => {
  return (
    <TextContainer>
      <TitleContainer>
        <Typography token="text-title-medium" color="text-color-inverted">
          {title}
        </Typography>
        <div>
          <BrandPeyaPlusInverted size={'xlarge'} />
        </div>
      </TitleContainer>
      <Typography token="text-base-large" color="text-color-inverted">
        {subtitle}
      </Typography>
    </TextContainer>
  );
};

HeaderContent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
