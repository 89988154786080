import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Snackbar from '@pedidosya/web-fenix/molecules/Snackbar';
import messages from '../../messages';

const ReactivateSubsFeedback = ({ showSnackbar, onCloseSnackbar, type }) => {
  const intl = useIntl();

  const label =
    type == 'positive'
      ? intl.formatMessage(messages.reactivateSubscriptionPositiveMessage)
      : intl.formatMessage(messages.reactivateSubscriptionErrorMessage);

  return (
    <Snackbar
      label={label}
      type={type}
      show={showSnackbar}
      position="bottom-center"
      onClose={onCloseSnackbar}
    />
  );
};

ReactivateSubsFeedback.propTypes = {
  showSnackbar: PropTypes.bool,
  onCloseSnackbar: PropTypes.func,
  type: PropTypes.string,
};

export default ReactivateSubsFeedback;
