import React from 'react';
import PropTypes from 'prop-types';
import { Webview, Web, Ios, Android } from '@app/contexts/deviceInfo/components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { BILL_STATUS } from '@commons/models/billStatusEnum';
import DownloadBillButton from './DownloadBillButton';
import SendMailButton from './SendBillButton';

const GetBillDocument = ({ billId, status, planId, amount, date, onError }) => {
  if (status !== BILL_STATUS.AVAILABLE)
    return (
      <Typography color="text-color-primary" token="font-body-highcontrast-sentence-small">
        {status === BILL_STATUS.IN_PROCESS ? 'En proceso' : 'No disponible'}
      </Typography>
    );

  return (
    <>
      <Web>
        <DownloadBillButton
          billId={billId}
          planId={planId}
          preventShare
          onError={() => onError({ type: 'download' })}
        />
      </Web>
      <Webview>
        <Ios>
          <DownloadBillButton
            billId={billId}
            planId={planId}
            onError={() => onError({ type: 'download' })}
          />
        </Ios>
        <Android>
          <SendMailButton
            billId={billId}
            planId={planId}
            amount={amount}
            date={date}
            onError={() => onError({ type: 'send' })}
          />
        </Android>
      </Webview>
    </>
  );
};

GetBillDocument.propTypes = {
  billId: PropTypes.string,
  planId: PropTypes.number,
  amount: PropTypes.number,
  date: PropTypes.string,
  onError: PropTypes.func,
  status: PropTypes.oneOf(Object.values(BILL_STATUS)),
};

export default GetBillDocument;
