/* eslint-disable no-console */
import axios from 'axios';
import logger from '@app/logger';

export const fetchUser = async () => {
  try {
    const userResponse = await axios.get('/loyalty/api/user');
    return userResponse.data;
  } catch (error) {
    logger.error('Error fetching user data');
    throw error;
  }
};
