import fenixTheme from '@pedidosya/web-fenix/theme';

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export const theme = Object.assign(fenixTheme, {
  mediaQueries: {
    mobileS: `only screen and ${device.mobileS}`,
    mobileM: `only screen and ${device.mobileM}`,
    mobileL: `only screen and ${device.mobileL}`,
    tablet: `only screen and ${device.tablet}`,
    laptop: `only screen and ${device.laptop}`,
    laptopL: `only screen and ${device.laptopL}`,
    desktop: `only screen and ${device.desktop}`,
    desktopL: `only screen and ${device.desktopL}`,
    mobileIosNotch: `only screen and (min-height: 800px) and ${device.mobileM}`,
    mobileIos: `only screen and (max-height: 740px) and ${device.mobileM}`,
  },
});
