import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Unsubscribe } from '../Unsubscribe';
import { SurveyModal } from '../SurveyModal';
import { useFlagVariation } from '@app/contexts/FwfFlags/hooks';
import { EXPERIMENTAL_FLAGS } from '@commons/fwf/constants';

export const Cancel = ({ onCancelSubscription, onGoBack }) => {
  const surveyFlag = useFlagVariation(EXPERIMENTAL_FLAGS.WEB_LOYALTY_PROGRAM_SURVEY.KEY);
  const [showUnsubscribe, setShowUnsubscribe] = useState(true);

  const onContinue = () => {
    if (surveyFlag) setShowUnsubscribe(false);
    else onCancelSubscription();
  };

  return (
    <>
      {showUnsubscribe ? (
        <Unsubscribe onGoBack={onGoBack} onContinue={onContinue} />
      ) : (
        <SurveyModal
          open={!showUnsubscribe && surveyFlag}
          onCloseSurvey={onGoBack}
          onUnsubscribe={onCancelSubscription}
        />
      )}
    </>
  );
};

Cancel.propTypes = {
  onCancelSubscription: PropTypes.func,
  onGoBack: PropTypes.func,
};
