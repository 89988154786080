import { useEffect, useCallback } from 'react';
import { useDeviceInfo } from '@app/contexts/deviceInfo';
import { useFlagVariation } from '@app/contexts/FwfFlags/hooks';
import { EXPERIMENTAL_FLAGS } from '@commons/fwf/constants';
import { ROUTES } from '@commons/services/routes.service';
import { useHistory } from 'react-router-dom';

const PAYMENT_INSTRUMENT_EVENT = 'payment_instrument_changed';

const usePaymentInstrumentsNavigation = () => {
  const deviceInfo = useDeviceInfo();
  const history = useHistory();
  const isNativeFlagEnabled = useFlagVariation(
    EXPERIMENTAL_FLAGS.USE_NATIVE_PAYMENT_METHOD_CHANGE.KEY,
  );

  const shouldNativeNavigate = isNativeFlagEnabled && deviceInfo.isWebview();

  useEffect(() => {
    // Only add listener and instantiate handler when native navigation is allowed
    if (shouldNativeNavigate) {
      const handlePaymentInstrumentChange = (event) => {
        const instrumentId = event?.detail?.paymentInstrumentSelected;
        if (!instrumentId) return null;

        if (/^[a-zA-Z0-9_-]+$/.test(instrumentId)) {
          history.replace(
            `${ROUTES.PAYMENT_INSTRUMENT_CHANGE_SUCCESS}?paymentInstrumentsSelected=${instrumentId}`,
          );
        }
      };

      window.addEventListener(PAYMENT_INSTRUMENT_EVENT, handlePaymentInstrumentChange);

      return () =>
        window.removeEventListener(PAYMENT_INSTRUMENT_EVENT, handlePaymentInstrumentChange);
    }

    return () => {};
  }, [history, shouldNativeNavigate]);

  const navigateToPaymentInstruments = useCallback(
    (instrumentId) => {
      const paymentInstrumentData = {
        instrumentSelected: instrumentId,
        action: 'CHANGE_PAYMENT_INSTRUMENT',
        businessType: 'LOYALTY_SUBSCRIPTION',
      };

      // Interface to open deeplink on Loyalty flow
      if (shouldNativeNavigate) {
        if (deviceInfo.isIos()) {
          window?.webkit?.messageHandlers?.ListenEventsInterface?.postMessage({
            event: 'open_deeplink_change_payment_method',
            payload: paymentInstrumentData,
          });
        } else {
          window?.ListenEventsInterface?.openDeeplinkChangePaymentMethod(
            JSON.stringify(paymentInstrumentData),
          );
        }
      } else {
        const checkoutBasePath = deviceInfo.isWebview() ? '/loyalty/checkout' : '';
        const url = `${checkoutBasePath}/payment-methods-mfe/user-instruments?selectedInstruments=${encodeURI(
          JSON.stringify([{ id: instrumentId }]),
        )}&action=CHANGE_PAYMENT_INSTRUMENT&businessType=LOYALTY_SUBSCRIPTION&successCallback=${
          ROUTES.PAYMENT_INSTRUMENT_CHANGE_SUCCESS
        }&cancelCallback=${ROUTES.STATUS_DETAILS}`;

        window.location.replace(url);
      }
    },
    [deviceInfo, shouldNativeNavigate],
  );

  return navigateToPaymentInstruments;
};

export default usePaymentInstrumentsNavigation;
