import React from 'react';
import FenixSkeleton from '@pedidosya/web-fenix/atoms/Skeleton';
import { SkeletonContainer, RowContainer, NameContainer, ShimmerIcon } from './styled-components';

const Skeleton = () => {
  return (
    <SkeletonContainer role="progressbar">
      {Array.apply(0, Array(4)).map((x, idx) => (
        <div key={idx}>
          <RowContainer>
            <NameContainer>
              <ShimmerIcon>
                <FenixSkeleton
                  shape="square"
                  height="shape-size-action-10"
                  width="shape-size-action-10"
                />
              </ShimmerIcon>
              <FenixSkeleton
                shape="square"
                height="shape-size-action-3"
                width="shape-size-action-25"
              />
            </NameContainer>
            <FenixSkeleton
              shape="square"
              height="shape-size-action-3"
              width="shape-size-action-25"
            />
          </RowContainer>
          <FenixSkeleton shape="square" height="shape-size-action-1" />
        </div>
      ))}
    </SkeletonContainer>
  );
};

Skeleton.propTypes = {};

export default Skeleton;
