import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { TRACKING_CLICK_LOCATIONS } from '@commons/constants/tracker';
import differenceInCalendarDays from '@commons/utils/date/differenceInCalendarDays';
import { formatDateToDayMonth } from '@commons/utils/date/date';
import { isFreeTrial, isPercentage } from '@commons/utils/markdowns';
import { SUBSCRIPTION_STATUS } from '@commons/models/subscriptionStatusEnum';
import { getUnitIdFromValue } from '@commons/models/durationUnitEnum';
import ChevronRight from '@pedidosya/web-fenix/icons/ChevronRight';
import Settings from '@pedidosya/web-fenix/icons/Settings';
import Briefcase from '@pedidosya/web-fenix/icons/Briefcase';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Tag from '@pedidosya/web-fenix/atoms/Tag';
import CardContainer from '@pedidosya/web-fenix/molecules/CardContainer';
import { CardContent, TextContainer, IconContainer, HeaderCard } from './styled-components';
import messages from '../../messages';
import landingMessage from '../../../landing/components/plan/messages';

export const DetailCard = ({
  onDetailClick,
  subscription,
  trackClick = () => {},
  isBusinessUser,
}) => {
  const intl = useIntl();

  const { next_charge_date, status, reactivation_enabled, markdown } = subscription;
  const { for_reactivation, discount_amount, duration_value, duration_unit } = markdown || {};
  const freeTrialMarkdown = isFreeTrial(markdown);
  const percentageMarkdowns = isPercentage(markdown);

  const isCancelled = status === SUBSCRIPTION_STATUS.CANCELLED;
  const canReactivate = isCancelled && reactivation_enabled && !isBusinessUser;

  const remainingDays = differenceInCalendarDays(new Date(next_charge_date), new Date(Date.now()));

  const clickWithTrack = (clickLocation, clickHandler) => (args) => {
    if (trackClick && typeof trackClick === 'function') trackClick(clickLocation);
    if (clickHandler && typeof clickHandler === 'function') clickHandler(args);
  };

  const getTitle = () => {
    if (canReactivate) {
      return messages.reactivateCardTitle;
    }
    if (!canReactivate && !isBusinessUser) {
      return messages.cardTitle;
    }
    if (!canReactivate && isBusinessUser) {
      return messages.plusCardTitle;
    }
  };

  const title = getTitle();

  const getNormalSubtitleTypography = (text) => {
    return (
      <Typography token="text-base-large" color="text-color-secondary">
        {text}
      </Typography>
    );
  };

  const getSubtitle = () => {
    if (isBusinessUser) {
      return getNormalSubtitleTypography(<FormattedMessage {...messages.plusCardSubtitle} />);
    }
    if (!canReactivate)
      return getNormalSubtitleTypography(<FormattedMessage {...messages.cardSubtitle} />);
    if (!markdown && !isBusinessUser)
      return getNormalSubtitleTypography(<FormattedMessage {...messages.reactivateCardSubtitle} />);
    if (!for_reactivation && !isBusinessUser) {
      const discount = percentageMarkdowns
        ? intl.formatMessage(messages.planDiscountTitle, {
            discountAmountText: `${Math.floor(discount_amount)}%`,
          })
        : null;

      return getNormalSubtitleTypography(
        <>
          {percentageMarkdowns ? (
            <FormattedMessage
              {...messages.reactivateCardSubtitleMarkdownPerc}
              values={{
                tag: <Tag category="deals-and-discounts" label={discount} />,
              }}
            />
          ) : (
            <FormattedMessage
              {...messages.reactivateCardSubtitleMarkdown}
              values={{
                remainingDate: formatDateToDayMonth(next_charge_date),
                tag: <Tag category="deals-and-discounts" label="Gratis" />,
              }}
            />
          )}
        </>,
      );
    }

    return getNormalSubtitleTypography(
      <FormattedMessage
        {...messages.reactivateCardSubtitleMarkdownNew}
        values={{
          tag: (
            <Tag
              category="deals-and-discounts"
              label={
                freeTrialMarkdown
                  ? intl.formatMessage(landingMessage.durationText, {
                      value: duration_value,
                      unit: getUnitIdFromValue(duration_unit),
                    }) + ' gratis'
                  : discount_amount + '% OFF'
              }
            />
          ),
        }}
      />,
    );
  };

  return (
    <CardContainer aria-label="detail_card" withBorder={false}>
      {isCancelled && (
        <HeaderCard>
          <Typography token="font-label-highercontrast-medium" color="text-color-primary">
            <FormattedMessage {...messages.cancelledCardTitle} values={{ remainingDays }} />
          </Typography>
        </HeaderCard>
      )}

      <CardContent
        onClick={!isBusinessUser && clickWithTrack(TRACKING_CLICK_LOCATIONS.MANAGE, onDetailClick)}
        isBusinessUser={isBusinessUser}
      >
        <IconContainer>
          {isBusinessUser ? <Briefcase size="medium" /> : <Settings size="medium" />}
        </IconContainer>
        <TextContainer>
          <Typography token="text-title-small">
            <FormattedMessage {...title} />
          </Typography>
          {getSubtitle()}
        </TextContainer>
        {!isBusinessUser && (
          <IconContainer aria-label="detail_card_button">
            <ChevronRight size="small" />
          </IconContainer>
        )}
      </CardContent>
    </CardContainer>
  );
};

DetailCard.propTypes = {
  onDetailClick: PropTypes.func.isRequired,
  savings: PropTypes.object,
  subscription: PropTypes.object,
};
