/* eslint-disable no-console */
import axios from 'axios';
import logger from '@app/logger';

export const sendEvent = async (event) => {
  try {
    await axios.post(`/loyalty/api/metrics`, { event });
  } catch (error) {
    logger.error(`Error sending metric`);
    throw error;
  }
};
