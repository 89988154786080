import styled from 'styled-components';

export const CardContent = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.space('spacing-component-2xlarge')};
  gap: ${({ theme, isBusinessUser }) =>
    isBusinessUser
      ? theme.space('spacing-component-xlarge')
      : theme.space('spacing-component-large')};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.space('spacing-component-small')};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderCard = styled.div`
  height: ${({ theme }) => theme.size('size-7')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) =>
    `${theme.borderRadius('border-radius-default')} ${theme.borderRadius(
      'border-radius-default',
    )} 0 0`};
  background: ${({ theme }) => theme.color('shape-color-surface-feedback-warning')};
`;
