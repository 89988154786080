import axios from 'axios';
import logger from '@app/logger';

export const fetchSubscriptionTC = async ({ type }) => {
  try {
    const tcResponse = await axios.get(`/loyalty/api/subscription/terms`, { params: { type } });
    return tcResponse.data;
  } catch (error) {
    logger.error(`Error getting T&C for type ${type}`);
    throw error;
  }
};
