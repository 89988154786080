import axios from 'axios';
import logger from '@app/logger';

export const fetchStatusDetail = async () => {
  try {
    const statusResponse = await axios.get('/loyalty/api/status/detail');
    return statusResponse.data;
  } catch (error) {
    logger.error('Error getting status detail');
    throw error;
  }
};
