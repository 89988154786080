import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import {
  Table as StyledTable,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from './styled-components';

const Table = ({ headers, data }) => {
  return (
    <StyledTable>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableCell as="th" key={header.id} align={header.align}>
              <Typography token="text-highlight-large">{header.label}</Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, idx) => (
          <TableRow key={idx}>
            {headers.map(({ id, render, align }) => (
              <TableCell key={id} align={align}>
                <Typography token="text-base-medium">
                  {render ? render(row[id], row) : row[id]}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </StyledTable>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      align: PropTypes.oneOf(['left', 'right', 'center']),
      render: PropTypes.func,
      label: PropTypes.node,
    }),
  ),
  data: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
