import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '@pedidosya/web-fenix/atoms/Button';
import { FooterContainerWrapper as FooterContainer } from './styled-components';
import SubscribeLabel from './SubscribeLabel';
import { useIsWebviewIos } from '@app/contexts/deviceInfo/hooks';
import useHeightFromChild from '@app/hooks/useHeightFromChild';
import { hasFreeTrial, hasPercentage } from '@commons/utils/markdowns';
import messages from '../plan/messages';

const Footer = ({ onSubscribe, isLoading, pricing = {} }) => {
  const isWebviewIos = useIsWebviewIos();
  const [parentRef] = useHeightFromChild();

  const ctaMessage =
    hasFreeTrial(pricing?.markdowns) && !hasPercentage(pricing?.markdowns) ? (
      <FormattedMessage {...messages.tryForFree} />
    ) : (
      <FormattedMessage {...messages.subscribe} />
    );

  return (
    <div ref={parentRef}>
      <FooterContainer isWebviewIos={isWebviewIos}>
        <SubscribeLabel pricing={pricing} />
        <Button
          onClick={onSubscribe}
          label={ctaMessage}
          state={isLoading ? 'loading' : 'enabled'}
          size="large"
          fullWidth
        />
      </FooterContainer>
    </div>
  );
};

Footer.propTypes = {
  onSubscribe: PropTypes.func,
  isLoading: PropTypes.bool,
  pricing: PropTypes.shape({
    markdowns: PropTypes.array,
    currencySymbol: PropTypes.string,
    monthly_fee: PropTypes.number,
    monthly_fee_after_discount: PropTypes.number,
  }),
};

export default Footer;
