import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import DOMPurify from 'isomorphic-dompurify';
import { BoxMessageWrapper as BoxMessage } from './styled-components';

const NewsMessage = ({ title, description, showMessage, className }) => {
  const [show, setShow] = useState(showMessage ?? false);

  return (
    show && (
      <BoxMessage
        variant="quiet"
        type="informative"
        title={title}
        message={
          <Typography token="font-body-midcontrast-sentence-small">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(description, { RETURN_DOM: true }).outerHTML,
              }}
            />
          </Typography>
        }
        onClose={() => {
          setShow(false);
        }}
        className={className}
      />
    )
  );
};

NewsMessage.propTypes = {
  children: PropTypes.node,
};

export default NewsMessage;
