import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useIsIos,
  useIsAndroid,
  useIsWebview,
  useAppVersion,
} from '@app/contexts/deviceInfo/hooks';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { IOS_VERSION } from '@app/utils/constants/backNavigationVersion';
import { HOME_LINKS } from '@commons/utils/deeplinks';
import { PRESENTATION_QUERY } from '@commons/models/presentationQueryEnum';
import navigation from '@pedidosya/web-native-bridges/navigation';

/**
 * Hook to go back
 */
const useBackNavigation = ({ isHistoryRoot }) => {
  const isIos = useIsIos();
  const isAndroid = useIsAndroid();
  const isWebview = useIsWebview();

  const history = useHistory();
  const { presentation = PRESENTATION_QUERY.ROOT } = usePublicEnv();
  const canNavigateBack = !isHistoryRoot && history && history.length !== 1;
  const isBackNavigationVersion = useAppVersion({ min: IOS_VERSION });

  const androidBackCallback = useCallback(() => {
    const withNativeBackNavigation = presentation !== PRESENTATION_QUERY.ROOT;

    if (canNavigateBack) {
      return history.goBack();
    }
    if (withNativeBackNavigation) {
      navigation.goBack();
    } else {
      window.location.assign(HOME_LINKS.ANDROID);
    }
  }, [canNavigateBack, history, presentation]);

  const iosBackCallback = useCallback(() => {
    const withNativeBackNavigation =
      isBackNavigationVersion && presentation !== PRESENTATION_QUERY.ROOT;

    if (canNavigateBack) {
      return history.goBack();
    }

    if (withNativeBackNavigation) {
      navigation.goBack();
    } else {
      window.location.assign(HOME_LINKS.IOS);
    }
  }, [canNavigateBack, history, presentation, isBackNavigationVersion]);

  const webBackCallback = useCallback(() => {
    const withNativeBackNavigation = presentation === PRESENTATION_QUERY.PUSH;

    if (canNavigateBack) {
      history.goBack();
    }
    if (withNativeBackNavigation) {
      history.goBack();
    } else {
      window.location.assign(HOME_LINKS.WEB);
    }
  }, [canNavigateBack, history, presentation]);

  const handleBack = useCallback(() => {
    if (isWebview && isIos) return iosBackCallback();
    if (isWebview && isAndroid) return androidBackCallback();
    return webBackCallback();
  }, [isWebview, isIos, isAndroid, androidBackCallback, iosBackCallback, webBackCallback]);

  return handleBack;
};

export default useBackNavigation;
