import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { TRACKING_CLICK_LOCATIONS, TRACKING_SWIP_LOCATIONS } from '@commons/constants/tracker';
import { PlanWrapper, BenefitsSection, InitialSection } from '../styled-components';
import { LoyaltyHeader } from '../Header';
import { FrequentlyAskedQuestion } from '../frequentlyAskedQuestion';
import { DismissableMessage } from '../dismissableMessage';
import { BenefitsCard } from '../benefitsCard';
import { BenefitsDetails } from '../benefitsDetails';
import { VendorsSwiper } from '../vendorsSwiper';
import { hasFreeTrial as markdownsHasFreeTrial } from '@commons/utils/markdowns';
import { hasFreeTrial as promotionsHasFreeTrial } from '@app/utils/promotions';
import firstLettertoUpper from '@commons/utils/string/firstLettertoUpper';
import { DismissableRichtTextPopup as Popup } from '@app/components/DismissableRichTextPopup';
import { RealState } from '../RealState';
import { Footer } from '../Footer';
import { TermsAndCondition } from '../termsAndCondition';

export const Plan = ({
  planId,
  data,
  trackClick = Function.prototype,
  trackSwiped = Function.prototype,
  onSubscribeClick,
  isLoading = false,
}) => {
  const planWrapperRef = useRef();
  const title = `${firstLettertoUpper(data.user.first_name)}, ${data.header_v2?.title}`;
  const markdowns = data?.pricing?.markdowns || [];

  return (
    <PlanWrapper ref={planWrapperRef}>
      <LoyaltyHeader title={title} subtitle={data.header_v2?.subtitle} />
      <InitialSection marginTop={-21}>
        {Boolean(data?.real_state?.length) && (
          <RealState
            realState={data?.real_state}
            promotions={data?.promotions}
            onSwiped={() => trackSwiped(TRACKING_SWIP_LOCATIONS.MARKDOWN_SWIMLANE)}
            onItemClick={(item) =>
              trackClick(
                `${item.replaceAll(' ', '_').toLowerCase()}_${
                  TRACKING_CLICK_LOCATIONS.MARKDOWN_SWIMLANE
                }`,
              )
            }
          />
        )}
        <BenefitsSection>
          <BenefitsCard benefits={data.benefits_summary.details} />
        </BenefitsSection>
      </InitialSection>
      {data?.infoMessage && (
        <DismissableMessage>
          <span dangerouslySetInnerHTML={{ __html: data.infoMessage }} />
        </DismissableMessage>
      )}
      {data.favorite_vendors && (
        <VendorsSwiper
          favoriteVendors={data.favorite_vendors}
          onSeeAllClick={() => trackClick(TRACKING_CLICK_LOCATIONS.SEE_MORE)}
          onSwiped={() => trackSwiped(TRACKING_SWIP_LOCATIONS.VENDORS_SWIMLANE)}
        />
      )}
      <BenefitsDetails
        benefits={data.benefits}
        onTermsAndConditionsClick={({ type }) =>
          trackClick(
            `${type.toLowerCase()}_${TRACKING_CLICK_LOCATIONS.TERMS_AND_CONDITIONS}`,
            `benefit.${TRACKING_CLICK_LOCATIONS.TERMS_AND_CONDITIONS}`,
          )
        }
      />
      <FrequentlyAskedQuestion
        benefitTypes={data.benefits.map((benefit) => benefit.type)}
        planId={planId}
        cities={data?.faq_attributes?.allowed_cities}
        currencySymbol={data?.pricing?.currencySymbol}
        hasFreeTrial={markdownsHasFreeTrial(markdowns) || promotionsHasFreeTrial(data?.promotions)}
        minCharge={data?.pricing.min_charge}
        onQuestionClick={(id, { open }) =>
          open && trackClick(`${id}_${TRACKING_CLICK_LOCATIONS.FAQ}`, TRACKING_CLICK_LOCATIONS.FAQ)
        }
      />
      <TermsAndCondition
        planId={planId}
        onTermAndConditionClick={() => trackClick(TRACKING_CLICK_LOCATIONS.TERMS_AND_CONDITIONS)}
      />
      <Footer
        isLoading={isLoading}
        onSubscribe={() => {
          trackClick(TRACKING_CLICK_LOCATIONS.SUBSCRIBE);
          onSubscribeClick();
        }}
        pricing={data?.pricing}
      />
      {data?.pop_up && data?.pop_up?.content && (
        <Popup
          id="plan-popup"
          content={data?.pop_up?.content}
          delayTime={data?.pop_up?.delay_time}
          showOneTime
        />
      )}
    </PlanWrapper>
  );
};

Plan.propTypes = {
  planId: PropTypes.number,
  trackClick: PropTypes.func,
  onSubscribeClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  data: PropTypes.shape({
    user: PropTypes.shape({
      first_name: PropTypes.string,
    }),
    benefits_summary: PropTypes.shape({
      details: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
        }),
      ),
    }),
    pricing: PropTypes.shape({
      currencySymbol: PropTypes.string,
      monthly_fee: PropTypes.number,
      markdowns: PropTypes.array,
      min_charge: PropTypes.number,
    }),
    faq_attributes: PropTypes.shape({
      mov: PropTypes.number,
      allowed_cities: PropTypes.arrayOf(PropTypes.string),
    }),
    popup: PropTypes.shape({
      content: PropTypes.string,
      delay_time: PropTypes.number,
    }),
  }),
};
