const BENEFIT_TYPES = {
  DELIVERY_FEE: 'DELIVERY_FEE',
  DMART_CHARGEBACKS: 'DMART_CHARGEBACKS',
  DMARTS: 'DMARTS',
  VISA_MARKDOWN: 'VISA_MARKDOWN',
  LUNCH: 'LUNCH_WITH_PLUS',
  DRINKS: 'DRINKS',
  CUSTOMER_SUPPORT_PLUS: 'CUSTOMER_SUPPORT_PLUS',
  GENERIC_BENEFIT: 'GENERIC_BENEFIT',
};

module.exports = { BENEFIT_TYPES };
