import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useIsWebviewAndroid } from '@app/contexts/deviceInfo/hooks';
import { fetchStatusPayments } from '@app/services/loyalty/fetchStatusPayments';
import { Table } from '@app/components/Table';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { formatCurrency } from '@app/utils/format/currency';
import { Snackbar } from '@pedidosya/web-fenix/molecules';
import FenixBottomSheet from '@pedidosya/web-fenix/organisms/BottomSheet';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { Container, TitleContainer, ContactText } from './styled-components';
import Skeleton from './Skeleton';
import GetBillDocument from './GetBillDocument';
import messages from '../../../status/messages';

export const Payments = ({ open, onClose, planId }) => {
  const isWebviewAndroid = useIsWebviewAndroid();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const intl = useIntl();
  const { isLoading, data } = useQuery(
    queryKeyBuilders.statusPayments(planId),
    () => fetchStatusPayments(planId),
    { enabled: open },
  );

  return (
    <FenixBottomSheet
      handler={true}
      open={open}
      title={<FormattedMessage {...messages.paymentsTitle} />}
      scrollEnabled={true}
      onClickOut={onClose}
      onClose={onClose}
    >
      {isLoading ? (
        <Skeleton />
      ) : (
        <Container>
          <TitleContainer>
            <Typography token="text-highlight-large">
              <FormattedMessage
                {...(isWebviewAndroid ? messages.paymentsSubtitleSend : messages.paymentsSubtitle)}
              />
            </Typography>
          </TitleContainer>
          <Table
            headers={[
              {
                id: 'date',
                label: 'Fecha',
                align: 'center',
                render: (date) => intl.formatDate(date),
              },
              {
                id: 'amount',
                label: 'Monto',
                align: 'center',
                render: (amount, row) =>
                  formatCurrency(intl, { amount, currencySymbol: row.currencySymbol }),
              },
              {
                id: 'bill',
                label: <FormattedMessage {...messages.paymentDocumentName} />,
                align: 'center',
                render: ({ id, status }, row) => (
                  <GetBillDocument
                    billId={id}
                    status={status}
                    planId={planId}
                    amount={row.amount}
                    date={row.date}
                    onError={() => setShowSnackbar(true)}
                  />
                ),
              },
            ]}
            data={data?.items || []}
          />
          {data?.hasMoreBills && (
            <ContactText>
              <Typography token="text-highlight-small">
                <FormattedMessage {...messages.paymentsContact} />
              </Typography>
            </ContactText>
          )}
          <Snackbar
            label={<FormattedMessage {...messages.paymentsSendError} />}
            type="error"
            show={showSnackbar}
            onClose={() => setShowSnackbar(false)}
          />
        </Container>
      )}
    </FenixBottomSheet>
  );
};

Payments.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  planId: PropTypes.number,
};
