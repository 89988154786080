import { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Hook to clear router state on page leave or component unmount, whichever happens first
 */
const useClearRouterState = () => {
  const history = useHistory();
  const replaceHistory = useCallback(() => {
    history.replace({ ...history.location, state: undefined });
  }, [history]);

  useEffect(() => {
    window.addEventListener('beforeunload', replaceHistory);
    return () => {
      // Reset Location state if we leave this page
      replaceHistory();
      window.removeEventListener('beforeunload', replaceHistory);
    };
  }, [replaceHistory]);
};

export default useClearRouterState;
