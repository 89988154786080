import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@tanstack/react-query';
import { ButtonLink } from '@app/components/ButtonLink';
import { TermsAndConditions } from '@app/components/TermsAndConditions';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { TermsAndConditionsCont } from './styled-components';
import messages from './messages';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { fetchPlanTC } from '@app/services/loyalty/fetchPlanTC';

export const TermsAndCondition = ({ onTermAndConditionClick, planId }) => {
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  const { isLoading, data } = useQuery(
    queryKeyBuilders.planTyc(planId),
    () => fetchPlanTC({ planId }),
    { enabled: showTermsAndConditions },
  );

  const openTermsAndConditions = () => {
    onTermAndConditionClick();
    setShowTermsAndConditions(true);
  };

  return (
    <>
      <TermsAndConditionsCont>
        <Typography token="text-base-medium">
          {' '}
          <FormattedMessage
            {...messages.termsAndConditions}
            values={{
              a: (chunks) => (
                <ButtonLink onClick={() => openTermsAndConditions()}>
                  <Typography token="text-utility-underline-medium">{chunks}</Typography>
                </ButtonLink>
              ),
            }}
          />
        </Typography>
      </TermsAndConditionsCont>
      <TermsAndConditions
        isLoading={isLoading}
        data={data}
        open={showTermsAndConditions}
        onClose={() => setShowTermsAndConditions(false)}
      />
    </>
  );
};

TermsAndCondition.propTypes = {
  planId: PropTypes.number,
  onTermAndConditionClick: PropTypes.func,
};
