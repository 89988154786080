/* eslint-disable radix */
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Switch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { LandingPage } from '../landing';
import { StatusPage } from '../status';
import { UpcomingTyCAR } from '../upcomingTyC';
import { StatusDetailsPage } from '../statusDetails';
import { CongratsPage } from '../congrats';
import { PaymentInstrumentChangeSuccess } from '../paymentInstrumentChangeSuccess';
import { CancellationPage } from '../cancellation';
import { RedirectPage } from '../redirect';
import { useDeviceInfo } from '@app/contexts/deviceInfo';
import { ROUTES } from '@commons/services/routes.service';
import { SUBSCRIPTION_STATUS } from '@commons/models/subscriptionStatusEnum';
import { BrandError } from '../../components/Error';
import { getHomeLink } from '@commons/utils/deeplinks';
import { fetchSubscription } from '../../services/loyalty/fetchSubscription';
import Spinner from '../../components/Spinner';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { RouteByStatus } from './RouteByStatus';
import messages from './messages';

export const LoyaltyRouter = () => {
  const subscriptionQuery = useQuery(queryKeyBuilders.subscription(), fetchSubscription, {
    retry: false,
  });
  const deviceInfo = useDeviceInfo();
  const { isLoading, isError, refetch, data } = subscriptionQuery;

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return (
      <BrandError
        message={<FormattedMessage {...messages.genericError} />}
        primaryActionLabel="Intentar nuevamente"
        primaryActionClick={refetch}
        secondaryActionLabel="Ir al inicio"
        secondaryActionClick={() => {
          window.location = getHomeLink(deviceInfo);
        }}
      />
    );
  }

  return (
    <Switch>
      <RouteByStatus
        allowedStatuses={[SUBSCRIPTION_STATUS.ACTIVE, SUBSCRIPTION_STATUS.CANCELLED]}
        status={data.status}
        exact
        path={ROUTES.STATUS}
        render={(props) => <StatusPage {...props} />}
      />
      <RouteByStatus
        allowedStatuses={[SUBSCRIPTION_STATUS.ACTIVE, SUBSCRIPTION_STATUS.CANCELLED]}
        status={data.status}
        exact
        path={ROUTES.STATUS_DETAILS}
        render={(props) => <StatusDetailsPage {...props} />}
      />
      <RouteByStatus
        allowedStatuses={[SUBSCRIPTION_STATUS.INACTIVE, SUBSCRIPTION_STATUS.UNSUBSCRIBED]}
        status={data.status}
        exact
        path={ROUTES.PLAN_LANDING}
        render={(props) => <LandingPage {...props} planId={2} />}
      />
      <RouteByStatus exact path={ROUTES.LANDING} status={data.status} />
      <RouteByStatus
        allowedStatuses={[SUBSCRIPTION_STATUS.ACTIVE]}
        status={data.status}
        exact
        path={ROUTES.CONGRATS}
        render={() => <CongratsPage />}
      />
      <RouteByStatus
        allowedStatuses={[SUBSCRIPTION_STATUS.ACTIVE]}
        status={data.status}
        exact
        path={ROUTES.PAYMENT_INSTRUMENT_CHANGE_SUCCESS}
        render={() => <PaymentInstrumentChangeSuccess />}
      />
      <RouteByStatus
        allowedStatuses={[SUBSCRIPTION_STATUS.ACTIVE]}
        status={data.status}
        exact
        path={ROUTES.CANCELLATION}
        render={() => <CancellationPage />}
      />
      <RouteByStatus
        allowedStatuses={[
          SUBSCRIPTION_STATUS.ACTIVE,
          SUBSCRIPTION_STATUS.INACTIVE,
          SUBSCRIPTION_STATUS.UNSUBSCRIBED,
          SUBSCRIPTION_STATUS.CANCELLED,
        ]}
        status={SUBSCRIPTION_STATUS.UNSUBSCRIBED}
        exact
        path={ROUTES.REDIRECT}
        render={() => <RedirectPage />}
      />
      <RouteByStatus
        allowedStatuses={[SUBSCRIPTION_STATUS.ACTIVE, SUBSCRIPTION_STATUS.CANCELLED]}
        status={data.status}
        exact
        path={ROUTES.UPCOMING_TYC}
        render={(props) => <UpcomingTyCAR {...props} />}
      />
    </Switch>
  );
};
