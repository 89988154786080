import styled from 'styled-components';
import Modal from '@pedidosya/web-fenix/organisms/Modal';
import CloseIcon from '@pedidosya/web-fenix/icons/Close';

export const Popup = styled(Modal)`
  overflow: auto;

  > div {
    padding: 0;
  }

  > div > div > button {
    display: none;
  }
`;

export const PopupCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: ${({ theme }) => theme.space('spacing-12')};
  right: ${({ theme }) => theme.space('spacing-08')};
`;
