import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatCurrency } from '@app/utils/format/currency';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { TotalSavingContainer, OrderTitle } from './styled-components';
import messages from '../../messages';

export const SavingCard = ({ savings, currencySymbol }) => {
  const intl = useIntl();
  return (
    <TotalSavingContainer>
      <OrderTitle>
        <Typography token="text-base-large" color="text-color-secondary">
          En{' '}
          <Typography token="text-highlight-large" color="text-color-secondary">
            <FormattedMessage
              {...messages.savingOrders}
              values={{
                orders: savings?.orders_count,
              }}
            />
          </Typography>
        </Typography>
        <Typography token="text-base-large" color="text-color-secondary">
          ya ahorraste
        </Typography>
      </OrderTitle>
      <Typography token="text-display-small" color="text-color-primary">
        {formatCurrency(intl, {
          amount: savings?.total,
          currencySymbol,
        })}
      </Typography>
    </TotalSavingContainer>
  );
};

SavingCard.propTypes = {
  savings: PropTypes.shape({
    total: PropTypes.number,
    orders_count: PropTypes.number,
  }),
  currencySymbol: PropTypes.string,
};
