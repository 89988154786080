import axios from 'axios';
import logger from '@app/logger';

export const cancelSubscription = async ({ planId }) => {
  try {
    const subscription = await axios.post(`/loyalty/api/subscriptions/cancel`, { planId });
    return subscription.data;
  } catch (error) {
    logger.error(`Error cancelling subscription to plan: ${planId}`);
    throw error;
  }
};
