import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import ChevronRight from '@pedidosya/web-fenix/icons/ChevronRight';
import {
  VendorsSwiperTitle,
  VendorsSwiperContent,
  VendorWrapper,
  AdditionalVendorButton,
  VendorSwiperContainer,
} from './styled-components';
import { VendorsSwiperList } from './VendorsSwiperList';
import { Vendors } from '../vendors';
import useDetectFirstHorizontalScroll from '@app/hooks/useDetectFirstHorizontalScroll';
import messages from '../plan/messages';
import { IMAGE_URL_DOMAIN } from '@commons/constants/imagesDomain';

const TOTAL_VENDORS_IN_SWIPER = 10;
const backgroundImage = `${IMAGE_URL_DOMAIN}/image/pedidosya/loyalty/loyalty-graphic-background.png`;

export const VendorsSwiper = ({
  favoriteVendors,
  onSeeAllClick = Function.prototype,
  onSwiped,
}) => {
  const [showVendors, setShowVendors] = useState(false);
  const totalVendorsCount = favoriteVendors?.total;
  const vendorSwiperRef = useDetectFirstHorizontalScroll(onSwiped);

  return (
    <>
      <VendorsSwiperTitle>
        <Typography token="font-headline-lowcontrast-medium" style={{ fontSize: '20px' }}>
          <FormattedMessage
            {...messages.vendorsTitle}
            values={{
              s: (chunks) => <strong>{chunks}</strong>,
            }}
          />
        </Typography>
      </VendorsSwiperTitle>
      <VendorsSwiperContent backgroundImage={backgroundImage}>
        <VendorSwiperContainer role="vendors-swiper" ref={vendorSwiperRef}>
          <VendorsSwiperList vendorList={favoriteVendors.vendors} />
          {totalVendorsCount > TOTAL_VENDORS_IN_SWIPER && (
            <VendorWrapper>
              <AdditionalVendorButton
                icon={<ChevronRight size="xlarge" title="arrow" />}
                size="small"
                onClick={() => {
                  setShowVendors(true);
                  onSeeAllClick();
                }}
                aria-label="ver todos"
              />
              <Typography token="text-utility-underline-medium">Ver todos</Typography>
            </VendorWrapper>
          )}
        </VendorSwiperContainer>
        <Vendors open={showVendors} onClose={() => setShowVendors(false)} />
      </VendorsSwiperContent>
    </>
  );
};

VendorsSwiper.propTypes = {
  favoriteVendors: PropTypes.shape({
    total: PropTypes.number,
    vendors: PropTypes.arrayOf(
      PropTypes.shape({
        discount: PropTypes.shape({
          type: PropTypes.string,
          value: PropTypes.number,
        }),
        name: PropTypes.string,
        icon: PropTypes.string,
        priority: PropTypes.number,
        ratio: PropTypes.number,
      }),
    ),
  }),
  onSeeAllClick: PropTypes.func,
  onSwiped: PropTypes.func,
};
