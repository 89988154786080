const TRACKING_CLICK_LOCATIONS = {
  SUBSCRIBE: 'suscribir',
  TERMS_AND_CONDITIONS: 'terminos_y_condiciones',
  FAQ: 'faq',
  HELP: 'ayuda',
  CANCEL: 'cancelar',
  SEE_MORE: 'ver_mas',
  MANAGE: 'gestionar',
  CTA: 'cta',
  PAYMENTS: 'pagos',
  CHANGE_PAYMENT_INSTRUMENT: 'changePaymentInstrument',
  CANCEL_CLICK: 'cancelar_click',
  CHANGE_MULTIPLE_PAYMENT_CARD: 'changeMultiplePaymentCard',
  REACTIVATE_SUBSCRIPTION: 'reactivateSubscription',
  MULTIPLE_PAYMENT_CARD_TERMS: 'cobro_multiples_tarjetas_terminos_y_condiciones',
  MARKDOWN_SWIMLANE: 'markdowns_swimlane',
};

const TRACKING_SWIP_LOCATIONS = {
  MARKDOWN_SWIMLANE: 'markdowns_swimlane',
  VENDORS_SWIMLANE: 'vendors_swimlane',
};

const TRACKING_EVENTS = {
  PLUS_PLAN_DETAILS_LOADED: 'loyalty_plan_details.loaded',
  PLUS_PLAN_DETAILS_CLICKED: 'loyalty_plan_details.clicked',
  PLUS_PLAN_DETAILS_SWIPED: 'swimlane.swiped',
  SUBSCRIPTION_DETAILS_LOADED: 'loyalty_subscription_details.loaded',
  SUBSCRIPTION_DETAILS_CLICKED: 'loyalty_subscription_details.clicked',
  SUBSCRIPTION_COMPLETED_LOADED: 'loyalty_subscription_completed.loaded',
  SUBSCRIPTION_COMPLETED: 'loyalty_subscription_completed',
  SUBSCRIPTION_MANAGEMENT_LOADED: 'loyalty_subscription_management.loaded',
  SUBSCRIPTION_MANAGEMENT_CLICKED: 'loyalty_subscription_management.clicked',
  PAYMENT_INSTRUMENT_CHANGED: 'loyalty_payment_instrument.updated',
  SUBSCRIPTION_CANCEL_LOADED: 'loyalty_subscription_cancel.loaded',
};

module.exports = { TRACKING_EVENTS, TRACKING_CLICK_LOCATIONS, TRACKING_SWIP_LOCATIONS };
