import { useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { SUBSCRIPTION_STATUS } from '@commons/models/subscriptionStatusEnum';
import { TRACKING_EVENTS } from '@commons/constants/tracker';
import CUSTOM_EVENTS from '@commons/constants/customEvents';
import { useTracker, TRACKING_NULL_ERROR } from '@app/contexts/tracker';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { fetchSubscription } from '@app/services/loyalty/fetchSubscription';
import { sendEvent } from '@app/services/loyalty/sendEvent';
import logger from '@app/logger';

export const useStatusPageTracker = ({ isPageReady, isErrorPage }) => {
  const loadedRef = useRef(false);
  const subscriptionQuery = useQuery(queryKeyBuilders.subscription(), fetchSubscription);
  const { tracker, origin } = useTracker();
  const { city } = usePublicEnv();

  useEffect(() => {
    const { data, isSuccess } = subscriptionQuery;

    if (isSuccess && !loadedRef.current && isPageReady) {
      sendEvent(
        isErrorPage
          ? CUSTOM_EVENTS.SUBSCRIPTION_DETAILS_LOADED_ERROR
          : CUSTOM_EVENTS.SUBSCRIPTION_DETAILS_LOADED,
      );
      tracker.track(TRACKING_EVENTS.SUBSCRIPTION_DETAILS_LOADED, {
        origin,
        status: data ? data.status : SUBSCRIPTION_STATUS.UNSUBSCRIBED,
        planId: data ? data.plan_id : undefined,
        cityId: city?.id,
        errorMessage: isErrorPage ? 'Error loading status' : TRACKING_NULL_ERROR,
      });

      loadedRef.current = !isErrorPage;
    }
  }, [tracker, origin, subscriptionQuery, city?.id, isPageReady, isErrorPage]);

  const trackClick = (clickLocation, eventClickLocation = clickLocation) => {
    const { data } = subscriptionQuery;
    const event = `${TRACKING_EVENTS.SUBSCRIPTION_DETAILS_CLICKED}.${eventClickLocation}`;

    if (CUSTOM_EVENTS.has(event)) {
      sendEvent(event);
    } else {
      logger.error(`Event ${event} is not registered for status, ignoring it`);
    }

    tracker.track(TRACKING_EVENTS.SUBSCRIPTION_DETAILS_CLICKED, {
      origin,
      status: data ? data.status : SUBSCRIPTION_STATUS.UNSUBSCRIBED,
      planId: data ? data.plan_id : undefined,
      cityId: city?.id,
      clickLocation,
    });
  };

  return { trackClick };
};
