import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { NavbarWrapper, TopAppBarWrapper } from './styled-components';

export const FixedHeader = ({ backgroundImage, handleBack, isWebviewIos, showTitle }) => {
  return (
    <NavbarWrapper backgroundImage={backgroundImage} isWebviewIos={isWebviewIos}>
      <TopAppBarWrapper
        onGoBack={handleBack}
        category="inverted"
        title={
          showTitle ? (
            <Typography token="text-title-small" color="text-color-inverted">
              Cancelar suscripción
            </Typography>
          ) : (
            ''
          )
        }
      />
    </NavbarWrapper>
  );
};

FixedHeader.propTypes = {
  backgroundImage: PropTypes.string,
  handleBack: PropTypes.func,
};
