import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { sendPaymentConfigurationStrategy } from '@app/services/loyalty/sendPaymentConfigurationStrategy';
import { acceptTermsAndConditions } from '@app/services/loyalty/acceptTermsAndConditions';
import { sendEvent } from '@app/services/loyalty/sendEvent';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Card from '@pedidosya/web-fenix/molecules/CardContainer';
import CardIcon from '@pedidosya/web-fenix/icons/CardIcon';
import Switch from '@pedidosya/web-fenix/atoms/Switch';
import BoxMessage from '@pedidosya/web-fenix/molecules/BoxMessage';
import Divider from '@pedidosya/web-fenix/atoms/Divider';
import AlternatePaymentCoachmark from './AlternatePaymentCoachmark';
import AlternatePaymentTyC from './AlternatePaymentTyC';
import CUSTOM_EVENTS from '@commons/constants/customEvents';
import { OtherCardGrid, SwitchContainer, CardContent, TycContainer } from './styled-components';

const AlternatePaymentCard = ({
  planId,
  countryId,
  checked,
  onChangeMultiplePaymentCardClick,
  showCoachmark,
  showTerms,
  onTermAndConditionClick,
  onChangePaymentConfig,
}) => {
  const [selected, setSelected] = useState(checked);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const { mutate, isLoading } = useMutation(sendPaymentConfigurationStrategy);
  const { mutate: acceptTermsMutate, isLoading: acceptTermsIsLoading } =
    useMutation(acceptTermsAndConditions);

  const handleClick = () => {
    if (isLoading || acceptTermsIsLoading) return;

    if (showTerms) {
      acceptTermsMutate(
        { planId, countryId },
        {
          onSuccess: () => {
            changePaymentConfig();
          },
          onError: (err) => {
            onError(selected);
            logger.error(err);
          },
        },
      );
    } else {
      changePaymentConfig();
    }
  };

  const changePaymentConfig = () => {
    onChangeMultiplePaymentCardClick();
    sendEvent(CUSTOM_EVENTS.SUBSCRIPTION_MANAGEMENT_CLICKED_CHANGE_MULTIPLE_PAYMENT_CARD);

    const value = !selected;
    setSelected(value);
    mutate(
      { planId, strategy: value ? 'ALL' : 'ORIGINAL' },
      {
        onSuccess: () => {
          onChangePaymentConfig();
        },
        onError: (err) => {
          onError(selected);
          logger.error(err);
        },
      },
    );
  };

  const onError = (selected) => {
    setOpen(true);
    setMessage(
      `No pudimos ${
        selected ? 'deshabilitar' : 'habilitar'
      } el uso de tus tarjetas, vuelve a intentar.`,
    );
    setSelected(selected);
  };

  return (
    <>
      <AlternatePaymentCoachmark showCoachmark={showCoachmark}>
        <Card withShadow={false}>
          <CardContent>
            <OtherCardGrid>
              <CardIcon />
              <Typography token="font-body-midcontrast-sentence-small" color="text-color-secondary">
                Usar cualquiera de mis tarjetas guardadas cuando falle el cobro de mi suscripción.
              </Typography>
              <SwitchContainer>
                <Switch state="enabled" selected={selected} onClick={handleClick} />
              </SwitchContainer>
            </OtherCardGrid>
            {showTerms && (
              <>
                <Divider />
                <TycContainer>
                  <AlternatePaymentTyC
                    planId={planId}
                    onTermAndConditionClick={onTermAndConditionClick}
                  />
                </TycContainer>
              </>
            )}
          </CardContent>
        </Card>
      </AlternatePaymentCoachmark>
      {open && (
        <BoxMessage variant="loud" type="error" message={message} onClose={() => setOpen(false)} />
      )}
    </>
  );
};

export default AlternatePaymentCard;
