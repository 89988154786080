import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import FenixIcon from '@pedidosya/web-fenix/icons/FenixIcon';
import Divider from '@pedidosya/web-fenix/atoms/Divider';
import { RowList, NameContainer, BenefitDividerContainer, BenefitsList } from './styled-components';

export const Benefits = ({ benefitsSummary }) => {
  return (
    <>
      <RowList>
        <Typography token="text-title-small" color="text-color-primary">
          {benefitsSummary.title}
        </Typography>
      </RowList>

      <BenefitsList role="benefits-list">
        {benefitsSummary.benefits.map((benefit, idx) => {
          return (
            <>
              {idx > 0 && (
                <BenefitDividerContainer>
                  <Divider />
                </BenefitDividerContainer>
              )}
              <RowList key={benefit.type} aria-label="benefits">
                <NameContainer>
                  <FenixIcon color="default" size="medium" token={benefit.icon} />
                  <Typography token="text-base-large" color="text-color-primary">
                    {benefit.title}
                  </Typography>
                </NameContainer>
              </RowList>
            </>
          );
        })}
      </BenefitsList>
    </>
  );
};

Benefits.propTypes = {
  benefitsSummary: PropTypes.shape({
    title: PropTypes.string,
    benefits: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        title: PropTypes.string,
        amount: PropTypes.number,
      }),
    ),
  }),
};
