import React from 'react';
import PropTypes from 'prop-types';
import Navbar from '@pedidosya/web-fenix/organisms/Navbar';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import ChevronLeft from '@pedidosya/web-fenix/icons/ChevronLeft';
import { useIsWebviewIos } from '@app/contexts/deviceInfo/hooks';
import { FormattedMessage } from 'react-intl';
import { HeaderIOS, NavbarContent, NavbarIcon, NavbarRightSpacer } from './styled-components';
import messages from '../../messages';

export const Header = ({ onClickGoBack }) => {
  const isWebviewIos = useIsWebviewIos();

  return (
    <>
      <HeaderIOS isWebviewIos={isWebviewIos} />
      <Navbar transparent>
        <NavbarContent>
          <NavbarIcon>
            <ChevronLeft size="medium" onClick={() => onClickGoBack()} color="icon-color-primary" />
          </NavbarIcon>
          <Typography token="font-subtitle-midcontrast-sentence-large" color="text-color-primary">
            <FormattedMessage {...messages.title} />
          </Typography>
          {/* The followigin spacer has the same width of the icon to allow the title to be properly centerted */}
          <NavbarRightSpacer />
        </NavbarContent>
      </Navbar>
    </>
  );
};

Header.propTypes = {
  onClickGoBack: PropTypes.func.isRequired,
};
