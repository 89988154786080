module.exports = {
  user: () => ['user'],
  subscription: () => ['subscription'],
  subscriptionStatus: () => ['subscription', 'status'],
  plans: () => ['plans'],
  landing: () => ['landing'],
  promotionTc: (tcUrl) => ['landing', 'promotion', 'tcUrl', tcUrl],
  plan: (planId) => ['plans', 'detail', +planId],
  planPrice: (planId) => ['plans', 'detail', +planId, 'price'],
  planTyc: (planId) => ['plans', 'detail', +planId, 'tc'],
  markdown: (planId) => ['markdowns', +planId],
  favoriteVendors: () => ['vendors'],
  status: () => ['status'],
  statusDetail: () => ['status', 'detail'],
  statusPayments: (planId) => ['status', 'payments', +planId],
  statusVendors: () => ['status', 'vendors'],
  statusBill: (billId) => ['status', 'bill', +billId],
  statusTyc: (type) => ['tc', type],
  cancellation: () => ['cancellation'],
  redirectTyc: () => ['redirect', 'tc'],
};
