export const TRACKING_ORIGINS = {
  SIDEMENU: 'sidemenu',
  BANNER: 'banner',
  EMAIL: 'email',
  NOTIFICATION_CENTER: 'notification_center',
  DEEPLINK: 'deeplink',
  PUSH: 'push',
  CANCELLATION_FLOW: 'cancellation_flow',
  ORDER_STATUS: 'order_status',
  LOYALTY_PROGRAM: 'loyaltyprogram',
};

export const TRACKING_NULL_ERROR = 'NULL';
