const { TRACKING_EVENTS } = require('./tracker');

const dinamicEvents = {
  // Destination events
  SUBSCRIPTION_COMPLETED_ALL_IN_ONE: (origin = 'deeplink') =>
    `${TRACKING_EVENTS.SUBSCRIPTION_COMPLETED}.all_in_one.${origin}`,
  SUBSCRIPTION_COMPLETED_LANDING: (origin = 'deeplink') =>
    `${TRACKING_EVENTS.SUBSCRIPTION_COMPLETED}.landing.${origin}`,
};

module.exports = { ...dinamicEvents };
