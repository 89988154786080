import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { FAQ } from '@app/components/FrequentlyAskedQuestions';
import { FaqWrapper, FaqTitle } from './styled-components';
import { formatCurrency } from '@app/utils/format/currency';
import { getCurrencySymbol } from '@pedidosya/currency-formatter';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { FAQ_TYPES } from '@commons/models/faqEnum';
import { BENEFIT_TYPES } from '@commons/models/benefitTypeEnum';
import messages from './messages';

export const FrequentlyAskedQuestion = React.forwardRef(
  (
    { benefitTypes, onQuestionClick = Function.prototype, cities, hasFreeTrial, minCharge },
    ref,
  ) => {
    const intl = useIntl();
    const { country } = usePublicEnv();
    const currencySymbol = getCurrencySymbol(country?.shortName)?.symbol;

    const questions = [
      {
        id: FAQ_TYPES.CONDITIONS,
        question: intl.formatMessage(messages.conditionsQuestion),
        answer: intl.formatMessage(messages.conditionsAnswer),
      },
      benefitTypes.some((type) =>
        [BENEFIT_TYPES.DMARTS, BENEFIT_TYPES.DMART_CHARGEBACKS].includes(type),
      ) && {
        id: FAQ_TYPES.MARKETS,
        question: intl.formatMessage(messages.marketsQuestion),
        answer: intl.formatMessage(messages.marketsAnswer),
      },
      {
        id: FAQ_TYPES.UNSUBSCRIBE,
        question: intl.formatMessage(messages.unsubscribeQuestion),
        answer: intl.formatMessage(messages.unsubscribeAnswer),
      },
      {
        id: FAQ_TYPES.CITIES,
        question: intl.formatMessage(messages.citiesQuestion),
        answer:
          cities && cities.length > 0
            ? intl.formatMessage(messages.citiesAnswer, { cities: cities.join(', ') })
            : intl.formatMessage(messages.allCitiesAnswer, { country: country.name }),
      },
      hasFreeTrial && {
        id: FAQ_TYPES.FREE_TRIAL,
        question: intl.formatMessage(messages.freeTrialQuestion),
        answer: intl.formatMessage(messages.freeTrialAnswer, {
          amount: formatCurrency(intl, { amount: minCharge, currencySymbol }),
        }),
      },
    ].filter(Boolean);

    return (
      <FaqWrapper ref={ref}>
        <FaqTitle>
          <Typography token="text-title-medium">
            <FormattedMessage {...messages.title} />
          </Typography>
        </FaqTitle>
        <FAQ questions={questions} onQuestionClick={onQuestionClick} />
      </FaqWrapper>
    );
  },
);

FrequentlyAskedQuestion.propTypes = {
  planId: PropTypes.number,
  onTermAndConditionClick: PropTypes.func,
  mov: PropTypes.number,
  cities: PropTypes.arrayOf(PropTypes.string),
  currencySymbol: PropTypes.string,
  minCharge: PropTypes.number,
  onQuestionClick: PropTypes.func,
  hasFreeTrial: PropTypes.bool,
  benefitTypes: PropTypes.arrayOf(PropTypes.string),
};
