import React, { useState, Fragment } from 'react';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useIsWebviewIos } from '@app/contexts/deviceInfo/hooks';
import ThumbnailImage from '@pedidosya/web-fenix/atoms/ThumbnailImage';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Tag from '@pedidosya/web-fenix/atoms/Tag';
import Divider from '@pedidosya/web-fenix/atoms/Divider';
import ChevronRight from '@pedidosya/web-fenix/icons/ChevronRight';
import FenixBottomSheet from '@pedidosya/web-fenix/organisms/BottomSheet';
import {
  PromotionsListTitle as Title,
  PromotionItemContainer as ItemContainer,
  TCContainer,
  DataContainer,
  DescriptionText,
} from './styled-components';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { decorateTitle } from '@app/utils/promotions';
import TcSkeleton from './TcSkeleton';
import { fetchPromotionTC } from '@app/services/loyalty/fetchPromotionTC';

const PromotionsList = ({ promotions, open, onClose }) => {
  const isWebviewIos = useIsWebviewIos();
  const [tcUrl, setTcUrl] = useState('');

  const { isLoading, data } = useQuery(
    queryKeyBuilders.promotionTc(tcUrl),
    () => fetchPromotionTC(tcUrl),
    { enabled: Boolean(tcUrl) },
  );

  const showTerm = async (promotion) => {
    setTcUrl(promotion?.terms_and_conditions?.url);
  };

  const backTc = () => {
    setTcUrl('');
  };

  const onClosePromotions = () => {
    onClose();

    // Go back to promotions main list after animation finished
    setTimeout(() => {
      backTc();
    }, 300);
  };

  return (
    <FenixBottomSheet
      handler={true}
      open={open}
      scrollEnabled={true}
      onGoBack={tcUrl ? backTc : null}
      title={
        <Title>
          <Typography token="text-title-small" color="shape-color-surface-secondary">
            {tcUrl ? (
              <div aria-label="term-and-conditions">Términos y condiciones</div>
            ) : (
              'Promociones para suscribirte'
            )}
          </Typography>
        </Title>
      }
      onClickOut={onClosePromotions}
      onClose={onClosePromotions}
    >
      <TCContainer isWebviewIos={isWebviewIos}>
        {tcUrl ? (
          isLoading ? (
            <TcSkeleton />
          ) : (
            <Typography token="text-base-large" dangerouslySetInnerHTML={{ __html: data }} />
          )
        ) : (
          promotions.map((promotion, idx) => (
            <Fragment key={idx}>
              {idx > 0 && <Divider />}
              <ItemContainer
                aria-label="promotions"
                onShowInternalTC={() => {
                  showTerm(promotion);
                }}
                promotion={promotion}
              >
                <ThumbnailImage src={promotion.icon} stroke shape="square" size="M" />
                <DataContainer>
                  <Tag category="deals-and-discounts" label={promotion.title} />
                  <DescriptionText>
                    <Typography token="text-base-medium" color="shape-color-surface-secondary">
                      {decorateTitle(promotion.description)}
                    </Typography>
                  </DescriptionText>
                  <Typography token="text-base-medium" color="text-color-tertiary">
                    {promotion.footer}
                  </Typography>
                </DataContainer>
                {promotion?.terms_and_conditions?.url && (
                  <ChevronRight
                    size="small"
                    title="Ver condiciones"
                    style={{ alignSelf: 'center' }}
                  />
                )}
              </ItemContainer>
            </Fragment>
          ))
        )}
      </TCContainer>
    </FenixBottomSheet>
  );
};

PromotionsList.propTypes = {
  promotions: PropTypes.array,
};

export default PromotionsList;
