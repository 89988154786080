import styled from 'styled-components';

export const MessageBox = styled.div`
  border: 1px solid ${({ color }) => color};
  border-left-width: 4px;
  display: flex;
  align-items: center;
  background-color: white;
  padding: ${({ theme }) => theme.space('spacing-08')};
  margin: ${({ theme }) => `0 ${theme.space('spacing-08')}`};
  border-radius: 8px;
  gap: ${({ theme }) => theme.space('spacing-06')};

  > svg {
    flex-shrink: 0;
  }
`;
