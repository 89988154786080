/**
 * We are implementing the formatting function in this way a temporary solution as intl.formatNumber
 * does not accept a currency symbol as parameter. The final solution should include receiving the
 * currency code instead of the currency symbol. It will be done in:
 *
 * https://peyaplanning.atlassian.net/browse/LOY-1828
 *
 */
import logger from '@app/logger';

export function formatCurrency(intl, { amount, currencySymbol, currencyCode = 'FOO' } = {}) {
  const formattedAmount = intl.formatNumber(amount, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: Number.isInteger(amount) ? 0 : 2,
  });

  const DEFAULT_CURRENCY_SYMBOL = '$';

  if (currencySymbol) {
    return formattedAmount.replace(currencyCode, currencySymbol);
  } else {
    logger.error(
      `[formatCurrency] - $ instead of original country currencySymbol. Incoming currencySymbol: ${currencySymbol} | amount: ${amount}`,
    );
    return formattedAmount.replace(currencyCode, DEFAULT_CURRENCY_SYMBOL);
  }
}
