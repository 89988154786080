import { defineMessages } from 'react-intl';

export default defineMessages({
  termsAndConditions: {
    id: 'app.faq.termsAndConditions',
    defaultMessage: 'Consultá los <a>Términos y condiciones</a> del programa.',
  },
  cancelSubscriptionTitle: {
    id: 'app.status.cancelSubscriptionTitle',
    defaultMessage: 'Cancelá tu suscripción cuando quieras.',
  },
});
