import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@pedidosya/web-fenix/atoms/Button';
import { PaymentLinkWrapper } from './styled-components';
import { Payments } from './Payments';
import messages from '../../../status/messages';

export const PaymentLink = ({ planId, onShowPayments = Function.prototype }) => {
  const [showPayments, setShowPayments] = useState(false);

  const onPaymentsClick = () => {
    setShowPayments(true);
    onShowPayments();
  };
  return (
    <PaymentLinkWrapper>
      <Button
        label={<FormattedMessage {...messages.paymentsHistory} />}
        size="large"
        hierarchy="tertiary"
        onClick={() => onPaymentsClick()}
      />
      <Payments planId={planId} open={showPayments} onClose={() => setShowPayments(false)} />
    </PaymentLinkWrapper>
  );
};
