export const ROUTES = {
  LANDING: '/loyalty-program',
  STATUS: '/loyalty-program/status',
  PLANS: '/loyalty-program/plans',
  PLAN_LANDING: '/loyalty-program/landing',
  CONGRATS: '/loyalty-program/congrats',
  PUBLIC_ENVIRONMENT: '/loyalty-program-env',
  STATUS_DETAILS: '/loyalty-program/status-details',
  PAYMENT_INSTRUMENT_CHANGE_SUCCESS: '/loyalty-program/payment-instrument/success',
  CANCELLATION: '/loyalty-program/cancellation',
  REDIRECT: '/loyalty/redirect',
  UPCOMING_TYC: '/loyalty-program/upcoming_tyc_ar',
};
