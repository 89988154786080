import { useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { TRACKING_EVENTS } from '@commons/constants/tracker';
import CUSTOM_EVENTS from '@commons/constants/customEvents';
import DINAMIC_EVENTS from '@commons/constants/dinamicEvents';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { useTracker, TRACKING_NULL_ERROR } from '@app/contexts/tracker';
import { fetchSubscription } from '@app/services/loyalty/fetchSubscription';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { sendEvent } from '@app/services/loyalty/sendEvent';
import { sendCustomEvent } from '@app/services/loyalty/sendCustomEvent';
import { PRESENTATION_QUERY } from '@commons/models/presentationQueryEnum';

export const useCongratsPageTracker = ({ isPageReady, isErrorPage }) => {
  const loadedRef = useRef(false);
  const subscriptionQuery = useQuery(queryKeyBuilders.subscription(), fetchSubscription);
  const { tracker, origin } = useTracker();
  const { city, presentation } = usePublicEnv();

  useEffect(() => {
    const { data: subscription, isSuccess } = subscriptionQuery;

    if (isSuccess && !loadedRef.current && isPageReady) {
      if (presentation === PRESENTATION_QUERY.MODAL) {
        sendEvent(
          isErrorPage
            ? CUSTOM_EVENTS.SUBSCRIPTION_ALL_IN_ONE_COMPLETED_LOADED_ERROR
            : CUSTOM_EVENTS.SUBSCRIPTION_ALL_IN_ONE_COMPLETED_LOADED,
        );
        if (!isErrorPage) sendCustomEvent(DINAMIC_EVENTS.SUBSCRIPTION_COMPLETED_ALL_IN_ONE(origin));
      } else {
        sendEvent(
          isErrorPage
            ? CUSTOM_EVENTS.SUBSCRIPTION_COMPLETED_LOADED_ERROR
            : CUSTOM_EVENTS.SUBSCRIPTION_COMPLETED_LOADED,
        );
        if (!isErrorPage) sendCustomEvent(DINAMIC_EVENTS.SUBSCRIPTION_COMPLETED_LANDING(origin));
      }

      tracker.track(TRACKING_EVENTS.SUBSCRIPTION_COMPLETED_LOADED, {
        origin,
        status: subscription.status,
        planId: subscription.plan_id,
        cityId: city?.id,
        errorMessage: isErrorPage ? 'Error loading congrats' : TRACKING_NULL_ERROR,
        presentation,
      });

      loadedRef.current = !isErrorPage;
    }
  }, [tracker, origin, subscriptionQuery, city?.id, isPageReady, isErrorPage, presentation]);
};
