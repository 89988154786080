import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Image from '@pedidosya/web-fenix/foundations/Image';
import { BenefitConditions as Conditions } from '@app/components/BenefitConditions';
import { TitleContainer } from './styled-components';

export const BenefitConditions = ({ icon, title, bullets }) => {
  return (
    <Conditions
      content={
        <TitleContainer>
          <Typography token="text-highlight-large" color="text-color-primary">
            {title}
          </Typography>
          <Image src={icon} width={70} />
        </TitleContainer>
      }
      bullets={bullets}
    />
  );
};

BenefitConditions.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  bullets: PropTypes.arrayOf(PropTypes.string),
};
