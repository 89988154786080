import { useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { SUBSCRIPTION_STATUS } from '@commons/models/subscriptionStatusEnum';
import { TRACKING_EVENTS } from '@commons/constants/tracker';
import CUSTOM_EVENTS from '@commons/constants/customEvents';
import { useTracker, TRACKING_NULL_ERROR } from '@app/contexts/tracker';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { fetchSubscription } from '@app/services/loyalty/fetchSubscription';
import { sendEvent } from '@app/services/loyalty/sendEvent';
import logger from '@app/logger';

export const useLandingPageTracker = ({ isPageReady, isErrorPage, hasVendorChannel }) => {
  const loadedRef = useRef(false);

  const subscriptionQuery = useQuery(queryKeyBuilders.subscription(), fetchSubscription);

  const { tracker, origin } = useTracker();
  const { city } = usePublicEnv();

  useEffect(() => {
    const { data: subscription, isSuccess } = subscriptionQuery;

    if (isSuccess && !loadedRef.current && isPageReady) {
      sendEvent(
        isErrorPage
          ? CUSTOM_EVENTS.PLUS_PLAN_DETAILS_LOADED_ERROR
          : CUSTOM_EVENTS.PLUS_PLAN_DETAILS_LOADED,
      );

      tracker.track(TRACKING_EVENTS.PLUS_PLAN_DETAILS_LOADED, {
        origin,
        status: subscription ? subscription.status : SUBSCRIPTION_STATUS.UNSUBSCRIBED,
        cityId: city?.id,
        errorMessage: isErrorPage ? 'Error loading landing' : TRACKING_NULL_ERROR,
      });

      if (hasVendorChannel) sendEvent(CUSTOM_EVENTS.PLUS_PLAN_CHANNEL_LOADED);

      loadedRef.current = !isErrorPage;
    }
  }, [tracker, origin, subscriptionQuery, city, isPageReady, isErrorPage, hasVendorChannel]);

  const trackClick = (clickLocation, eventClickLocation = clickLocation) => {
    const { data: subscription } = subscriptionQuery;
    const event = `${TRACKING_EVENTS.PLUS_PLAN_DETAILS_CLICKED}.${eventClickLocation}`;

    if (CUSTOM_EVENTS.has(event)) {
      sendEvent(event);
    } else {
      logger.error(`Event ${event} is not registered for landing, ignoring it`);
    }

    tracker.track(TRACKING_EVENTS.PLUS_PLAN_DETAILS_CLICKED, {
      origin,
      status: subscription ? subscription.status : SUBSCRIPTION_STATUS.UNSUBSCRIBED,
      cityId: city?.id,
      clickLocation,
    });
  };

  const trackSwiped = (swipLocation, eventSwipLocation = swipLocation) => {
    const event = `${TRACKING_EVENTS.PLUS_PLAN_DETAILS_SWIPED}.${eventSwipLocation}`;

    if (CUSTOM_EVENTS.has(event)) {
      sendEvent(event);
    } else {
      logger.error(`Event ${event} is not registered for landing, ignoring it`);
    }

    tracker.track(TRACKING_EVENTS.PLUS_PLAN_DETAILS_SWIPED, {
      swimlaneStrategy: 'loyalty_plan_details',
      swimlaneTitle: swipLocation,
    });
  };

  return { trackClick, trackSwiped };
};
