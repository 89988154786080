import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { formatCurrency } from '@app/utils/format/currency';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import FenixIcon from '@pedidosya/web-fenix/icons/FenixIcon';
import Divider from '@pedidosya/web-fenix/atoms/Divider';
import Invoice from '@pedidosya/web-fenix/icons/Invoice';
import { RowList, NameContainer, DividerContainer } from './styled-components';

export const Savings = ({ savings, monthlyFee, currencySymbol }) => {
  const intl = useIntl();

  return (
    <>
      <div role="savings-list">
        {savings.categories.map((category, _) => {
          return (
            <RowList key={category.type} aria-label="savings">
              <NameContainer>
                <FenixIcon color="default" size="medium" token={category.icon} />
                <Typography token="text-base-large" color="text-color-primary">
                  {category.title}
                </Typography>
              </NameContainer>
              <div>
                <Typography token="text-base-large" color="text-color-primary">
                  {formatCurrency(intl, { amount: category.amount, currencySymbol })}
                </Typography>
              </div>
            </RowList>
          );
        })}
      </div>
      {savings.categories?.length > 1 && (
        <RowList>
          <Typography token="text-highlight-large" color="text-color-primary">
            {savings.subtotal.title || 'Subtotal'}
          </Typography>
          <div>
            <Typography token="text-highlight-large" color="text-color-primary">
              {formatCurrency(intl, {
                amount: savings.subtotal.amount || savings.subtotal,
                currencySymbol,
              })}
            </Typography>
          </div>
        </RowList>
      )}
      <DividerContainer>
        <Divider />
      </DividerContainer>
      <RowList>
        <NameContainer>
          <Invoice size={'medium'} color="icon-color-tertiary" filled />
          <Typography token="text-base-large" color="text-color-tertiary">
            {monthlyFee.title}
          </Typography>
        </NameContainer>
        <div>
          <Typography token="text-base-large" color="text-color-tertiary">
            {formatCurrency(intl, { amount: -monthlyFee.amount, currencySymbol })}
          </Typography>
        </div>
      </RowList>
      <DividerContainer>
        <Divider />
      </DividerContainer>
      <RowList>
        <Typography token="text-highlight-large" color="text-color-primary">
          {savings.total.title || 'Tu ahorro mensual con Plus'}
        </Typography>
        <div>
          <Typography token="text-highlight-large" color="text-color-feedback-positive">
            {formatCurrency(intl, {
              amount: savings.total.amount || savings.total,
              currencySymbol,
            })}
          </Typography>
        </div>
      </RowList>
    </>
  );
};

Savings.propTypes = {
  savings: PropTypes.shape({
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        title: PropTypes.string,
        amount: PropTypes.number,
      }),
    ),
    subtotal: PropTypes.number,
    total: PropTypes.number,
  }),
  monthlyFee: PropTypes.shape({
    title: PropTypes.string,
    amount: PropTypes.number,
  }),
  currencySymbol: PropTypes.string,
};
