import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ThemeProvider } from 'styled-components';
import fenixTheme from '@pedidosya/web-fenix/theme';
import { useHeaderConfiguration } from '../../hooks/useHeaderConfiguration';
import { ROUTES } from '@commons/services/routes.service';
import { Cancel } from './components/Cancel';
import Spinner from '../../components/Spinner';
import { DialogBrandError } from '../../components/Error';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { cancelSubscription } from '@app/services/loyalty/cancelSubscription';
import { fetchSubscription } from '../../services/loyalty/fetchSubscription';
import { useCancellationPageTracker } from './tracking/CancellationPageTracker';
import messages from './messages';

export const CancellationPage = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const subscriptionQuery = useQuery(queryKeyBuilders.subscription(), () => fetchSubscription());
  const { data, isLoading, isError } = subscriptionQuery;
  const [cancelLoaded, setCancelLoaded] = useState(false);

  const { trackClick, trackUnsubscribe } = useCancellationPageTracker({
    isErrorPage: isError,
    isPageReady: !isLoading,
  });

  const [showCancellationError, setShowCancellationError] = useState(false);
  const intl = useIntl();

  useHeaderConfiguration();

  const goBack = () => {
    history.length > 1 ? history.goBack() : history.replace(ROUTES.STATUS);
  };

  const goToStatus = () => {
    history.replace(ROUTES.STATUS, {
      cancelFeedback: {
        label: intl.formatMessage(messages.cancelSubscriptionPositiveMessage),
        type: 'positive',
      },
    });
  };

  const updateStatus = () => {
    queryClient.removeQueries(queryKeyBuilders.subscription());
    queryClient.removeQueries(queryKeyBuilders.status());
  };

  const onCancelSubscriptionBrandErrorClose = () => {
    setShowCancellationError(false);
  };

  const cancelSubscriptionMutation = useMutation(cancelSubscription);
  const onCancelSubscription = async () => {
    trackUnsubscribe();
    const planId = data?.plan_id;

    try {
      setCancelLoaded(true);
      await cancelSubscriptionMutation.mutateAsync({ planId });
      updateStatus();
      goToStatus();
    } catch (error) {
      logger.error(error);
      setShowCancellationError(true);
    } finally {
      setCancelLoaded(false);
    }
  };

  if (isLoading || cancelLoaded) {
    return <Spinner />;
  }
  return (
    <ThemeProvider theme={fenixTheme}>
      <Cancel onGoBack={goBack} onCancelSubscription={onCancelSubscription} />
      <DialogBrandError
        open={showCancellationError}
        onClose={onCancelSubscriptionBrandErrorClose}
        message={intl.formatMessage(messages.cancelModalErrorTitle)}
        title={intl.formatMessage(messages.cancelModalErrorMessage)}
      />
    </ThemeProvider>
  );
};
