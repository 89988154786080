import { defineMessages } from 'react-intl';

export default defineMessages({
  brandError: {
    id: 'app.brandError.genericError',
    defaultMessage: 'No pudimos llevarte a donde esperabas.',
  },
  cancelModalErrorTitle: {
    id: 'app.status.cancelModalErrorTitle',
    defaultMessage: 'No pudimos cancelar tu suscripción',
  },
  cancelModalErrorMessage: {
    id: 'app.status.cancelModalErrorMessage',
    defaultMessage: 'Intenta nuevamente en unos minutos.',
  },

  cancelSubscriptionPositiveMessage: {
    id: 'app.status.cancelSubscriptionPositiveMessage',
    defaultMessage: 'Tu suscripción fue cancelada.',
  },
});
