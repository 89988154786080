import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@tanstack/react-query';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { TERMS_AND_CONDITIONS } from '@commons/models/termsAndConditionTypeEnum';
import { Typography } from '@pedidosya/web-fenix/atoms';
import { TermsAndConditions } from '@app/components/TermsAndConditions';
import { ButtonLink } from '@app/components/ButtonLink';
import { fetchSubscriptionTC } from '@app/services/loyalty/fetchSubscriptionTC';
import messages from './messages';

export const TermsAndCondition = ({ onTermAndConditionClick, type }) => {
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const onCloseTermsAndConditions = () => setShowTermsAndConditions(false);

  const { isLoading, data } = useQuery(
    queryKeyBuilders.statusTyc(type),
    () => fetchSubscriptionTC({ type }),
    { enabled: showTermsAndConditions },
  );

  const openTermsAndConditions = () => {
    onTermAndConditionClick();
    setShowTermsAndConditions(true);
  };

  const getTermsAndConditionsText = () => {
    switch (type) {
      case TERMS_AND_CONDITIONS.PLUS_GENERAL:
        return (
          <Typography token="text-base-medium" color="text-color-primary">
            <FormattedMessage
              {...messages.termsAndConditions}
              values={{
                a: (chunks) => (
                  <ButtonLink onClick={() => openTermsAndConditions()}>
                    <Typography token="font-label-highercontrast-underline-medium">
                      {chunks}
                    </Typography>
                  </ButtonLink>
                ),
              }}
            />
          </Typography>
        );
      default:
        null;
    }
  };
  return (
    <>
      <div>{getTermsAndConditionsText()}</div>
      <TermsAndConditions
        open={showTermsAndConditions}
        onClose={onCloseTermsAndConditions}
        isLoading={isLoading}
        data={data}
      />
    </>
  );
};
