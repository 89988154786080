import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import fenixTheme from '@pedidosya/web-fenix/theme';
import { hasFreeTrial, hasPercentage } from '@commons/utils/markdowns';
import { getUnitIdFromValue } from '@commons/models/durationUnitEnum';
import { formatCurrency } from '@app/utils/format/currency';
import { getCurrencySymbol } from '@pedidosya/currency-formatter';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { Container, ConcatContainer, PriceContainer, DiscountContainer } from './styled-components';
import TagDeal from './TagDeal';
import PeriodText from './PeriodText';
import messages from '../plan/messages';

const SubscribeLabel = ({ pricing }) => {
  const intl = useIntl();
  const hasFreeTrialMarkdown = hasFreeTrial(pricing?.markdowns);
  const hasPercentageMarkdowns = hasPercentage(pricing?.markdowns);
  const hasConcatMarkdowns = pricing?.markdowns?.length > 1;
  const { country } = usePublicEnv();

  const [first] = pricing?.markdowns || [];
  const second = pricing?.markdowns?.length > 1 ? pricing?.markdowns[1] : [];
  const { discount_amount, discount_type, duration_unit, duration_value } = first || {};

  const subscriptionAmount =
    hasFreeTrialMarkdown && !hasPercentageMarkdowns
      ? pricing?.monthly_fee_after_discounts
      : pricing?.monthly_fee_after_discounts || pricing?.monthly_fee;

  const currencySymbol = getCurrencySymbol(country?.shortName)?.symbol;

  const price = formatCurrency(intl, {
    amount: subscriptionAmount,
    currencySymbol: currencySymbol,
  });

  const fullPrice = formatCurrency(intl, {
    amount: pricing?.monthly_fee,
    currencySymbol: currencySymbol,
  });

  const subtitleText = hasConcatMarkdowns ? (
    <FormattedMessage
      {...messages.priceAfterDiscount}
      values={{
        amount: price,
      }}
    />
  ) : (
    <FormattedMessage
      {...messages.promoTime}
      values={{
        value: duration_value,
        unit: getUnitIdFromValue(duration_unit),
      }}
    />
  );

  return (
    <Container largeSpacing={hasConcatMarkdowns}>
      {(hasFreeTrialMarkdown || hasPercentageMarkdowns) && !hasConcatMarkdowns && (
        <DiscountContainer>
          <Typography
            token="font-body-midcontrast-strikethrough-small"
            color={fenixTheme.color('text-color-secondary')}
          >
            {fullPrice}
          </Typography>
          <TagDeal
            discountAmount={hasFreeTrialMarkdown ? 100 : discount_amount}
            discountType={discount_type}
          />
        </DiscountContainer>
      )}
      {hasConcatMarkdowns ? (
        <ConcatContainer>
          <PeriodText period={first} />
          <PeriodText period={second} prefix={'+ '} />
        </ConcatContainer>
      ) : (
        <PriceContainer>
          <Typography token="font-headline-highcontrast-small">{price}</Typography>
          {!hasFreeTrialMarkdown && (
            <Typography token="font-body-highcontrast-sentence-small">/mes</Typography>
          )}
        </PriceContainer>
      )}
      {(hasFreeTrialMarkdown || hasPercentageMarkdowns) && (
        <Typography
          token="font-body-midcontrast-sentence-small"
          color={fenixTheme.color('text-color-secondary')}
        >
          {subtitleText}
        </Typography>
      )}
    </Container>
  );
};

SubscribeLabel.propTypes = {
  pricing: PropTypes.shape({
    markdowns: PropTypes.array,
    currencySymbol: PropTypes.string,
    monthly_fee: PropTypes.number,
    monthly_fee_after_discount: PropTypes.number,
  }),
};

export default SubscribeLabel;
