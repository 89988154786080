import React from 'react';
import { useAppVersion, useIsWeb, useIsWebview, useIsAndroid, useIsIos } from './hooks';

export const Android = ({ children }) => (useIsAndroid() ? children : null);

export const Ios = ({ children }) => (useIsIos() ? children : null);

export const Webview = ({ children }) => (useIsWebview() ? children : null);

export const Web = ({ children }) => (useIsWeb() ? children : null);

Webview.Android = ({ children }) => (
  <Webview>
    <Android>{children}</Android>
  </Webview>
);

Webview.Android.displayName = 'Webview.Android';

Webview.Ios = ({ children }) => (
  <Webview>
    <Ios>{children}</Ios>
  </Webview>
);
Webview.Ios.displayName = 'Webview.Ios';

Web.Android = ({ children }) => (
  <Web>
    <Android>{children}</Android>
  </Web>
);
Web.Android.displayName = 'Web.Android';

Web.Ios = ({ children }) => (
  <Web>
    <Ios>{children}</Ios>
  </Web>
);
Web.Ios.displayName = 'Web.Ios';

export const AppVersion = ({ min, max, exact, children, fallback = null }) => {
  const matchVersion = useAppVersion({ min, max, exact });

  return matchVersion ? children : fallback;
};
