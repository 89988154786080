import styled from 'styled-components';

export const ConditionContainer = styled.div`
  padding: ${({ theme }) => `0 ${theme.space('spacing-10')} ${theme.space('spacing-25')}`};

  ${({ theme }) => theme.font('text-base-large')};

  li {
    ${({ theme }) => theme.font('text-base-medium')};
    margin-bottom: ${({ theme }) => theme.space('spacing-07.5')};
  }
`;
