import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import Spinner from '../../components/Spinner';

export const getMessages = (locale) => import(`../../../build/translations/${locale}.json`);

export const createIntlProvider =
  (locale, getMessages) =>
  ({ initialMessages, children }) => {
    const [messages, setMessages] = useState(initialMessages);

    const setNoMessages = () => setMessages([]);

    useEffect(() => {
      if (messages) return;
      getMessages(locale).then(setMessages).catch(setNoMessages);
    }, [messages]);

    if (!messages) return <Spinner />;

    return (
      <IntlProvider messages={messages} locale={locale} defaultLocale="es">
        {children}
      </IntlProvider>
    );
  };
