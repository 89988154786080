import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import Image from '@pedidosya/web-fenix/foundations/Image';
import MailIcon from '@app/assets/mail.svg';
import SentIcon from '@app/assets/sent.svg';
import { sendBillMail } from '@app/services/loyalty/sendBillMail';
import Button from '@pedidosya/web-fenix/atoms/Button';
import logger from '@app/logger';
import { BillButtonWrapper } from './styled-components';

const SendMailButton = ({ billId, planId, amount, date, onError }) => {
  const { mutate, isSuccess, isLoading } = useMutation(sendBillMail);
  const handleClick = () =>
    mutate(
      { billId, planId, amount, date },
      {
        onError: (err) => {
          logger.error(err);
          onError();
        },
      },
    );

  const getButtonState = () => {
    if (isSuccess) return 'disabled';
    if (isLoading) return 'loading';

    return 'enabled';
  };

  return (
    <BillButtonWrapper isSuccess={isSuccess}>
      <Button
        onClick={handleClick}
        icon={
          isSuccess ? (
            <Image src={SentIcon} alt="Enviada" height={16} width={16} />
          ) : (
            <Image src={MailIcon} alt="Enviar" height={10} width={16} />
          )
        }
        label={isSuccess ? 'Enviada' : 'Enviar'}
        size="medium"
        anatomy="right-icon"
        hierarchy="tertiary"
        state={getButtonState()}
      />
    </BillButtonWrapper>
  );
};

SendMailButton.propTypes = {
  billId: PropTypes.string,
  planId: PropTypes.number,
  amount: PropTypes.number,
  date: PropTypes.string,
  onError: PropTypes.func,
};

export default SendMailButton;
