import React from 'react';
import PropTypes from 'prop-types';
import Button from '@pedidosya/web-fenix/atoms/Button';
import FixedButtonsFooter from '@pedidosya/web-fenix/organisms/FixedButtonsFooter';
import { FooterWrapper } from './styled-components';

export const Footer = ({ onClose, onCancel }) => {
  return (
    <FooterWrapper withShadow={false}>
      <FixedButtonsFooter.Primary>
        <Button
          label="Seguir con Plus"
          size="large"
          fullWidth
          hierarchy="primary"
          onClick={onClose}
        />
      </FixedButtonsFooter.Primary>
      <FixedButtonsFooter.Secondary>
        <Button
          label="Cancelar suscripción"
          size="large"
          fullWidth
          hierarchy="secondary"
          onClick={onCancel}
        />
      </FixedButtonsFooter.Secondary>
    </FooterWrapper>
  );
};

Footer.propTypes = {
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
};
