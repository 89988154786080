import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIsWebviewIos } from '@app/contexts/deviceInfo/hooks';
import Navbar from '@pedidosya/web-fenix/organisms/Navbar';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { NavigationHeader } from '@app/components/NavigationHeader';
import { HeaderIOS, NavbarContent, NavbarWrapper } from './styled-components';
import fenixTheme from '@pedidosya/web-fenix/theme';
import messages from '../plan/messages';

const ANIMATION_DURATION = 300;

export const FixedHeader = ({ showHeader }) => {
  const isWebviewIos = useIsWebviewIos();
  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    if (showHeader) setShowNavbar(true);
    else
      setTimeout(function () {
        setShowNavbar(false);
      }, ANIMATION_DURATION);
  }, [showHeader]);

  return (
    <>
      {showNavbar && (
        <NavbarWrapper hideHeader={!showHeader} duration={ANIMATION_DURATION}>
          <Navbar>
            <HeaderIOS isWebviewIos={isWebviewIos} />
            <NavbarContent>
              <NavigationHeader
                isHistoryRoot
                fenix
                color={fenixTheme.color('icon-color-primary')}
              />
              <Typography token="font-body-midcontrast-sentence-large">
                <FormattedMessage {...messages.headerStickyTitle} />
              </Typography>
            </NavbarContent>
          </Navbar>
        </NavbarWrapper>
      )}
    </>
  );
};
