import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import FenixArrowLeft from '@pedidosya/web-fenix/icons/ChevronLeft';
import { HOME_LINKS } from '@commons/utils/deeplinks';
import { PRESENTATION_QUERY } from '@commons/models/presentationQueryEnum';
import navigation from '@pedidosya/web-native-bridges/navigation';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { IOS_VERSION } from '@app/utils/constants/backNavigationVersion';
import { useAppVersion } from '@app/contexts/deviceInfo/hooks';
import { Button } from './styled-components';

const IosBackNavigation = ({
  color,
  onNavigationClick = Function.prototype,
  isHistoryRoot = false,
}) => {
  const history = useHistory();
  const { presentation = PRESENTATION_QUERY.ROOT } = usePublicEnv();
  const isBackNavigationVersion = useAppVersion({ min: IOS_VERSION });
  const withNativeBackNavigation =
    isBackNavigationVersion && presentation !== PRESENTATION_QUERY.ROOT;
  const canNavigateBack = !isHistoryRoot && history && history.length !== 1;

  const onBackNavigation = () => {
    if (!canNavigateBack) {
      withNativeBackNavigation ? navigation.goBack() : window.location.assign(HOME_LINKS.IOS);
    } else {
      history.goBack();
    }

    onNavigationClick();
  };

  return (
    <Button role="button" onClick={onBackNavigation} title="iosGoBack">
      <FenixArrowLeft size="medium" color={color} />
    </Button>
  );
};

IosBackNavigation.propTypes = {
  color: PropTypes.string,
  fenix: PropTypes.bool,
  onNavigationClick: PropTypes.func,
  isHistoryRoot: PropTypes.bool,
};

export default IosBackNavigation;
