/* eslint-disable no-console */
import { STG_ENVIRONMENT, PROD_ENVIRONMENT } from '@appscore/web-constants/environments';
import isObject from '@commons/utils/object/isObject';
import * as Sentry from './sentry';

const buffer = [];

const addToBufferWithNoInitWarning =
  (level) =>
  (...args) => {
    buffer.push([level, ...args]);
  };

const logger = {
  debug: addToBufferWithNoInitWarning('debug'),
  info: addToBufferWithNoInitWarning('info'),
  warn: addToBufferWithNoInitWarning('warn'),
  error: addToBufferWithNoInitWarning('error'),
};

const initSentry = ({ deviceInfo, user, environment, country, city }) => {
  Sentry.init({ environment });
  Sentry.setUser(user);

  const tags = [
    ['platform', deviceInfo.isWebview ? 'webview' : 'web'],
    ['country', country?.shortName],
    ['city', city?.slug],
    ['os', deviceInfo?.os],
    ['appVersion', deviceInfo?.appVersion],
  ];

  tags.forEach(([key, value]) => Sentry.setTag(key, value));
};

const logWithJoinedMessageAndContext = (level, ...args) => {
  const lastArg = args[args.length - 1];
  let context;

  if (isObject(lastArg)) {
    context = args.pop();
  }

  let message = args.join(' ');

  if (context instanceof Error) {
    if (message.length === 0) message = context.message;
    context = {
      errorMessage: context?.message,
      errorStack: context?.stack,
    };
  }

  Sentry.log(level, message, context);
};

const freezeLogger = () => {
  // Clear the init function, forbidding init to be called twice
  logger.init = null;

  Object.freeze(logger);

  return logger;
};

const initLogger = ({ deviceInfo, user, environment, country, city }) => {
  if ([STG_ENVIRONMENT, PROD_ENVIRONMENT].includes(environment)) {
    initSentry({ deviceInfo, user, environment, country, city });

    logger.debug = (...args) => logWithJoinedMessageAndContext('debug', ...args);
    logger.info = (...args) => logWithJoinedMessageAndContext('info', ...args);
    logger.warn = (...args) => logWithJoinedMessageAndContext('warn', ...args);
    logger.error = (...args) => logWithJoinedMessageAndContext('error', ...args);
  } else {
    logger.debug = (...args) => console.debug(...args);
    logger.info = (...args) => console.info(...args);
    logger.warn = (...args) => console.warn(...args);
    logger.error = (...args) => console.error(...args);
  }

  buffer.forEach(([level, ...args]) => {
    logger[level](...args);
  });

  return freezeLogger();
};

logger.init = initLogger;

export default logger;
