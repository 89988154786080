import React from 'react';
import PropTypes from 'prop-types';
import Check from '@pedidosya/web-fenix/icons/Check';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { List, ListItem } from './styled-components';

export const Benefits = ({ benefits }) => {
  return (
    <List aria-label="conditions">
      {benefits.map((benefit, idx) => (
        <ListItem key={idx}>
          <Check color="#15bf79" size="small" />
          <Typography
            token="text-base-large"
            color="shape-color-surface-secondary"
            dangerouslySetInnerHTML={{ __html: benefit.description }}
          />
        </ListItem>
      ))}
    </List>
  );
};

Benefits.propTypes = {
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};
