import React from 'react';
import PropTypes from 'prop-types';
import ThumbnailImage from '@pedidosya/web-fenix/atoms/ThumbnailImage';
import ThumbnailLabel from '@pedidosya/web-fenix/atoms/ThumbnailLabel';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { RowList, Title, ThumbnailStackedWrapper } from './styled-components';

const DISPLACEMENT = 12;
const MAX_VENDORS = 3;

export const Orders = ({ orders, isLowText }) => {
  const extraVendors = orders.vendors?.length - MAX_VENDORS ?? 0;
  const vendors = extraVendors > 0 ? orders.vendors?.slice(0, MAX_VENDORS - 1) : orders.vendors;
  const displace = extraVendors > 0 ? vendors?.length : vendors?.length - 1;

  return (
    <RowList>
      <Title>
        <Typography
          token={isLowText ? 'text-base-medium' : 'text-title-small'}
          color={isLowText ? 'text-color-secondary' : 'text-color-primary'}
        >
          {orders.title}
        </Typography>
      </Title>
      <ThumbnailStackedWrapper right={DISPLACEMENT * displace} role="vendors-stacked">
        {vendors &&
          vendors.map((vendor, _) => {
            return (
              <ThumbnailImage
                shape="square"
                size="XS"
                src={vendor.img}
                stroke
                aria-label="vendors"
                key={vendor.vendor_id}
              />
            );
          })}
        {extraVendors > 0 && (
          <ThumbnailLabel size="XS" stroke style="neutral">
            + {extraVendors + 1}
          </ThumbnailLabel>
        )}
      </ThumbnailStackedWrapper>
    </RowList>
  );
};

Orders.propTypes = {
  orders: PropTypes.shape({
    title: PropTypes.string,
    vendors: PropTypes.arrayOf(
      PropTypes.shape({
        img: PropTypes.string,
      }),
    ),
  }),
  isLowText: PropTypes.bool,
};
