import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, StaticRouter } from 'react-router-dom';

const AppRouter = ({ url, history, children }) => {
  if (typeof window === 'undefined')
    return (
      <StaticRouter location={url} context={{}}>
        {children}
      </StaticRouter>
    );

  return <BrowserRouter history={history}>{children}</BrowserRouter>;
};

AppRouter.propTypes = {
  url: PropTypes.string,
  history: PropTypes.shape({}),
  children: PropTypes.node,
};

export default AppRouter;
