import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { DISCOUNT_TYPE } from '@commons/models/discountTypeEnum';
import ThumbnailImage from '@pedidosya/web-fenix/atoms/ThumbnailImage';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { VendorWrapper, TagWrapper, ThumbnailImageContent } from './styled-components';
import messages from '../plan/messages';

export const VendorsSwiperList = ({ vendorList }) => {
  const getDiscountText = (amount, discountType) => {
    switch (discountType) {
      case DISCOUNT_TYPE.PERCENTAGE:
        return `${amount}%`;
      default:
        amount;
    }
  };
  return (
    <>
      {vendorList.map((vendor, idx) => {
        const amount = vendor.discount.value;
        const discountAmountText = getDiscountText(amount, vendor.discount.type);
        return (
          <VendorWrapper key={idx} aria-label="vendors">
            <ThumbnailImageContent>
              <ThumbnailImage src={vendor.icon} stroke shape="circle" size="XL" />
            </ThumbnailImageContent>
            <TagWrapper
              category={amount === 100 ? 'deals-and-discounts' : 'quiet'}
              label={
                <Typography token="text-highlight-small">
                  <FormattedMessage
                    {...messages.discountTitle}
                    values={{
                      discountAmountText,
                      discountAmount: amount,
                    }}
                  />
                </Typography>
              }
            />
          </VendorWrapper>
        );
      })}
    </>
  );
};

VendorsSwiperList.propTypes = {
  vendorList: PropTypes.arrayOf(
    PropTypes.shape({
      discount: PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.number,
      }),
      icon: PropTypes.string,
    }),
  ),
};
