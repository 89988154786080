import React from 'react';
import PropTypes from 'prop-types';
import { useIsWebviewIos } from '@app/contexts/deviceInfo/hooks';
import SimpleError from '@pedidosya/web-fenix/pages/Error';
import Navbar from '@pedidosya/web-fenix/organisms/Navbar';
import ChevronLeft from '@pedidosya/web-fenix/icons/ChevronLeft';
import Close from '@pedidosya/web-fenix/icons/Close';
import { HeaderIOS, NavbarWrapper, NavbarContent, NavbarIcon } from './styled-components';

const Error = ({
  primaryActionLabel,
  primaryActionClick,
  message,
  secondaryActionLabel,
  secondaryActionClick,
  errorCode,
  onClose,
  onGoBack,
}) => {
  const isWebviewIos = useIsWebviewIos();

  return (
    <>
      <HeaderIOS isWebviewIos={isWebviewIos} />
      <NavbarWrapper>
        <Navbar transparent>
          <NavbarContent>
            <NavbarIcon>
              {onClose && (
                <Close
                  size="medium"
                  onClick={onClose}
                  color="icon-color-primary"
                  title="close-icon"
                />
              )}
              {onGoBack && !onClose && (
                <ChevronLeft
                  size="medium"
                  onClick={onGoBack}
                  color="icon-color-primary"
                  title="chevronleft-icon"
                />
              )}
            </NavbarIcon>
          </NavbarContent>
        </Navbar>
      </NavbarWrapper>
      <SimpleError
        errorCode={errorCode ?? 500}
        primaryActionClick={primaryActionClick}
        primaryActionLabel={primaryActionLabel}
        message={message}
        secondaryActionClick={secondaryActionClick}
        secondaryActionLabel={secondaryActionLabel}
      />
    </>
  );
};

Error.propTypes = {
  primaryActionLabel: PropTypes.string,
  primaryActionClick: PropTypes.func,
  message: PropTypes.string,
  secondaryActionLabel: PropTypes.string,
  secondaryActionClick: PropTypes.func,
  errorCode: PropTypes.number,
  onClose: PropTypes.func,
  onGoBack: PropTypes.func,
};

export default Error;
