import axios from 'axios';
import logger from '@app/logger';

export const fetchCancellation = async () => {
  try {
    const cancellationResponse = await axios.get('/loyalty/api/cancellation');
    return cancellationResponse.data;
  } catch (error) {
    logger.error('Error getting cancellation data');
    throw error;
  }
};
