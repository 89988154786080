import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { ConditionsContainer, ConditionList } from './styled-components';

export const BenefitConditions = ({ content, bullets }) => {
  return (
    <ConditionsContainer>
      {content}
      <ConditionList aria-label="conditions-bullet">
        {bullets.map((bullet, index) => (
          <li key={index}>
            <Typography
              token="text-base-medium"
              color="text-color-primary"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(bullet, { RETURN_DOM: true }).outerHTML,
              }}
            />
          </li>
        ))}
      </ConditionList>
    </ConditionsContainer>
  );
};

BenefitConditions.propTypes = {
  content: PropTypes.node,
  bullets: PropTypes.arrayOf(PropTypes.string),
};
