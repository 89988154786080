import startOfDay from './startOfDay';

const differenceInCalendarDays = (leftDate, rightDate) => {
  const timestampLeft = startOfDay(leftDate).getTime();
  const timestampRight = startOfDay(rightDate).getTime();

  return Math.round((timestampLeft - timestampRight) / (24 * 3600 * 1000));
};

export default differenceInCalendarDays;
