import React from 'react';
import PropTypes from 'prop-types';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import { StyledSpinner } from './styled-components';

const Spinner = ({ fullScreen }) => {
  return (
    <StyledSpinner fullScreen={fullScreen} role="progressbar" aria-busy="true">
      <PeyaLoader />
    </StyledSpinner>
  );
};

Spinner.propTypes = {
  fullScreen: PropTypes.bool,
};

Spinner.defaultProps = {
  fullScreen: true,
};

export default Spinner;
