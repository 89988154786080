import styled from 'styled-components';

export const ConditionsContainer = styled.div`
  padding: ${({ theme }) =>
    `${theme.space('spacing-component-large')} ${theme.space(
      'spacing-component-xlarge',
    )} ${theme.space('spacing-component-4xlarge')}`};
`;

export const ConditionList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) =>
    `${theme.space('spacing-component-xlarge')} ${theme.space('spacing-component-xlarge')} 0`};
  gap: ${({ theme }) => theme.space('spacing-component-large')};
`;
