import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color('shape-color-background-primary')};
  padding: ${({ theme }) =>
    `${theme.space('spacing-component-large')} ${theme.space(
      'spacing-component-xlarge',
    )} ${theme.space('spacing-component-xlarge')}`};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space('spacing-component-xlarge')};
`;

export const PlanPriceContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${({ theme }) => theme.space('spacing-10')} 0;
`;

export const PriceContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const CardContent = styled.div`
  padding: 0 ${({ theme }) => theme.space('spacing-10')};
`;

export const PaymentMethodGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  column-gap: ${({ theme }) => theme.space('spacing-4')};
  row-gap: ${({ theme }) => theme.space('spacing-1')};
  align-items: center;
  padding: ${({ theme }) => theme.space('spacing-10')} 0;
`;

export const Row = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space('spacing-4')};
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${({ theme }) => theme.space('spacing-3')};
`;

export const ButtonContainer = styled.div`
  padding-top: ${({ theme }) => theme.space('spacing-8')};
`;
