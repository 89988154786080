import { useEffect } from 'react';
import { useIsWebviewAndroid, useIsWebviewIos } from '@app/contexts/deviceInfo/hooks';

// on loading el theme es light
const payload = {
  backgroundColor: '#00FFFFFF',
  theme: 'dark',
  showActionButton: false,
  showHamburgerButton: false,
};

const iOSMessage = {
  command: 'webview-event',
  event: 'updateHeader',
};

const publishiOS = (event) =>
  window.webkit?.messageHandlers?.ListenEventsInterface?.postMessage({
    ...iOSMessage,
    payload: { ...event },
  });

const publishAndroid = (event) =>
  window.NavigationHeaderInterface?.updateHeader(JSON.stringify(event));

export const publishEventsOnDevice = (event) => {
  publishAndroid({ ...payload, ...event });
  publishiOS({ ...payload, ...event });
};

export const useHeaderConfiguration = ({ isError, theme = 'dark' } = {}) => {
  const android = useIsWebviewAndroid();
  const ios = useIsWebviewIos();

  useEffect(() => {
    if (ios || android) {
      if (isError) {
        publishEventsOnDevice({
          ...payload,
          theme: 'light',
        });
      }

      // Both hamburguer and action button should never be shown if the navigation header is web only
      publishEventsOnDevice({
        ...payload,
        theme,
      });

      return () => {
        publishEventsOnDevice({ ...payload });
      };
    }

    return undefined;
  }, [ios, android, isError, theme]);
};
