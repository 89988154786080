import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider, Hydrate } from '@tanstack/react-query';
import { theme } from '@app/theme';
import GlobalStyles from '@pedidosya/web-fenix/theme/GlobalStyles';
import logger from '@app/logger';

import FenixFonts from '@pedidosya/web-fenix/theme/FenixFonts';
import { LoyaltyGlobalStyles } from '@app/loyaltyGlobalStyles';
import { createIntlProvider, getMessages } from '@app/contexts/IntlProvider';
import { DeviceInfoProvider } from '@app/contexts/deviceInfo';
import { TrackerProvider } from '@app/contexts/tracker';
import { FwfFlagsProvider } from '@app/contexts/FwfFlags';
import AppRouter from '@app/AppRouter';
import Router from '@app/MainRouter';
import { getHistory } from '@app/utils/history';
import { usePublicEnv, PublicEnvProvider, publicEnvShape } from '@app/contexts/PublicEnv';

const getServerValue = (key, elementId) => {
  if (typeof window !== 'undefined') {
    const value = window[key];
    delete window[key];

    const element = document.getElementById(elementId);
    if (element) element.remove();

    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  }

  return null;
};

const defaultQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 3,
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const defaultFwfFlags = getServerValue('__FWF_FLAGS__', 'reactQueryState');

/**
 * Instanciate providers
 */
const IntlProvider = (props) => {
  const { locale } = usePublicEnv();
  return createIntlProvider(locale, getMessages)(props);
};

const DeviceInfoProv = ({ children }) => {
  const { deviceInfo } = usePublicEnv();
  return <DeviceInfoProvider value={deviceInfo}>{children}</DeviceInfoProvider>;
};

const LoggerProvider = ({ children }) => {
  const { deviceInfo, userId, environment, country, city } = usePublicEnv();

  useEffect(() => {
    try {
      logger.init({ deviceInfo, environment, country, city, user: { id: userId } });
    } catch (error) {
      console.error('Error initializing logger', error.message);
    }
  });

  return children;
};

/**
 * Render application root
 */
const Root = ({
  context,
  messages,
  url,
  queryClient = defaultQueryClient,
  tracker,
  fwfFlags = defaultFwfFlags,
  fwfPromise,
}) => {
  const dehydratedState = getServerValue('__REACT_QUERY_STATE__', 'reactQueryState');

  return (
    <PublicEnvProvider context={context}>
      <DeviceInfoProv>
        <LoggerProvider>
          <TrackerProvider tracker={tracker}>
            <QueryClientProvider client={queryClient}>
              <Hydrate state={dehydratedState}>
                <ThemeProvider theme={theme}>
                  <FwfFlagsProvider fwfPromise={fwfPromise} flagValues={fwfFlags}>
                    <AppRouter url={url} history={getHistory()}>
                      <GlobalStyles />
                      <LoyaltyGlobalStyles />
                      <FenixFonts />
                      <IntlProvider initialMessages={messages}>
                        <Router />
                      </IntlProvider>
                    </AppRouter>
                  </FwfFlagsProvider>
                </ThemeProvider>
              </Hydrate>
            </QueryClientProvider>
          </TrackerProvider>
        </LoggerProvider>
      </DeviceInfoProv>
    </PublicEnvProvider>
  );
};

const ssrProps = getServerValue('__INITIAL_DATA__', 'initialData');

Root.defaultProps = { ...ssrProps };

Root.propTypes = {
  context: PropTypes.shape(publicEnvShape),
  messages: PropTypes.shape({}),
  url: PropTypes.string,
  tracker: PropTypes.shape({}),
  fwfFlags: PropTypes.object,
  fwfPromise: PropTypes.instanceOf(Promise),
  origin: PropTypes.string,
};

export default Root;
