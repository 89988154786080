import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from '@pedidosya/web-fenix/theme/GlobalStyles';
import fenixTheme from '@pedidosya/web-fenix/theme';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { fetchRedirectTyc } from '../../services/loyalty/fetchRedirectTyc';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { TcWrapper } from './styled-components';

export const RedirectPage = () => {
  const tycQuery = useQuery(queryKeyBuilders.redirectTyc(), () => fetchRedirectTyc());
  const { data } = tycQuery;

  const sanitizedData = useMemo(
    () => DOMPurify.sanitize(data, { RETURN_DOM: true }).innerHTML,
    [data],
  );

  return (
    <ThemeProvider theme={fenixTheme}>
      <>
        <GlobalStyles />
        {data && (
          <Typography token="text-base-medium">
            <TcWrapper dangerouslySetInnerHTML={{ __html: sanitizedData }} />
          </Typography>
        )}
      </>
    </ThemeProvider>
  );
};
