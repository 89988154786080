/* eslint-disable no-console */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import logger from '@app/logger';

import { TRACKING_ORIGINS } from './constants';
import { useDeviceInfo } from '../deviceInfo';
import { usePublicEnv } from '../PublicEnv';

const TrackerContext = React.createContext({});

/**
 * Helpers from https://github.com/pedidosya/web-commons/blob/staging/modules/tracker/v1
 */

const isAndroidTrack = () => {
  return !!(window?.AnalyticsWebInterface || window?.CourierWebFormInterface);
};

const isIosTrack = () => {
  return !!window?.webkit?.messageHandlers?.AnalyticsWebInterface;
};

const checkPlatformMatch = (device) => {
  if (device.isWebview()) {
    if (device.isAndroid() && !isAndroidTrack())
      throw new Error('[TRACKING_PLATFORM_MISMATCH] Expected Android');
    if (device.isIos() && !isIosTrack())
      throw new Error('[TRACKING_PLATFORM_MISMATCH] Expected iOS');
  } else if (isIosTrack() || isAndroidTrack()) {
    throw new Error('[TRACKING_PLATFORM_MISMATCH] Expected web');
  }
};

export const useTracker = () => React.useContext(TrackerContext);

export const noop = { track: () => logger.error('[NO_TRACKER_IMPLEMENTATION]') };

export const TrackerProvider = ({ tracker = noop, children }) => {
  const { origin } = usePublicEnv();
  const value = useMemo(
    () => ({ origin: origin || TRACKING_ORIGINS.DEEPLINK, tracker }),
    [tracker, origin],
  );
  const device = useDeviceInfo();

  useEffect(() => {
    try {
      checkPlatformMatch(device);
    } catch (error) {
      logger.error(error.message);
    }
  }, [device]);

  if (!tracker) return null;

  return <TrackerContext.Provider value={value}>{children}</TrackerContext.Provider>;
};

TrackerProvider.propTypes = {
  tracker: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};
