import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  background: transparent;
`;

export const Button = styled.div`
  padding-left: ${({ theme }) => theme.space('spacing-08')};
  max-width: fit-content;
`;
