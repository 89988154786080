import styled from 'styled-components';
import CardContainer from '@pedidosya/web-fenix/molecules/CardContainer';

export const RealStateContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: ${({ theme }) => `0 ${theme.space('spacing-08')} ${theme.space('spacing-09')}`};
  gap: 12px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const CardContainerWrapper = styled(CardContainer)`
  min-width: 90vw;
`;

export const VendorItemContainer = styled.div`
  div[role='region'] {
    padding: 12px;
  }
  h1 {
    margin: 0;
  }

  hr {
    height: 0;
  }

  span.ellipsis {
    margin-bottom: 10px;
  }
`;
