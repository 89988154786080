import axios from 'axios';
import logger from '@app/logger';

export const fetchStatus = async () => {
  try {
    const statusResponse = await axios.get('/loyalty/api/status');
    return statusResponse.data;
  } catch (error) {
    logger.error('Error getting status data');
    throw error;
  }
};
