import { createGlobalStyle } from 'styled-components';

export const LoyaltyGlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box
  }

  .row {
    display: flex;
    flex-direction: row
  }

  svg:focus {
    outline: none;
  }

  html,body{
    background-color: white;
  }
`;
