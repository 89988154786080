import axios from 'axios';
import logger from '@app/logger';

export const reactivateSubscription = async () => {
  try {
    await axios.post(`/loyalty/api/subscriptions/reactivateSubscription`);
  } catch (error) {
    logger.error('Error reactivating subscription');
    throw error;
  }
};
