import axios from 'axios';
import logger from '@app/logger';

export const createSubscriptionIntent = async ({ planId }) => {
  try {
    const subscriptionIntent = await axios.post(`/loyalty/api/subscription/intent`, {
      plan_id: planId,
    });
    return subscriptionIntent.data;
  } catch (error) {
    logger.error(`Error creating subscription intent for plan ${planId}`);
    throw error;
  }
};
