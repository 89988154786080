const FAQ_TYPES = {
  CONDITIONS: 'conditions',
  MARKETS: 'markets',
  UNSUBSCRIBE: 'unsubscribe',
  RESUBSCRIBE: 'resubscribe',
  CITIES: 'cities',
  FREE_TRIAL: 'freeTrial',
};

module.exports = { FAQ_TYPES };
