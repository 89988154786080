import React from 'react';
import Image from '@pedidosya/web-fenix/foundations/Image';
import { replaceString } from '@app/utils/jsx/replaceString';
import VisaIcon from '@app/assets/Visa.svg';

// TODO: Is this still needed? VISA is now shown as a benefit anymore
export const decorateTitle = (title) =>
  replaceString(title, {
    VISA: (
      <Image
        src={VisaIcon}
        alt="VISA"
        width={40}
        height={13}
        style={{ verticalAlign: 'baseline', display: 'inline' }}
      />
    ),
  });
