import styled from 'styled-components';
import ThumbnailStacked from '@pedidosya/web-fenix/molecules/ThumbnailStacked';
import TopAppBar from '@pedidosya/web-fenix/organisms/TopAppBar';
import FixedButtonsFooter from '@pedidosya/web-fenix/organisms/FixedButtonsFooter';

export const UnsubscribePage = styled.section`
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
`;

const Header = styled.div`
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-color: #2e0328;
  width: 100%;
`;

export const HeaderWrapper = styled(Header)`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: ${({ theme }) =>
    `${theme.space('spacing-28')} ${theme.space('layout-margin-mobile')} 0`};

  height: ${({ theme }) => theme.size('size-52')};
  min-height: ${({ theme }) => theme.size('size-52')};

  ${({ isWebviewIos, theme }) =>
    isWebviewIos &&
    `
    @media ${theme.mediaQueries.mobileIos} {
      height:  ${theme.size('size-57')};
      min-height: ${theme.size('size-57')};
      padding-top:  ${theme.space('spacing-38')};
    }

    @media ${theme.mediaQueries.mobileIosNotch} {
      height: ${theme.size('size-62')};
      min-height: ${theme.size('size-62')};
      padding-top: ${theme.space('spacing-48')};
    }
  `}
`;

export const NavbarWrapper = styled(Header)`
  position: fixed;
  z-index: 1;
  display: flex;
  align-items: end;

  ${({ isWebviewIos, theme }) =>
    isWebviewIos &&
    `
      @media ${theme.mediaQueries.mobileIos} {
      padding-top: ${theme.space('spacing-10')};
    }

    @media ${theme.mediaQueries.mobileIosNotch} {
      padding-top: ${theme.space('spacing-20')};
    }
  `}
`;

export const TopAppBarWrapper = styled(TopAppBar)`
  margin-top: 12px;
`;

export const Content = styled.div`
  display: flex;
  padding: ${({ theme }) =>
    `${theme.space('spacing-component-medium')} 0 ${theme.space('spacing-layout-2xlarge')}`};
  width: 100%;
  height: 100%;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: ${({ theme }) => theme.space('spacing-component-medium')};
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.space('spacing-06')};
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `0 ${theme.space('layout-margin-mobile')}`};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const Container = styled.div`
  position: relative;
  top: -24px;
  padding: ${({ theme }) => `0 ${theme.space('layout-margin-mobile')} `};
`;

export const ContentCard = styled.div`
  padding: ${({ theme }) => `${theme.space('spacing-04')} 0`};
`;

export const VendorSwipperContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: ${({ theme }) =>
    `${theme.space('spacing-component-medium')} ${theme.space('layout-margin-mobile')}`};
  gap: 8px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const RowList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.space('spacing-component-medium')} 0`};
`;

export const DividerContainer = styled.div`
  padding: ${({ theme }) => `${theme.space('spacing-component-small')} 0`};
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const TotalContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0 0;
`;

export const ThumbnailStackedWrapper = styled(ThumbnailStacked)`
  position: relative;
  right: ${({ right }) => `-${right}px`};
`;

export const FooterWrapper = styled(FixedButtonsFooter)`
  height: auto;
  padding-bottom: ${({ theme }) => theme.space('spacing-component-4xlarge')};
`;

export const BenefitDividerContainer = styled.div`
  padding: ${({ theme }) => `${theme.space('spacing-component-medium')} 0`};
`;

export const BenefitsList = styled.div`
  padding-top: ${({ theme }) => theme.space('spacing-component-medium')};
`;
