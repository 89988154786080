import axios from 'axios';
import logger from '@app/logger';

export const fetchPromotionTC = async (tcUrl) => {
  try {
    const tcResponse = await axios.get(`/loyalty/api/promotion/tc`, {
      params: { tcUrl },
    });

    return tcResponse.data;
  } catch (error) {
    logger.error(`Error getting T&C for ${tcUrl}`);
    throw error;
  }
};
