import styled from 'styled-components';
import TopAppBar from '@pedidosya/web-fenix/organisms/TopAppBar';

const Header = styled.div`
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-color: #2e0328;
  width: 100%;

  ${({ isWebviewIos, theme }) =>
    isWebviewIos &&
    `
      @media ${theme.mediaQueries.mobileIos} {
      padding-top: ${theme.space('spacing-10')};
    }

    @media ${theme.mediaQueries.mobileIosNotch} {
      padding-top: ${theme.space('spacing-20')};
    }
  `}
`;

export const HeaderWrapper = styled(Header)`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: ${({ theme }) => `0 ${theme.space('layout-margin-mobile')} 0`};

  height: ${({ theme, isActive }) => (isActive ? theme.size('size-59') : theme.size('size-66'))};
  min-height: ${({ theme, isActive }) =>
    isActive ? theme.size('size-59') : theme.size('size-66')};

  ${({ isWebviewIos, isActive, theme }) =>
    isWebviewIos &&
    `
      @media ${theme.mediaQueries.mobileIos} {
        height: ${isActive ? theme.size('size-64') : theme.size('size-71')};
        min-height: ${isActive ? theme.size('size-64') : theme.size('size-71')};
      }

      @media ${theme.mediaQueries.mobileIosNotch} {
        height: ${isActive ? theme.size('size-69') : theme.size('size-76')};
        min-height: ${isActive ? theme.size('size-69') : theme.size('size-76')};
      }
  `}
`;

export const NavbarWrapper = styled(Header)`
  position: fixed;
  z-index: 1;
  display: flex;
  align-items: end;
`;

export const Content = styled.div`
  width: 100%;
  padding-bottom: ${({ theme }) => theme.space('spacing-component-3xlarge')};
`;

export const TopAppBarWrapper = styled(TopAppBar)`
  margin-top: 12px;
`;
