import styled from 'styled-components';

export const TotalSavingContainer = styled.div`
  display: flex;
  margin: ${({ theme }) => `${theme.space('spacing-component-xlarge')} 0`};
  padding: ${({ theme }) => `${theme.space('spacing-component-large')} 0`};

  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.space('spacing-component-large')};
`;

export const OrderTitle = styled.div`
  display: flex;
  flex-direction: column;
`;
