import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { DiscountContainer } from './styled-components';
import { getUnitIdFromValue } from '@commons/models/durationUnitEnum';
import messages from '../plan/messages';
import TagDeal from './TagDeal';

const PeriodText = ({ period, prefix }) => {
  const { duration_value, duration_unit, discount_amount, discount_type } = period || {};
  return (
    <DiscountContainer>
      <Typography token="font-label-highercontrast-medium">
        {prefix}
        <FormattedMessage
          {...messages.durationText}
          values={{
            value: duration_value,
            unit: getUnitIdFromValue(duration_unit),
          }}
        />
      </Typography>
      <TagDeal discountAmount={discount_amount} discountType={discount_type} />
    </DiscountContainer>
  );
};

PeriodText.propTypes = {
  period: PropTypes.shape({
    duration_value: PropTypes.number,
    duration_unit: PropTypes.string,
    discount_amount: PropTypes.number,
    discount_type: PropTypes.string,
  }),
  prefix: PropTypes.string,
};

export default PeriodText;
