import styled from 'styled-components';

export const BenefitTitle = styled.div`
  padding: ${({ theme }) => `${theme.space('spacing-05')} 0`};
`;

export const ConditionsWrapper = styled.div`
  padding: ${({ theme }) => `${theme.space('spacing-02.5')} 0`};
`;

export const DescriptionContainer = styled.div`
  > *:first-child {
    margin-top: 0;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${({ theme }) => theme.space('spacing-component-small')};
  padding-bottom: ${({ theme }) => theme.space('spacing-component-medium')};
`;
