import styled from 'styled-components';
import { NewsMessage } from '@app/components/NewsMessage';
import CardContainer from '@pedidosya/web-fenix/molecules/CardContainer';

export const Container = styled.section`
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: ${(props) => props.marginTop + 'px'};
  background-color: ${({ theme }) => theme.color('shape-color-background-primary')};
`;

export const Content = styled.div`
  padding: ${({ theme }) =>
    `0 ${theme.space('layout-margin-mobile')} ${theme.space('spacing-17')}`};
`;

export const SavingCardContainer = styled.div`
  padding: ${({ theme }) => `0 ${theme.space('spacing-08')}`};
`;

export const NewsMessageWrapper = styled(NewsMessage)`
  margin: ${({ theme }) => `${theme.space('spacing-layout-large')} 0 0`};
`;

export const InitialSectionTitle = styled.div`
  padding: ${({ theme }) =>
    `${theme.space('spacing-layout-large')} 0 ${theme.space('spacing-component-xlarge')}`};
`;

export const InitialSectionSubtitle = styled.div`
  margin: ${({ theme }) =>
    `${theme.space('spacing-component-3xlarge')} 0 ${theme.space('spacing-component-medium')}`};
`;

export const CardContainerWrapper = styled(CardContainer)`
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: no-repeat;
  padding: ${({ theme }) => `0 ${theme.space('spacing-component-2xlarge')}`};
`;
