import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import Spinner from '@app/components/Spinner';
import { BrandError } from '@app/components/Error';
import { Header } from './Header';
import { HeaderContent } from './HeaderContent';
import { Footer } from './Footer';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { fetchCancellation } from '@app/services/loyalty/fetchCancellation';
import messages from '../../messages';
import { DetailCard } from './DetailCard';
import { UnsubscribePage } from './styled-components';
import { getHomeLink } from '@commons/utils/deeplinks';
import { usePublicEnv } from '@app/contexts/PublicEnv';

export const Unsubscribe = ({ onGoBack, onContinue }) => {
  const cancellationQuery = useQuery(queryKeyBuilders.cancellation(), fetchCancellation);
  const isLoading = cancellationQuery.isLoading;
  const isError = cancellationQuery.isError;
  const { deviceInfo } = usePublicEnv();

  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    return (
      <BrandError
        message={<FormattedMessage {...messages.brandError} />}
        primaryActionLabel="Intentar nuevamente"
        primaryActionClick={cancellationQuery.refetch}
        secondaryActionLabel="Ir al inicio"
        secondaryActionClick={() => {
          window.location = getHomeLink(deviceInfo);
        }}
      />
    );
  }

  return (
    <UnsubscribePage aria-label="unsubscribe">
      <div>
        <Header onGoBack={onGoBack}>
          <HeaderContent
            title={cancellationQuery.data.header.title}
            subtitle={cancellationQuery.data.header.subtitle}
          />
        </Header>
        <DetailCard
          orders={cancellationQuery.data.orders}
          savings={cancellationQuery.data.savings}
          monthlyFee={cancellationQuery.data.monthly_fee}
          benefitsSummary={cancellationQuery.data.benefits_summary}
          currencySymbol={cancellationQuery.data.currency_symbol}
        />
      </div>
      <Footer onClose={onGoBack} onCancel={onContinue} />
    </UnsubscribePage>
  );
};

Unsubscribe.propTypes = {
  onGoBack: PropTypes.func,
  onContinue: PropTypes.func,
};
