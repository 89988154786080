import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '@tanstack/react-query';
import { BrandError } from '@app/components/Error';

import Spinner from '@app/components/Spinner';
import { createCancelSubscriptionSurvey } from '@app/services/loyalty/createCancelSubscriptionSurvey';
import { Survey } from './Survey';
import messages from '../../messages';

const SURVEY_CONTAINER_ID = 'survey-container';

export const SurveyModal = ({ onUnsubscribe, onCloseSurvey, open }) => {
  const surveyMutation = useMutation(() => createCancelSubscriptionSurvey());

  useEffect(() => {
    if (open) surveyMutation.mutate();
  }, [open]);

  const errorComponent = (
    <BrandError
      message={<FormattedMessage {...messages.brandError} />}
      primaryActionLabel="Intentar nuevamente"
      primaryActionClick={surveyMutation.mutate}
      secondaryActionLabel="Volver"
      secondaryActionClick={onCloseSurvey}
      onGoBack={onCloseSurvey}
    />
  );

  return (
    <div aria-label="survey-comp">
      {surveyMutation.isLoading && <Spinner fullScreen />}
      {surveyMutation.isError && errorComponent}
      {surveyMutation.data && (
        <Survey
          key={surveyMutation.data.resourceId}
          id={SURVEY_CONTAINER_ID}
          survey={surveyMutation.data}
          onClose={onCloseSurvey}
          onCompleted={onUnsubscribe}
          loader={<Spinner fullScreen />}
          errorComponent={errorComponent}
        />
      )}
    </div>
  );
};

SurveyModal.propTypes = {
  onUnsubscribe: PropTypes.func.isRequired,
  onCloseSurvey: PropTypes.func,
  open: PropTypes.bool,
};
