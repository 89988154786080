import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import ChevronRight from '@pedidosya/web-fenix/icons/ChevronRight';
import FenixBottomSheet from '@pedidosya/web-fenix/organisms/BottomSheet';
import { BenefitDetailIcon } from '@app/components/BenefitDetailIcon';
import { BenefitConditions } from './BenefitConditions';
import { BenefitListItem, TextContainer, BenefitDetailIconContainer } from './styled-components';

export const Benefit = ({ description, title, type, conditions, onConditionsClick }) => {
  const [showCondition, setShowCondition] = useState(false);

  const openConditions = () => {
    if (conditions) {
      onConditionsClick();
      setShowCondition(true);
    }
  };

  return (
    <BenefitListItem onClick={openConditions}>
      <BenefitDetailIconContainer>
        <BenefitDetailIcon type={type} />
      </BenefitDetailIconContainer>
      <TextContainer>
        <Typography token="text-highlight-large" color="text-color-primary">
          {title}
        </Typography>
        <Typography token="text-base-medium" color="text-color-secondary">
          {description}
        </Typography>
      </TextContainer>
      {conditions && (
        <>
          <ChevronRight size="small" />
          <FenixBottomSheet
            handler={false}
            open={showCondition}
            onClickOut={() => {
              setShowCondition(!showCondition);
            }}
            onClose={() => {
              setShowCondition(!showCondition);
            }}
            title="Condiciones"
          >
            <BenefitConditions {...conditions} />
          </FenixBottomSheet>
        </>
      )}
    </BenefitListItem>
  );
};

Benefit.propTypes = {
  description: PropTypes.string,
  title: PropTypes.node,
  type: PropTypes.string,
  conditions: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string,
    bullets: PropTypes.arrayOf(PropTypes.string),
  }),
  key: PropTypes.string,
  onConditionsClick: PropTypes.func,
};
