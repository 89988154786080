import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { useDeviceInfo } from '@app/contexts/deviceInfo';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { fetchSubscription } from '@app/services/loyalty/fetchSubscription';
import Spinner from '@app/components/Spinner';
import { BrandError } from '@app/components/Error';
import { getHomeLink } from '@commons/utils/deeplinks';
import { initializeInstance as initializeFWF } from '@commons/fwf/instance';
import logger from '@app/logger';
import messages from '../../pages/router/messages';

const FwfFlagsContext = React.createContext();

export const FwfFlagsProvider = ({ fwfPromise, flagValues = {}, children }) => {
  const { sessionId, userId, country, city, device } = usePublicEnv();
  const [fwf, setFwf] = useState(null);
  const deviceInfo = useDeviceInfo();
  const subscriptionQuery = useQuery(queryKeyBuilders.subscription(), fetchSubscription, {
    retry: false,
  });
  const { isLoading, isError, refetch, data: subscription } = subscriptionQuery;

  useEffect(() => {
    if (subscription) {
      initializeFWF(fwfPromise, {
        sessionId,
        userData: {
          userId: userId,
        },
        contextData: {
          country: country?.shortName,
          cityId: city?.id,
          device,
          latestSubscriptionStatus: subscription.status,
          appVersion: deviceInfo.appVersion,
          os: deviceInfo.os,
        },
      })
        .then(setFwf)
        .catch((error) => {
          logger.error('FWF_INITIALIZATION_ERROR', {
            error,
          });
        });
    }
  }, [subscription, sessionId, userId, country, city, device, deviceInfo, fwfPromise]);

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return (
      <BrandError
        message={<FormattedMessage {...messages.genericError} />}
        primaryActionLabel="Intentar nuevamente"
        primaryActionClick={refetch}
        secondaryActionLabel="Ir al inicio"
        secondaryActionClick={() => {
          window.location = getHomeLink(deviceInfo);
        }}
      />
    );
  }

  return (
    <FwfFlagsContext.Provider value={{ ...flagValues, fwf }}>{children}</FwfFlagsContext.Provider>
  );
};

FwfFlagsProvider.propTypes = {
  flagValues: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string])),
  children: PropTypes.node.isRequired,
  fwfPromise: PropTypes.instanceOf(Promise),
};

export default FwfFlagsContext;
