import styled from 'styled-components';
import Tag from '@pedidosya/web-fenix/atoms/Tag';

export const Container = styled.div`
  padding: ${({ theme }) => `0 ${theme.space('spacing-12')}`};
  width: 100%;
`;

export const SkeletonContainer = styled.div`
  padding: ${({ theme }) => `0 ${theme.space('spacing-12')} ${theme.space('spacing-14')}`};
  width: 100%;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.space('spacing-10')};
  gap: ${({ theme }) => theme.space('spacing-08')};

  /* Direclty targeting first child as ThumbnialIcon does not allow styling it* */
  > div:first-child {
    outline-color: ${({ theme }) => theme.color('shape-color-background-secondary')};
    flex-shrink: 0;
  }
`;

export const ShimmerIcon = styled.div`
  margin-right: ${({ theme }) => theme.space('spacing-08')};
`;

export const VendorNameText = styled.label`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const DiscountText = styled(Tag)`
  text-transform: uppercase;
  width: fit-content;
  white-space: nowrap;
`;

export const DividerContent = styled.div`
  margin: ${({ theme }) => `${theme.space('spacing-07.5')} 0`};
`;
