import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@pedidosya/web-fenix/atoms';
import { TermsAndCondition } from './TermsAndCondition';
import { Content } from './styled-components';
import messages from './messages';

export const Tyc = ({ terms_and_conditions, onTermAndConditionClick }) => {
  return (
    <>
      <Content>
        <Typography token="text-base-medium" color="text-color-primary">
          <FormattedMessage {...messages.cancelSubscriptionTitle} />
        </Typography>
        {terms_and_conditions &&
          terms_and_conditions.map((tyc) => (
            <TermsAndCondition
              key={tyc}
              type={tyc}
              onTermAndConditionClick={onTermAndConditionClick}
            />
          ))}
      </Content>
    </>
  );
};
