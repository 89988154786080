import { defineMessages } from 'react-intl';

export default defineMessages({
  genericError: {
    id: 'app.landing.genericError',
    defaultMessage: 'No pudimos llevarte a donde esperabas. Intentá nuevamente en unos minutos.',
  },
  brandError: {
    id: 'app.brandError.genericError',
    defaultMessage: 'No pudimos llevarte a donde esperabas.',
  },
  subscribe: {
    id: 'app.landing.subscribe',
    defaultMessage: 'Suscribite',
  },
  tryForFree: {
    id: 'app.landing.tryForFree',
    defaultMessage: 'Probá gratis',
  },

  discountFooter: {
    id: 'app.landing.discountFooter',
    defaultMessage: '{discountAmount, select, 100 {gratis} other {{discountAmountText} OFF}}',
  },

  durationText: {
    id: 'app.landing.durationText',
    defaultMessage:
      '{value, plural, one{# {unit, select, DAY {día} MONTH {mes} YEAR {año} other {mes}}} other{# {unit, select, DAY {días} MONTH {meses} YEAR {años} other {mes}}}}',
  },

  promoTime: {
    id: 'app.landing.promoTime',
    defaultMessage:
      'Durante {value, plural, one{un {unit, select, DAY {día} MONTH {mes} YEAR {año} other {mes}}} other{# {unit, select, DAY {días} MONTH {meses} YEAR {años} other {mes}}}}',
  },

  priceAfterDiscount: {
    id: 'app.landing.priceAfterDiscount',
    defaultMessage: 'Luego {amount} /mes',
  },

  benefitTitle: {
    id: 'app.landing.benefitTitle',
    defaultMessage: 'Beneficios pensados <rb> para vos </rb>',
  },

  vendorsTitle: {
    id: 'app.landing.vendorsTitle',
    defaultMessage: 'Pagá menos <s>en tus locales preferidos: </s>',
  },

  discountTitle: {
    id: 'app.landing.discountTitle',
    defaultMessage: 'envío {discountAmount, select, 100 {gratis} other {{discountAmountText} OFF}}',
  },

  headerStickyTitle: {
    id: 'app.landing.headerStickyTitle',
    defaultMessage: 'Suscribite a Plus',
  },
});
