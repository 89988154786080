import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';
import { ConditionContainer as ConditionContainerWrapper } from './styled-components';

const ConditionContainer = ({ content }) => (
  <ConditionContainerWrapper
    dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(content),
    }}
  />
);

ConditionContainer.propTypes = {
  content: PropTypes.string,
};

export default ConditionContainer;
