import React, { useEffect } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { useIsWebviewIos } from '@app/contexts/deviceInfo/hooks';
import { useIntersect } from '@app/hooks/useIntersect';
import { publishEventsOnDevice } from '@app/hooks/useHeaderConfiguration';
import pedidosYaPlus from '../../../../assets/brand-plus.svg';
import Image from '@pedidosya/web-fenix/foundations/Image';
import { NavigationHeader } from '@app/components/NavigationHeader';
import {
  Header,
  BackgroundImage,
  HeaderContent,
  NavbarContent,
  Container,
  Title,
  Subtitle,
  HeaderIOS,
} from './styled-components';
import { FixedHeader } from './FixedHeader';
import { IMAGE_URL_DOMAIN } from '@commons/constants/imagesDomain';

const peyaPlus = `${IMAGE_URL_DOMAIN}/image/pedidosya/loyalty/loyalty_landing_header.png`;

export const LoyaltyHeader = ({ title, subtitle }) => {
  const isWebviewIos = useIsWebviewIos();
  const [intersectRef, entry] = useIntersect({ threshold: 1 });

  useEffect(() => {
    publishEventsOnDevice({
      theme: entry.isIntersecting ? 'dark' : 'light',
    });
  }, [entry.isIntersecting]);

  return (
    <>
      {/*we leave this comparison entry.isIntersecting === false because the first time entry.isIntersecting is loaded it is undefined
        which makes !entry.isIntersecting false and displays the header creating a blink of the component */}
      <FixedHeader showHeader={entry.isIntersecting === false} />
      <Header ref={intersectRef}>
        <BackgroundImage src={peyaPlus} />
        <HeaderContent isWebviewIos={isWebviewIos}>
          <HeaderIOS isWebviewIos={isWebviewIos} />
          <NavbarContent>
            <NavigationHeader isHistoryRoot fenix />
          </NavbarContent>
          <Container aria-label="header">
            <Image src={pedidosYaPlus} height={12} width={93} />
            <Title
              component="h1"
              token="font-headline-highcontrast-medium"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }}
            />
            {subtitle && (
              <Subtitle
                component="h2"
                token="font-body-midcontrast-sentence-small"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(subtitle) }}
              />
            )}
          </Container>
        </HeaderContent>
      </Header>
    </>
  );
};
