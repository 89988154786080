import React, { useState } from 'react';
import Button from '@pedidosya/web-fenix/atoms/Button';
import Coachmark from '@pedidosya/web-fenix/molecules/Coachmark';
import { Overlay, RightContent } from './styled-components';

const COACHMARK_WIDTH = 240;
const COACHMARK_HEIHT = 162;

const AlternatePaymentCoachmark = ({ showCoachmark, children }) => {
  const [targetEl, setTargetEl] = useState(null);
  const [openCoachmark, setOpenCoachmark] = useState(showCoachmark);

  const translateX = (targetEl?.offsetWidth - COACHMARK_WIDTH) / 2;
  const translateY = COACHMARK_HEIHT + 16;

  return (
    <>
      {openCoachmark ? (
        <div>
          <Overlay showOverlay={openCoachmark} onClick={() => setOpenCoachmark(false)} />
          <Coachmark
            message="Ahora puedes habilitar el uso de tus tarjetas y mantener tus beneficios."
            show={openCoachmark}
            target={targetEl}
            title="Mantén tu suscripción siempre al día "
            pointerPosition="bottom-center"
            translateX={translateX}
            translateY={-translateY}
            content={
              <RightContent>
                <Button
                  label="Entendido"
                  size="large"
                  hierarchy="tertiary"
                  onClick={() => setOpenCoachmark(false)}
                />
              </RightContent>
            }
          />
          <div ref={setTargetEl} style={{ zIndex: 1000, position: 'relative' }}>
            {children}
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default AlternatePaymentCoachmark;
