import React, { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { useDeviceInfo } from '@app/contexts/deviceInfo';
import { fetchStatusDetail } from '@app/services/loyalty/fetchStatusDetail';
import { useHeaderConfiguration } from '@app/hooks/useHeaderConfiguration';
import { getHomeLink } from '@commons/utils/deeplinks';
import fenixTheme from '@pedidosya/web-fenix/theme';
import GlobalStyles from '@pedidosya/web-fenix/theme/GlobalStyles';
import navigation from '@pedidosya/web-native-bridges/navigation';
import { ThemeProvider } from 'styled-components';
import { DetailPage } from './components/DetailPage';
import { BrandError, DialogBrandError } from '../../components/Error';
import Spinner from '../../components/Spinner';
import messages from './messages';
import { useStatusDetailPageTracker } from './tracking/StatusDetailPageTracker';
import { ROUTES } from '@commons/services/routes.service';

export const StatusDetailsPage = () => {
  const intl = useIntl();
  const deviceInfo = useDeviceInfo();
  const history = useHistory();
  const queryClient = useQueryClient();
  const statusDetailQuery = useQuery(queryKeyBuilders.statusDetail(), fetchStatusDetail);
  const [showCancellationError, setShowCancellationError] = useState(false);
  const [cancelLoaded, setCancelLoaded] = useState(false);

  const isLoading = statusDetailQuery.isLoading;
  const isError = statusDetailQuery.isError;

  const { trackClick } = useStatusDetailPageTracker({
    isErrorPage: isError,
    isPageReady: !isLoading,
    changePaymentInstrument: Boolean(statusDetailQuery?.data?.change_payment_instrument?.enabled),
    paymentNotificationError: Boolean(statusDetailQuery?.data?.notification),
  });

  const onCancelSubscriptionBrandErrorClose = () => {
    setShowCancellationError(false);
    updateStatus();
    statusDetailQuery.refetch();
  };

  const redirectToCancelPage = () => {
    const url = `/loyalty-program/cancellation`;
    window.singleSpaNavigate(url);
  };

  useHeaderConfiguration({ isError, theme: 'light' });

  const goBack = () => {
    history.length > 1 ? history.goBack() : navigation.goBack();
  };

  const updateStatus = () => {
    queryClient.removeQueries(queryKeyBuilders.subscription());
    queryClient.removeQueries(queryKeyBuilders.status());
  };

  const onUpdateData = () => {
    statusDetailQuery.refetch();
  };

  if (isLoading || cancelLoaded) {
    return <Spinner />;
  }
  if (isError) {
    return (
      <BrandError
        message={<FormattedMessage {...messages.brandError} />}
        primaryActionLabel="Intentar nuevamente"
        primaryActionClick={statusDetailQuery.refetch}
        secondaryActionLabel="Ir al inicio"
        secondaryActionClick={() => {
          window.location = getHomeLink(deviceInfo);
        }}
      />
    );
  }

  return (
    <ThemeProvider theme={fenixTheme}>
      <GlobalStyles />
      <DetailPage
        onClose={goBack}
        subscription={statusDetailQuery.data.subscription}
        purchase={statusDetailQuery.data.purchase}
        paymentInformation={statusDetailQuery.data.payment_information}
        changeInstrument={statusDetailQuery.data.change_payment_instrument}
        notification={statusDetailQuery.data.notification}
        trackClick={trackClick}
        paymentConfiguration={statusDetailQuery.data.payment_configuration}
        showCoachmark={statusDetailQuery.data.coachmark}
        onChangePaymentConfig={onUpdateData}
        onCancelPageOpen={redirectToCancelPage}
      />
      <DialogBrandError
        open={showCancellationError}
        onClose={onCancelSubscriptionBrandErrorClose}
        message={intl.formatMessage(messages.cancelModalErrorTitle)}
        title={intl.formatMessage(messages.cancelModalErrorMessage)}
      />
    </ThemeProvider>
  );
};
