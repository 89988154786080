import { useRef, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { useTracker } from '@app/contexts/tracker';
import { SUBSCRIPTION_STATUS } from '@commons/models/subscriptionStatusEnum';
import { TRACKING_EVENTS, TRACKING_CLICK_LOCATIONS } from '@commons/constants/tracker';
import CUSTOM_EVENTS from '@commons/constants/customEvents';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { fetchSubscription } from '@app/services/loyalty/fetchSubscription';
import { sendEvent } from '@app/services/loyalty/sendEvent';
import logger from '@app/logger';

/**
 * The cancellation page, from a Data point of view, is the same as the status detail. Thus,
 * for Perseus, we won't send any tracking event on page load and the clicks will have
 * the same event as status detail clicks
 *
 * For custom metrics purposes, this is a different component with its own events
 */

export const useCancellationPageTracker = ({ isErrorPage, isPageReady }) => {
  const loadedRef = useRef(false);
  const subscriptionQuery = useQuery(queryKeyBuilders.subscription(), fetchSubscription);
  const { tracker, origin } = useTracker();
  const { city } = usePublicEnv();

  useEffect(() => {
    const { isSuccess } = subscriptionQuery;

    if (isSuccess && !loadedRef.current && isPageReady) {
      sendEvent(
        isErrorPage
          ? CUSTOM_EVENTS.SUBSCRIPTION_CANCEL_LOADED_ERROR
          : CUSTOM_EVENTS.SUBSCRIPTION_CANCEL_LOADED,
      );

      loadedRef.current = !isErrorPage;
    }
  }, [origin, subscriptionQuery, city?.id, isErrorPage, isPageReady]);

  const trackUnsubscribe = () => {
    const { data } = subscriptionQuery;

    sendEvent(CUSTOM_EVENTS.SUBSCRIPTION_CANCEL_CANCELLED);

    tracker.track(TRACKING_EVENTS.SUBSCRIPTION_DETAILS_CLICKED, {
      origin,
      status: data ? data.status : SUBSCRIPTION_STATUS.UNSUBSCRIBED,
      planId: data ? data.plan_id : undefined,
      cityId: city?.id,
      clickLocation: TRACKING_CLICK_LOCATIONS.CANCEL,
    });
  };

  const trackClick = (clickLocation) => {
    const { data } = subscriptionQuery;
    const event = `loyalty_subscription_cancel.clicked.${clickLocation}`;

    if (CUSTOM_EVENTS.has(event)) {
      sendEvent(event);
    } else {
      logger.error(`Event ${event} is not registered, ignoring it`);
    }

    tracker.track(TRACKING_EVENTS.SUBSCRIPTION_MANAGEMENT_CLICKED, {
      origin,
      status: data ? data.status : SUBSCRIPTION_STATUS.UNSUBSCRIBED,
      planId: data ? data.plan_id : undefined,
      cityId: city?.id,
      clickLocation,
    });
  };

  return { trackClick, trackUnsubscribe };
};
