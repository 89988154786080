import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import {
  BenefitDetailsWrapper,
  BenefitDetailsTitle,
  Ribbon,
  List,
  ListItem,
} from '../styled-components';
import { BenefitDetail } from '../benefitDetail';
import messages from '../plan/messages';

export const BenefitsDetails = React.forwardRef(
  ({ benefits, onTermsAndConditionsClick = Function.prototype }, ref) => {
    return (
      <BenefitDetailsWrapper ref={ref}>
        <BenefitDetailsTitle>
          <Typography token="text-title-medium">
            <FormattedMessage
              {...messages.benefitTitle}
              values={{
                rb: (chunks) => (
                  <Ribbon>
                    <strong>
                      <em>{chunks}</em>
                    </strong>
                  </Ribbon>
                ),
              }}
            />
          </Typography>
        </BenefitDetailsTitle>
        <List aria-label="benefits-details">
          {benefits.map((benefit, idx) => {
            return (
              <ListItem key={idx}>
                <BenefitDetail
                  benefit={benefit}
                  onTermsAndConditionsClick={() => onTermsAndConditionsClick(benefit)}
                />
              </ListItem>
            );
          })}
        </List>
      </BenefitDetailsWrapper>
    );
  },
);

BenefitsDetails.propTypes = {
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      type: PropTypes.string,
      terms_and_conditions: PropTypes.string,
    }),
  ),
  onTermsAndConditionsClick: PropTypes.func,
};
