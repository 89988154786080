import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Divider from '@pedidosya/web-fenix/atoms/Divider';
import ChevronRight from '@pedidosya/web-fenix/icons/ChevronRight';
import {
  Container,
  Row,
  PaymentMethodGrid,
  ChangeInstrument,
  ChangeInstrumentRow,
} from './styled-components';
import usePaymentInstrumentsNavigation from './hooks/usePaymentInstrumentsNavigation';
import messages from './messages';

const PaymentMethod = ({
  icon,
  type,
  lastDigits,
  subtitle,
  instrumentId,
  changeInstrument,
  onChangePaymentInstrumentClick,
}) => {
  const showChangeInstrument =
    changeInstrument.enabled || changeInstrument.reason === 'USER_ONLY_HAS_ONE_PAYMENT_INSTRUMENT';
  const openPaymentInstruments = usePaymentInstrumentsNavigation();

  const handleChangePaymentInstrumentClick = () => {
    if (changeInstrument.enabled) {
      onChangePaymentInstrumentClick();
      openPaymentInstruments(instrumentId);
    }
  };

  return (
    <Container>
      <PaymentMethodGrid>
        {icon}
        <Row>
          <Typography token="font-subtitle-highcontrast-sentence-medium" color="text-color-primary">
            **** {lastDigits}
          </Typography>
          <Typography token="font-body-midcontrast-sentence-medium" color="text-color-primary">
            {type}
          </Typography>
        </Row>
        <div />
        <Typography token="font-body-midcontrast-sentence-small" color="text-color-tertiary">
          {subtitle}
        </Typography>
      </PaymentMethodGrid>
      {showChangeInstrument && (
        <>
          <Divider />
          <ChangeInstrument>
            <ChangeInstrumentRow onClick={handleChangePaymentInstrumentClick}>
              <Typography
                token="font-subtitle-highcontrast-sentence-medium"
                color={
                  changeInstrument.enabled
                    ? 'text-color-primary'
                    : 'text-color-action-disabled-default'
                }
              >
                Cambiar medio de pago
              </Typography>
              <ChevronRight
                size="small"
                color={
                  changeInstrument.enabled
                    ? 'icon-color-primary'
                    : 'icon-color-action-disabled-default'
                }
              />
            </ChangeInstrumentRow>
            {changeInstrument.reason === 'USER_ONLY_HAS_ONE_PAYMENT_INSTRUMENT' && (
              <Typography
                token="font-body-midcontrast-sentence-small"
                color="text-color-action-enabled-quiet"
              >
                <FormattedMessage {...messages.addPaymentInstrumentToChange} />
              </Typography>
            )}
          </ChangeInstrument>
        </>
      )}
    </Container>
  );
};

PaymentMethod.propTypes = {
  icon: PropTypes.node,
  lastDigits: PropTypes.node,
  subtitle: PropTypes.node,
  type: PropTypes.node,
  instrumentId: PropTypes.string,
  changeInstrument: PropTypes.shape({
    enabled: PropTypes.bool,
    reason: PropTypes.string,
  }),
};

export default PaymentMethod;
