import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '@app/utils/format/currency';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { useIntl } from 'react-intl';
import { PriceWrapper } from './styled-components';

const AMOUNT_SIZE_TOKENS = {
  large: 'font-body-midcontrast-sentence-large',
  medium: 'font-subtitle-highcontrast-sentence-medium',
};

export const Price = ({ symbol, actualAmount, beforeAmount, amountSize }) => {
  const intl = useIntl();

  const token = AMOUNT_SIZE_TOKENS[amountSize] || 'font-body-midcontrast-sentence-large';
  return (
    <PriceWrapper aria-label="plan_detail_price">
      {beforeAmount && (
        <Typography token="font-body-midcontrast-strikethrough-small" color="text-color-primary">
          {formatCurrency(intl, { amount: beforeAmount, currencySymbol: symbol })}
        </Typography>
      )}
      <Typography token={token} color="text-color-primary">
        {formatCurrency(intl, { amount: actualAmount, currencySymbol: symbol })}
      </Typography>
    </PriceWrapper>
  );
};

Price.propTypes = {
  symbol: PropTypes.string,
  actualAmount: PropTypes.number,
  beforeAmount: PropTypes.number,
  amountSize: PropTypes.oneOfType(Object.keys(AMOUNT_SIZE_TOKENS)),
};

export default Price;
