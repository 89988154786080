import nconf from 'nconf';
import { Flags } from '@commons/fwf/models/Flags';
import { FLAGS_KEYS } from './constants';

const FLAGS_UPDATED_EVENT_NAME = '@fwf/FLAGS_UPDATED_IN_BACKGROUND';
const CONTEXT_UPDATED_EVENT_NAME = '@fwf/RELEVANT_CONTEXT_CHANGE';

function getUser({ sessionId, userData = {}, contextData = {} }) {
  let user = {
    ...contextData,
  };
  if (sessionId) user.googleClientId = sessionId;
  const { userId, userEmail, ...userDataRest } = userData;
  if (userId) user.userId = userId.toString();
  if (userEmail) user.email = userEmail;
  if (userId || userEmail) user = { ...user, ...userDataRest };
  return user;
}

function importSdk() {
  return import('@deliveryhero/fwf-sdk-javascript');
}

export async function initializeInstance(
  fwfPromise,
  { sessionId, userData, contextData },
  onUpdate,
) {
  const fwf = await fwfPromise;

  if (!fwf) return null;

  const user = getUser({ sessionId, userData, contextData });
  await fwf.setContext(user);
  await fwf.subscribeFeatures(FLAGS_KEYS);
  fwf.subscribeObserver((response) => {
    if (
      response.type === FLAGS_UPDATED_EVENT_NAME ||
      response.type === CONTEXT_UPDATED_EVENT_NAME
    ) {
      const flags = Flags(response.data);
      onUpdate && onUpdate(flags);
    }
  });

  return fwf;
}

export async function getServerInstance({ sessionId, userData, contextData = {} }) {
  const fwfConfig = nconf.get('fwf');
  const FWF_TOKEN = fwfConfig.token;
  const FWF_URL = fwfConfig.url;
  const FLAG_TTL_IN_MINUTES = fwfConfig.flagTimeToLive;
  const DEBUG_MODE = fwfConfig.debugMode;

  const user = getUser({ sessionId, userData, contextData });
  const { default: FWFMain, FWFRegions, FWFApiVersion } = await importSdk();
  const fwfParams = {
    apiVersion: FWFApiVersion.V3,
    environmentToken: FWF_TOKEN,
    flagTimeToLive: FLAG_TTL_IN_MINUTES,
    region: FWFRegions.US,
    localFwfUrl: FWF_URL,
    debugMode: DEBUG_MODE,
    httpRequestTimeout: fwfConfig.httpRequestTimeout,
  };
  return new FWFMain({ ...fwfParams, user });
}
