import React from 'react';
import PropTypes from 'prop-types';
import ChevronDown from '@pedidosya/web-fenix/icons/ChevronDown';
import ChevronUp from '@pedidosya/web-fenix/icons/ChevronUp';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { Content, Title, Container } from './styled-components';

const Arrow = ({ expanded }) => {
  return expanded ? <ChevronUp size={'small'} /> : <ChevronDown size={'small'} />;
};

export const Accordion = ({ expanded, title, onToggle, children }) => {
  return (
    <div>
      <Container onClick={onToggle} expanded={expanded}>
        <Title expanded={expanded}>
          <Typography token="text-base-large">{title}</Typography>
        </Title>
        <div>
          <Arrow expanded={expanded} />
        </div>
      </Container>
      {expanded && <Content expanded={expanded}>{children}</Content>}
    </div>
  );
};

Accordion.propTypes = {
  expanded: PropTypes.bool,
  title: PropTypes.node,
  onToggle: PropTypes.func,
  children: PropTypes.node,
};
