import styled from 'styled-components';
import Modal from '@pedidosya/web-fenix/organisms/Modal';

export const BrandErrorModal = styled(Modal)`
  padding-bottom: 4px;

  & > div {
    padding: 0;
  }
`;

export const ErrorModalContainer = styled.div`
  margin: 0 auto;

  & > main div {
    min-height: 100%;
  }

  & > main div > header {
    display: none;
  }
`;

export const HeaderIOS = styled.div`
  width: 100%;

  ${({ isWebviewIos, theme }) =>
    /* The following values are hardcoded as they don't depend on fenix tokens */
    isWebviewIos &&
    `
    @media ${theme.mediaQueries.mobileIos} {
      padding-top: 20px;
    }

    @media ${theme.mediaQueries.mobileIosNotch} {
      padding-top: 40px;
    }
  `}
`;

export const NavbarWrapper = styled.div`
  position: absolute;
  width: 100%;
`;

export const NavbarContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const NavbarIcon = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.space('spacing-5')};
  padding-right: ${({ theme }) => theme.space('spacing-5')};
`;
