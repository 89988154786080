const parseItem = (item) => {
  try {
    return JSON.parse(item);
  } catch (error) {
    return item;
  }
};

export class Storage {
  constructor(storage) {
    this.storage = storage;
  }

  getItem(key) {
    return parseItem(this.storage.getItem(key));
  }

  setItem(key, value) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  removeItem(key) {
    return parseItem(this.storage.removeItem(key));
  }
}
