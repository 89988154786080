const { TRACKING_EVENTS, TRACKING_CLICK_LOCATIONS, TRACKING_SWIP_LOCATIONS } = require('./tracker');

const landingCustomEvents = {
  // Load events
  PLUS_PLAN_DETAILS_LOADED: TRACKING_EVENTS.PLUS_PLAN_DETAILS_LOADED,
  PLUS_PLAN_DETAILS_LOADED_ERROR: `${TRACKING_EVENTS.PLUS_PLAN_DETAILS_LOADED}.error`,
  PLUS_PLAN_CHANNEL_LOADED: 'loyalty_plan_details_channel.loaded',

  // Clicks
  PLUS_PLAN_DETAILS_CLICKED_SUBSCRIBE: `${TRACKING_EVENTS.PLUS_PLAN_DETAILS_CLICKED}.${TRACKING_CLICK_LOCATIONS.SUBSCRIBE}`,
  PLUS_PLAN_DETAILS_CLICKED_SEE_MORE: `${TRACKING_EVENTS.PLUS_PLAN_DETAILS_CLICKED}.${TRACKING_CLICK_LOCATIONS.SEE_MORE}`,
  PLUS_PLAN_DETAILS_CLICKED_TERMS_AND_CONDITIONS: `${TRACKING_EVENTS.PLUS_PLAN_DETAILS_CLICKED}.${TRACKING_CLICK_LOCATIONS.TERMS_AND_CONDITIONS}`,
  PLUS_PLAN_DETAILS_CLICKED_FAQ: `${TRACKING_EVENTS.PLUS_PLAN_DETAILS_CLICKED}.${TRACKING_CLICK_LOCATIONS.FAQ}`,
  PLUS_PLAN_DETAILS_CLICKED_BENEFIT_TERMS_AND_CONDITIONS: `${TRACKING_EVENTS.PLUS_PLAN_DETAILS_CLICKED}.benefit.${TRACKING_CLICK_LOCATIONS.TERMS_AND_CONDITIONS}`,

  // Swipe
  PLUS_PLAN_DETAILS_SWIPED_VENDOR_SWIPE: `${TRACKING_EVENTS.PLUS_PLAN_DETAILS_SWIPED}.${TRACKING_SWIP_LOCATIONS.VENDORS_SWIMLANE}`,
  PLUS_PLAN_DETAILS_SWIPED_MARKDOWN_SWIMLANE: `${TRACKING_EVENTS.PLUS_PLAN_DETAILS_SWIPED}.${TRACKING_SWIP_LOCATIONS.MARKDOWN_SWIMLANE}`,
};

const statusCustomEvents = {
  // Load events
  SUBSCRIPTION_DETAILS_LOADED: TRACKING_EVENTS.SUBSCRIPTION_DETAILS_LOADED,
  SUBSCRIPTION_DETAILS_LOADED_ERROR: `${TRACKING_EVENTS.SUBSCRIPTION_DETAILS_LOADED}.error`,

  // Clicks
  SUBSCRIPTION_DETAILS_CLICKED_MANAGE: `${TRACKING_EVENTS.SUBSCRIPTION_DETAILS_CLICKED}.${TRACKING_CLICK_LOCATIONS.MANAGE}`,
  SUBSCRIPTION_DETAILS_CLICKED_SEE_MORE: `${TRACKING_EVENTS.SUBSCRIPTION_DETAILS_CLICKED}.${TRACKING_CLICK_LOCATIONS.SEE_MORE}`,
  SUBSCRIPTION_DETAILS_CLICKED_TERMS_AND_CONDITIONS: `${TRACKING_EVENTS.SUBSCRIPTION_DETAILS_CLICKED}.${TRACKING_CLICK_LOCATIONS.TERMS_AND_CONDITIONS}`,
  SUBSCRIPTION_DETAILS_CLICKED_FAQ: `${TRACKING_EVENTS.SUBSCRIPTION_DETAILS_CLICKED}.${TRACKING_CLICK_LOCATIONS.FAQ}`,
  SUBSCRIPTION_DETAILS_CLICKED_BENEFIT_TERMS_AND_CONDITIONS: `${TRACKING_EVENTS.SUBSCRIPTION_DETAILS_CLICKED}.benefit.${TRACKING_CLICK_LOCATIONS.TERMS_AND_CONDITIONS}`,
  SUBSCRIPTION_DETAILS_CLICKED_BENEFIT_CTA: `${TRACKING_EVENTS.SUBSCRIPTION_DETAILS_CLICKED}.benefit.${TRACKING_CLICK_LOCATIONS.CTA}`,
};

const statusDetailsCustomEvents = {
  // Load events
  SUBSCRIPTION_MANAGEMENT_LOADED: TRACKING_EVENTS.SUBSCRIPTION_MANAGEMENT_LOADED,
  SUBSCRIPTION_MANAGEMENT_LOADED_ERROR: `${TRACKING_EVENTS.SUBSCRIPTION_MANAGEMENT_LOADED}.error`,

  // Instrument changed
  PAYMENT_INSTRUMENT_CHANGED: 'loyalty_payment_instrument.updated',
  PAYMENT_INSTRUMENT_CHANGED_ERROR: 'loyalty_payment_instrument.updated.error',

  // Subscription cancelled
  SUBSCRIPTION_STATUS_CANCELLED: 'loyalty_subscription_status.updated.cancelled',

  // Clicks
  SUBSCRIPTION_MANAGEMENT_CLICKED_PAYMENTS: `${TRACKING_EVENTS.SUBSCRIPTION_MANAGEMENT_CLICKED}.${TRACKING_CLICK_LOCATIONS.PAYMENTS}`,
  SUBSCRIPTION_MANAGEMENT_CLICKED_HELP: `${TRACKING_EVENTS.SUBSCRIPTION_MANAGEMENT_CLICKED}.${TRACKING_CLICK_LOCATIONS.HELP}`,
  SUBSCRIPTION_MANAGEMENT_CLICKED_CANCEL_CLICK: `${TRACKING_EVENTS.SUBSCRIPTION_MANAGEMENT_CLICKED}.${TRACKING_CLICK_LOCATIONS.CANCEL_CLICK}`,
  SUBSCRIPTION_MANAGEMENT_CLICKED_CANCEL_CHANGE_PAYMENT_INSTRUMENT: `${TRACKING_EVENTS.SUBSCRIPTION_MANAGEMENT_CLICKED}.${TRACKING_CLICK_LOCATIONS.CHANGE_PAYMENT_INSTRUMENT}`,
  SUBSCRIPTION_MANAGEMENT_CLICKED_CHANGE_MULTIPLE_PAYMENT_CARD: `${TRACKING_EVENTS.SUBSCRIPTION_MANAGEMENT_CLICKED}.${TRACKING_CLICK_LOCATIONS.CHANGE_MULTIPLE_PAYMENT_CARD}`,
};

const congratsCustomEvents = {
  // Load events
  SUBSCRIPTION_COMPLETED_LOADED: TRACKING_EVENTS.SUBSCRIPTION_COMPLETED_LOADED,
  SUBSCRIPTION_ALL_IN_ONE_COMPLETED_LOADED: `${TRACKING_EVENTS.SUBSCRIPTION_COMPLETED_LOADED}.all_in_one`,
  SUBSCRIPTION_COMPLETED_LOADED_ERROR: `${TRACKING_EVENTS.SUBSCRIPTION_COMPLETED_LOADED}.error`,
  SUBSCRIPTION_ALL_IN_ONE_COMPLETED_LOADED_ERROR: `${TRACKING_EVENTS.SUBSCRIPTION_COMPLETED_LOADED}.error.all_in_one`,
};

const cancellationCustomEvents = {
  // Load events
  SUBSCRIPTION_CANCEL_LOADED: TRACKING_EVENTS.SUBSCRIPTION_CANCEL_LOADED,
  SUBSCRIPTION_CANCEL_LOADED_ERROR: `${TRACKING_EVENTS.SUBSCRIPTION_CANCEL_LOADED}.error`,

  // Subscription cancelled
  SUBSCRIPTION_CANCEL_CANCELLED: 'loyalty_subscription_cancel.updated.cancelled',
};

const customEvents = {
  ...landingCustomEvents,
  ...statusCustomEvents,
  ...statusDetailsCustomEvents,
  ...congratsCustomEvents,
  ...cancellationCustomEvents,
};

const customEventsSet = new Set(Object.values(customEvents));

module.exports = { ...customEvents, has: (value) => customEventsSet.has(value) };
