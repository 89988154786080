import { IMAGE_URL_DOMAIN } from '../constants/imagesDomain';

const BASE_URL = `${IMAGE_URL_DOMAIN}/image/pedidosya/loyalty`;

export const URL_ICONS = {
  MOTOR_BIKE: `${BASE_URL}/loyalty-illustration-free-delivery-benefit-new.png`,
  BASKET: `${BASE_URL}/loyalty-illustration-dmarts-benefit-new.png`,
  CREDIT_CARD: `${BASE_URL}/loyalty-credit-cards-illustration.png`,
  LUNCH: `${BASE_URL}/loyalty-illustration-lunch-benefit-new.png`,
  DRINK: `${BASE_URL}/loyalty-illustration-drink-benefit-new.png`,
  CUSTOMER_SUPPORT_PLUS: `${BASE_URL}/loyalty-illustration-customer-attention-new.png`,
  COUPONS: `${BASE_URL}/loyalty-illustration-coupons-new.png`,
};
