import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import SimpleError from '@pedidosya/web-fenix/pages/Error';
import { ErrorModalContainer, BrandErrorModal } from './styled-components';

export const Error = ({ open, onClose, intl, ...props }) => (
  <BrandErrorModal open={open}>
    <ErrorModalContainer>
      <SimpleError
        errorCode={500}
        primaryActionLabel="Entendido"
        primaryActionClick={onClose}
        {...props}
      />
    </ErrorModalContainer>
  </BrandErrorModal>
);

Error.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export const DialogBrandError = injectIntl(Error);
