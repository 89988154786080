import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import FenixArrowLeft from '@pedidosya/web-fenix/icons/ChevronLeft';
import { HOME_LINKS } from '@commons/utils/deeplinks';
import { PRESENTATION_QUERY } from '@commons/models/presentationQueryEnum';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { Button } from './styled-components';

const WebBackNavigation = ({
  color,
  onNavigationClick = Function.prototype,
  isHistoryRoot = false,
}) => {
  const history = useHistory();
  const { presentation = PRESENTATION_QUERY.ROOT } = usePublicEnv();
  const withNativeBackNavigation = presentation === PRESENTATION_QUERY.PUSH;
  const canNavigateBack = !isHistoryRoot && history && history.length !== 1;

  const onBackNavigation = () => {
    if (!canNavigateBack) {
      if (withNativeBackNavigation) {
        history.goBack();
      } else {
        window.location.assign(HOME_LINKS.WEB);
      }
    } else {
      history.goBack();
    }
    onNavigationClick();
  };

  return (
    <Button role="button" onClick={onBackNavigation} title="webGoBack">
      <FenixArrowLeft size="medium" color={color} />
    </Button>
  );
};

WebBackNavigation.propTypes = {
  color: PropTypes.string,
  fenix: PropTypes.bool,
  onNavigationClick: PropTypes.func,
  isHistoryRoot: PropTypes.bool,
};

export default WebBackNavigation;
