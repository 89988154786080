import styled from 'styled-components';
import CardContainer from '@pedidosya/web-fenix/molecules/CardContainer';

export const CardSummaryWrapper = styled(CardContainer)`
  padding: ${({ theme }) => `${theme.space('spacing-12')} ${theme.space('spacing-07')}`};
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  text-align: start;
  list-style: none;
  gap: ${({ theme }) => theme.space('spacing-12')};
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  display: flex;
  gap: ${({ theme }) => theme.space('spacing-02')};
`;
