import axios from 'axios';
import logger from '@app/logger';

export const fetchRedirectTyc = async () => {
  try {
    const tycResponse = await axios.get('/loyalty/api/redirect/tc');
    return tycResponse.data;
  } catch (error) {
    logger.error('Error getting redirect tyc data');
    throw error;
  }
};
