import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Button from '@pedidosya/web-fenix/atoms/Button';
import messages from '../../../status/messages';

export const Cancel = ({ onClick = Function.prototype, onCancelPageOpen }) => {
  const intl = useIntl();

  const onCancelSubscriptionClick = () => {
    onClick();
    onCancelPageOpen();
  };

  return (
    <>
      <Button
        label={intl.formatMessage(messages.cancelButton)}
        size="large"
        hierarchy="tertiary"
        onClick={onCancelSubscriptionClick}
        fullWidth
      />
    </>
  );
};

Cancel.propTypes = {
  planId: PropTypes.number.isRequired,
  onUnsubscribe: PropTypes.func.isRequired,
};
