import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => theme.space('spacing-10')};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space('spacing-10')};

  > * {
    order: 0;
  }
`;

export const PaymentMethodGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  column-gap: ${({ theme }) => theme.space('spacing-4')};
  row-gap: ${({ theme }) => theme.space('spacing-1')};
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space('spacing-4')};
`;

export const ChangeInstrument = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space('spacing-4')};
`;

export const ChangeInstrumentRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
