import styled from 'styled-components';

export const PaymentLinkWrapper = styled.div`
  padding: ${({ theme }) => `${theme.space('spacing-4')} ${theme.space('spacing-4')}`};
`;

export const Container = styled.div`
  padding: ${({ theme }) => `0 ${theme.space('spacing-08')} ${theme.space('spacing-12')}`};
  width: 100%;
`;

export const TitleContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.space('spacing-04')}
  padding: ${({ theme }) => `${theme.space('spacing-05')} ${theme.space('spacing-04')} 0`};
  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-top: ${({ theme }) => theme.space('spacing-07.5')};
`;

export const BillButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  > * {
    height: auto;
    left: initial;
    right: initial;
  }

  ${({ theme, isSuccess }) =>
    isSuccess &&
    `
    button {
      color: ${theme.color('text-color-feedback-positive')}
    }
  `}
`;

export const Header = styled.div`
  margin: ${({ theme }) => `${theme.space('spacing-04')} 0`};
  width: 100%;
`;

export const SkeletonContainer = styled.div`
  padding: ${({ theme }) => `0 ${theme.space('spacing-12')} ${theme.space('spacing-14')}`};
  width: 100%;
`;

export const ContactText = styled.div`
  padding-top: ${({ theme }) => theme.space('spacing-08')};
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color('text-color-secondary')};
`;
