import { useRef, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { useTracker, TRACKING_NULL_ERROR } from '@app/contexts/tracker';
import { SUBSCRIPTION_STATUS } from '@commons/models/subscriptionStatusEnum';
import { TRACKING_EVENTS } from '@commons/constants/tracker';
import CUSTOM_EVENTS from '@commons/constants/customEvents';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { fetchSubscription } from '@app/services/loyalty/fetchSubscription';
import { sendEvent } from '@app/services/loyalty/sendEvent';
import logger from '@app/logger';

export const useStatusDetailPageTracker = ({
  isErrorPage,
  isPageReady,
  changePaymentInstrument,
  paymentNotificationError,
}) => {
  const loadedRef = useRef(false);
  const subscriptionQuery = useQuery(queryKeyBuilders.subscription(), fetchSubscription);
  const { tracker, origin } = useTracker();
  const { city } = usePublicEnv();

  useEffect(() => {
    const { data, isSuccess } = subscriptionQuery;

    if (isSuccess && !loadedRef.current && isPageReady) {
      sendEvent(
        isErrorPage
          ? CUSTOM_EVENTS.SUBSCRIPTION_MANAGEMENT_LOADED_ERROR
          : CUSTOM_EVENTS.SUBSCRIPTION_MANAGEMENT_LOADED,
      );

      tracker.track(TRACKING_EVENTS.SUBSCRIPTION_MANAGEMENT_LOADED, {
        origin,
        status: data ? data.status : SUBSCRIPTION_STATUS.UNSUBSCRIBED,
        planId: data ? data.plan_id : undefined,
        cityId: city?.id,
        errorMessage: isErrorPage ? 'Error loading status detail' : TRACKING_NULL_ERROR,
        changePaymentInstrument: changePaymentInstrument ? 'ENABLED' : 'DISABLED',
        paymentNotificationError: Boolean(paymentNotificationError).toString(),
      });

      loadedRef.current = !isErrorPage;
    }
  }, [
    tracker,
    origin,
    subscriptionQuery,
    city?.id,
    isErrorPage,
    isPageReady,
    changePaymentInstrument,
    paymentNotificationError,
  ]);

  const trackClick = (clickLocation) => {
    const { data } = subscriptionQuery;
    const event = `loyalty_subscription_management.clicked.${clickLocation}`;

    if (CUSTOM_EVENTS.has(event)) {
      sendEvent(event);
    } else {
      logger.error(`Event ${event} is not registered, ignoring it`);
    }

    tracker.track(TRACKING_EVENTS.SUBSCRIPTION_MANAGEMENT_CLICKED, {
      origin,
      status: data ? data.status : SUBSCRIPTION_STATUS.UNSUBSCRIBED,
      planId: data ? data.plan_id : undefined,
      cityId: city?.id,
      clickLocation,
    });
  };

  return { trackClick };
};
