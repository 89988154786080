import React from 'react';
import PropTypes from 'prop-types';
import { Benefits } from './Benefits';
import { CardSummaryWrapper } from './styled-components';

export const BenefitsCard = ({ benefits }) => {
  return (
    <CardSummaryWrapper withBorder={false}>
      <Benefits benefits={benefits} />
    </CardSummaryWrapper>
  );
};

BenefitsCard.propTypes = {
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.node,
    }),
  ),
};
