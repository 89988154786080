import styled from 'styled-components';

export const FaqWrapper = styled.div`
  text-align: left;
  background-color: ${({ theme }) => theme.color('shape-color-surface-action-oncontent-focused')};
  padding: ${({ theme }) => `${theme.space('spacing-12')} ${theme.space('spacing-18')} 0`};
`;

export const FaqTitle = styled.h2`
  margin: ${({ theme }) => `0 0 ${theme.space('spacing-08')}`};
`;
