import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Typography } from '@pedidosya/web-fenix/atoms';
import { TermsAndConditions } from '@app/components/TermsAndConditions';
import { ButtonLink } from '@app/components/ButtonLink';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { fetchPlanTC } from '@app/services/loyalty/fetchPlanTC';

const AlternatePaymentTyC = ({ planId, onTermAndConditionClick }) => {
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  const { isLoading, data } = useQuery(
    queryKeyBuilders.planTyc(planId),
    () => fetchPlanTC({ planId }),
    { enabled: showTermsAndConditions },
  );

  const onCloseTermsAndConditions = () => setShowTermsAndConditions(false);

  const openTermsAndConditions = () => {
    onTermAndConditionClick();
    setShowTermsAndConditions(true);
  };

  return (
    <>
      <Typography token="text-base-medium" color="text-color-tertiary">
        Al prender el botón, aceptas{' '}
        <ButtonLink onClick={() => openTermsAndConditions()}>
          <Typography token="text-utility-underline-medium"> términos y condiciones</Typography>
        </ButtonLink>
      </Typography>
      <TermsAndConditions
        open={showTermsAndConditions}
        onClose={onCloseTermsAndConditions}
        isLoading={isLoading}
        data={data}
      />
    </>
  );
};

export default AlternatePaymentTyC;
