import { useQuery } from '@tanstack/react-query';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { useTracker, TRACKING_NULL_ERROR } from '@app/contexts/tracker';
import { fetchSubscription } from '@app/services/loyalty/fetchSubscription';
import { sendEvent } from '@app/services/loyalty/sendEvent';
import { SUBSCRIPTION_STATUS } from '@commons/models/subscriptionStatusEnum';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { TRACKING_EVENTS } from '@commons/constants/tracker';
import CUSTOM_EVENTS from '@commons/constants/customEvents';

export const useInstrumentChangeSuccessTracker = () => {
  const subscriptionQuery = useQuery(queryKeyBuilders.subscription(), fetchSubscription);
  const { tracker, origin } = useTracker();
  const { city } = usePublicEnv();

  const trackInstrumentChange = (instrumentId, error = false) => {
    const { data } = subscriptionQuery;
    const previousInstrumentId = data?.instrument_id;

    sendEvent(
      error
        ? CUSTOM_EVENTS.PAYMENT_INSTRUMENT_CHANGED_ERROR
        : CUSTOM_EVENTS.PAYMENT_INSTRUMENT_CHANGED,
    );

    tracker.track(TRACKING_EVENTS.PAYMENT_INSTRUMENT_CHANGED, {
      origin,
      status: data ? data.status : SUBSCRIPTION_STATUS.UNSUBSCRIBED,
      planId: data ? data.plan_id : undefined,
      cityId: city?.id,
      instrumentId,
      instrumentChanged: instrumentId !== previousInstrumentId,
      errorMessage: error ? 'Error changing payment instrument' : TRACKING_NULL_ERROR,
    });
  };

  return { trackInstrumentChange, isTrackReady: subscriptionQuery.isSuccess };
};
