import axios from 'axios';
import logger from '@app/logger';

export const changePaymentInstrument = async (instrumentId) => {
  try {
    await axios.post(`/loyalty/api/subscriptions/changePaymentInstrument`, { instrumentId });
  } catch (error) {
    logger.error(`Error changing subscription's instrument to: ${instrumentId}`);
    throw error;
  }
};
