import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  color: ${({ theme }) => theme.color('text-color-tertiary')};
`;

export const TableCell = styled.td`
  text-align: ${({ align }) => align || 'left'};
  margin: 0;
  padding: ${({ theme }) => `${theme.space('spacing-03.5')} ${theme.space('spacing-04.5')}`};
`;

export const TableRow = styled.tr``;

export const TableBody = styled.tbody`
  ${TableCell} {
    padding-top: ${({ theme }) => theme.space('spacing-06.5')};
  }

  ${TableRow}:first-child {
    ${TableCell} {
      padding-top: ${({ theme }) => theme.space('spacing-07')};
    }
  }

  ${TableRow}:last-child {
    ${TableCell} {
      padding-bottom: ${({ theme }) => theme.space('spacing-08.5')};
      border-bottom: ${({ theme }) => `1px solid ${theme.color('shape-color-stroke-primary')}`};
    }
  }
`;

export const TableHead = styled.thead`
  ${TableCell} {
    border-bottom: ${({ theme }) => `1px solid ${theme.color('shape-color-stroke-primary')}`};
  }
`;
