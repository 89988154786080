import React from 'react';
import PropTypes from 'prop-types';
import Image from '@pedidosya/web-fenix/foundations/Image';
import { BENEFIT_TYPES } from '@commons/models/benefitTypeEnum';
import { URL_ICONS } from '@commons/models/benefitIconUrl';

const ICON_BY_TYPE = Object.fromEntries(
  [
    [BENEFIT_TYPES.DELIVERY_FEE, URL_ICONS.MOTOR_BIKE, 64, 64],
    [BENEFIT_TYPES.DMART_CHARGEBACKS, URL_ICONS.BASKET, 64, 64],
    [BENEFIT_TYPES.DMARTS, URL_ICONS.BASKET, 64, 64],
    [BENEFIT_TYPES.VISA_MARKDOWN, URL_ICONS.CREDIT_CARD, 70, 56],
    [BENEFIT_TYPES.LUNCH, URL_ICONS.LUNCH, 64, 64],
    [BENEFIT_TYPES.DRINKS, URL_ICONS.DRINK, 64, 64],
    [BENEFIT_TYPES.CUSTOMER_SUPPORT_PLUS, URL_ICONS.CUSTOMER_SUPPORT_PLUS, 64, 64],
    [BENEFIT_TYPES.GENERIC_BENEFIT, URL_ICONS.COUPONS, 64, 64],
  ].map(([key, icon, width, height]) => [
    key,
    (props) => (
      <Image src={icon} alt={key.toLowerCase()} width={width} height={height} {...props} />
    ),
  ]),
);

export const BenefitDetailIcon = ({ type, ...props }) => {
  if (ICON_BY_TYPE[type]) {
    const Component = ICON_BY_TYPE[type];
    return <Component {...props} />;
  }

  return null;
};

BenefitDetailIcon.propTypes = {
  type: PropTypes.oneOf(Object.keys(ICON_BY_TYPE)),
};
