import { useEffect, useState, useRef } from 'react';

const useDetectScrollWithFocus = (onScrollDetected) => {
  const [scrollDetected, setScrollDetected] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleScroll = (event) => {
      if (!scrollDetected && event.target.scrollLeft > 0) {
        onScrollDetected();
        setScrollDetected(true);
      }
    };

    const handleFocus = () => {
      setScrollDetected(false);
    };

    const currentWrapper = wrapperRef.current;
    if (currentWrapper) {
      currentWrapper.addEventListener('scroll', handleScroll);
      window.addEventListener('focus', handleFocus);
    }

    return () => {
      if (currentWrapper) {
        currentWrapper.removeEventListener('scroll', handleScroll);
        window.removeEventListener('focus', handleFocus);
      }
    };
  }, [scrollDetected]);

  return wrapperRef;
};

export default useDetectScrollWithFocus;
