import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) =>
    `${theme.space('spacing-component-large')} ${theme.space('layout-margin-mobile')} 0`};
  width: 100%;
`;

export const Paragraph = styled.div`
  margin-bottom: ${({ theme }) => theme.space('spacing-10')};
  width: 100%;

  > * {
    margin-bottom: ${({ theme }) => theme.space('spacing-04')};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
