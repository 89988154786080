import React, { Fragment } from 'react';

/**
 * Replace placeholders like `{var}` with given JSX replacements
 * @param {String} str
 * @param {Object} replacements
 * @returns
 */
export const replaceString = (str, replacements) => {
  const splitRegex = new RegExp(/\{(\w*)\}/g);
  const parts = str.split(splitRegex);

  return parts.map((part, idx) => {
    if (replacements.hasOwnProperty(part)) {
      return <Fragment key={idx}>{replacements[part]}</Fragment>;
    }
    return part;
  });
};
