import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { SUBSCRIPTION_STATUS } from '@commons/models/subscriptionStatusEnum';
import { ROUTES } from '@commons/services/routes.service';

export const RouteByStatus = ({ allowedStatuses = [], status, children, ...props }) => {
  if (allowedStatuses.includes(status)) return <Route {...props}>{children}</Route>;
  if ([SUBSCRIPTION_STATUS.INACTIVE, SUBSCRIPTION_STATUS.UNSUBSCRIBED].includes(status)) {
    return <Redirect to={ROUTES.PLAN_LANDING} />;
  }

  return <Redirect to={ROUTES.STATUS} />;
};

RouteByStatus.propTypes = {
  allowedStatuses: PropTypes.arrayOf(PropTypes.oneOf(Object.values(SUBSCRIPTION_STATUS))),
  status: PropTypes.oneOf(Object.values(SUBSCRIPTION_STATUS)).isRequired,
  children: PropTypes.oneOf([React.node, undefined]),
};
