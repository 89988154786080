import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { BrandError } from '@app/components/Error';
import navigation from '@pedidosya/web-native-bridges/navigation';
import { LoyaltyRouter } from '@app/pages/router/LoyaltyRouter';
import { ROUTES } from '@commons/services/routes.service';

function Router() {
  return (
    <Switch>
      <Route
        exact
        path={[
          ROUTES.LANDING,
          ROUTES.STATUS,
          ROUTES.PLAN_LANDING,
          ROUTES.CONGRATS,
          ROUTES.STATUS_DETAILS,
          ROUTES.PAYMENT_INSTRUMENT_CHANGE_SUCCESS,
          ROUTES.CANCELLATION,
          ROUTES.REDIRECT,
          ROUTES.UPCOMING_TYC,
        ]}
        component={LoyaltyRouter}
      />
      <Route exact path={['/loyalty']} render={() => <Redirect to={ROUTES.LANDING} />} />
      <Route
        render={() => (
          <BrandError
            errorCode={404}
            primaryActionLabel="Volver"
            primaryActionClick={() => navigation.goBack()}
          />
        )}
      />
    </Switch>
  );
}

export default Router;
