import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Information from '@pedidosya/web-fenix/icons/Information';
import Close from '@pedidosya/web-fenix/icons/Close';
import { MessageBox } from './styled-components';

const DismissableMessage = ({ children }) => {
  const [show, setShow] = useState(true);

  return (
    show && (
      <MessageBox color="#2656f7">
        <Information size="small" color="shape-color-surface-feedback-informative" />
        <Typography token="text-highlight-medium">{children}</Typography>

        <Close size="small" onClick={() => setShow(false)} />
      </MessageBox>
    )
  );
};

DismissableMessage.propTypes = {
  children: PropTypes.node,
};

export default DismissableMessage;
