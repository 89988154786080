import styled from 'styled-components';
import FooterContainer from '@pedidosya/web-fenix/molecules/FooterContainer';

export const FooterContainerWrapper = styled(FooterContainer)`
  position: fixed;
  bottom: 0px;
  z-index: 99;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ isWebviewIos, theme }) =>
    isWebviewIos
      ? `
      @media ${theme.mediaQueries.mobileIosNotch} {
        padding-bottom: ${theme.space('spacing-15')};
      }
    `
      : ''}
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ largeSpacing, theme }) =>
    `${largeSpacing ? theme.space('spacing-02') : theme.space('spacing-01')}`};
`;

export const TextContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => `${theme.space('spacing-02')}`};
`;

export const ConcatContainer = styled(TextContainer)`
  flex-direction: column;
`;

export const PriceContainer = styled(TextContainer)`
  align-items: end;
`;

export const DiscountContainer = styled(TextContainer)`
  align-items: center;
`;
