import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { TitleContainer } from './styled-components';
import messages from '../../messages';

const getMonthText = (stringDate) => {
  const date = new Date(stringDate);
  return date.toLocaleString('es-ES', { month: 'short' });
};

const getFullYear = (stringDate) => {
  const date = new Date(stringDate);
  return date.toLocaleString('es-ES', { year: '2-digit' });
};

export const HeaderTitle = ({ startDate }) => {
  return (
    <TitleContainer>
      <Typography token="text-title-medium" color="text-color-inverted">
        Tu suscripción
      </Typography>
      <Typography token="text-highlight-medium" color="text-color-inverted">
        <FormattedMessage
          {...messages.headerSubtitle}
          values={{ month: getMonthText(startDate), year: getFullYear(startDate) }}
        />
      </Typography>
    </TitleContainer>
  );
};

HeaderTitle.propTypes = {
  startDate: PropTypes.string,
};
