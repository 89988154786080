import React from 'react';
import Skeleton from '@pedidosya/web-fenix/atoms/Skeleton';

import { Paragraph } from './styled-components';

const TcSkeleton = () => {
  return (
    <>
      <Paragraph>
        <Skeleton shape="square" height="shape-size-action-3" />
        <Skeleton shape="square" height="shape-size-action-3" />
        <Skeleton shape="square" height="shape-size-action-3" />
        <Skeleton shape="square" height="shape-size-action-3" />
        <Skeleton shape="square" height="shape-size-action-3" />
      </Paragraph>
    </>
  );
};

export default TcSkeleton;
