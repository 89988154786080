import axios from 'axios';
import logger from '@app/logger';

export const createCancelSubscriptionSurvey = async () => {
  try {
    const survey = await axios.post(`/loyalty/api/subscriptions/cancel/survey`);
    return survey.data;
  } catch (error) {
    logger.error(`Error creating cancel subscription survey`);
    throw error;
  }
};
