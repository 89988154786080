import styled from 'styled-components';

export const SurveyContainer = styled.div`
  overflow: auto;
  width: 100%;
  height: 100vh;
  ${({ loaded, hasError }) => (!loaded || hasError) && `display: none;`}

  ${({ isWebviewIos, theme }) =>
    isWebviewIos &&
    `
    @media ${theme.mediaQueries.mobileIos} {
      padding-top: 20px;
    }

    @media ${theme.mediaQueries.mobileIosNotch} {
      padding-top: 40px;
    }
`}
`;
