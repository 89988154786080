import styled, { keyframes, css } from 'styled-components';
import Image from '@pedidosya/web-fenix/foundations/Image';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) =>
    `${theme.space('spacing-08')} ${theme.space('spacing-08')} 0 ${theme.space('spacing-08')}`};
  width: 100%;
  gap: ${({ theme }) => theme.space('spacing-component-2xlarge')};
`;

export const TextContainer = styled(Typography)`
  color: white;
`;

export const Title = styled(TextContainer)`
  margin-bottom: 0;
`;

export const Subtitle = styled(TextContainer)`
  width: 80%;
`;

export const HeaderIOS = styled.div`
  width: 100%;

  ${({ isWebviewIos, theme }) =>
    isWebviewIos &&
    `
    @media ${theme.mediaQueries.mobileIos} {
      padding-top: ${theme.space('spacing-10')};
    }
    @media ${theme.mediaQueries.mobileIosNotch} {
      padding-top: ${theme.space('spacing-20')};
    }
  `}
`;

export const Header = styled.div`
  width: 100%;
  margin-top: 0;
`;

export const NavbarContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;

  > div {
    width: 34%;
  }
`;

export const BackgroundImage = styled(Image)`
  /** The following proportions work due to the image's aspect ratio, adjust accordingly  */
  width: 100vw;
  height: 75vw;
  background-color: ${({ theme }) => theme.color('shape-color-surface-secondary')};
`;

export const HeaderContent = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
`;

export const fadeIn = keyframes`
  from {
    top: -84px;
  }
  to {
    top: 0;
  }
`;

export const fadeOut = keyframes`
  from {
    top: 0;
  }
  to {
    top: -84px;
  }
`;

export const NavbarWrapper = styled.div`
  header {
    position: fixed;
    width: 100%;
    padding: 0;
    height: fit-content;
    flex-direction: column;
    ${({ hideHeader, duration }) =>
      css`
        animation: ${hideHeader ? fadeOut : fadeIn} ${duration}ms ease-in-out backwards;
      `}
  }
`;
