import React from 'react';
import VendorItem from '@pedidosya/web-fenix/businessComponents/VendorItem';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { CardContainerWrapper, VendorItemContainer } from './styled-components';

export const ItemCard = ({ image, vendorName, discountTitle, categoryTitle, onClick }) => {
  return (
    <CardContainerWrapper>
      <VendorItemContainer>
        <VendorItem
          delivery={{}}
          images={{
            logo: image,
          }}
          vendorName={vendorName}
          labels={{
            mainCategoriesLabels: categoryTitle ? [categoryTitle] : null,

            discountLabel: discountTitle,
            conditionLabels: [
              <Typography token="font-label-midcontrast-small" color="text-color-tertiary">
                Aplican términos y condiciones
              </Typography>,
            ],
          }}
          renderFavToggle={false}
          variant="header-off"
          onClick={onClick}
        />
      </VendorItemContainer>
    </CardContainerWrapper>
  );
};
