import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Storage } from '@app/utils/storage';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { Popup as Dialog, PopupCloseIcon } from './styled-components';

const Popup = ({ id, content, delayTime, showOneTime, theme = 'dark' }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    /**
     * We are using session storage as it is reseted when the tab is closed, allowing us to
     * reopen popup when the user re-enters the webview, which does not happen neither
     * with cookies nor localStorage
     */
    const storage = new Storage(window.sessionStorage);
    const popupStorageKey = `popup-${id}-showed`;
    const showPopup = !showOneTime || !storage.getItem(popupStorageKey);

    if (showPopup) {
      setTimeout(() => {
        storage.setItem(popupStorageKey, true);
        setOpen(true);
      }, delayTime);
    }
  }, [delayTime, id, showOneTime]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <PopupCloseIcon
        size="medium"
        color={theme === 'light' ? 'default' : 'white'}
        onClick={handleClose}
      />
      <Typography
        token="text-base-large"
        id={id}
        data-testid={id}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </Dialog>
  );
};

Popup.propTypes = {
  id: PropTypes.string,
  content: PropTypes.string,
  delayTime: PropTypes.number,
  showOneTime: PropTypes.bool,
};

export default Popup;
