import { defineMessages } from 'react-intl';

export default defineMessages({
  brandError: {
    id: 'app.brandError.genericError',
    defaultMessage: 'No pudimos llevarte a donde esperabas.',
  },
  cancelModalTitle: {
    id: 'app.status.cancelModalTitle',
    defaultMessage: 'Cancelar Suscripción',
  },
  cancelModalSubtitle: {
    id: 'app.status.cancelModalSubtitle',
    defaultMessage:
      'Volverás a pagar el envío de cada pedido y dejarás de recibir descuentos exclusivos.',
  },
  cancelModalButtonAction: {
    id: 'app.status.cancelModalButtonAction',
    defaultMessage: 'Cancelar suscripción',
  },
  cancelModalBackButton: {
    id: 'app.status.cancelModalBackButton',
    defaultMessage: 'Volver',
  },
  cancelModalErrorTitle: {
    id: 'app.status.cancelModalErrorTitle',
    defaultMessage: 'No pudimos cancelar tu suscripción',
  },
  cancelModalErrorMessage: {
    id: 'app.status.cancelModalErrorMessage',
    defaultMessage: 'Intenta nuevamente en unos minutos.',
  },
  title: {
    id: 'app.status.title',
    defaultMessage: 'Tu suscripción Plus',
  },
  cancelledStatusTitle: {
    id: 'app.status.cancelledStatusTitle',
    defaultMessage: 'Cancelaste tu suscripción',
  },
  activeSubtitle: {
    id: 'app.status.activeSubtitle',
    defaultMessage:
      'La {isFirst, select, true {primera} false {próxima} other {próxima}} fecha de pago es { remainingDays, plural, =0 {hoy} one {mañana} other {en # días}}.',
  },
  helpNeeded: {
    id: 'app.status.helpNeeded',
    defaultMessage: '¿Necesitás ayuda?',
  },
  cancelButton: {
    id: 'app.status.cancelButton',
    defaultMessage: 'Cancelar suscripción',
  },
  planTitle: {
    id: 'app.status.planTitle',
    defaultMessage: 'Plan mensual',
  },
  planSubtitle: {
    id: 'app.status.planSubtitle',
    defaultMessage: 'Sos plus desde {month} {year}',
  },
  cancelledTitle: {
    id: 'app.status.cancelledTitle',
    defaultMessage: 'Finaliza el {nextChargeDate} y no se renovará.',
  },
  cancelledSubtitle: {
    id: 'app.status.cancelledSubtitle',
    defaultMessage: 'Para volver a suscribirte tienes que esperar a que termine el ciclo actual.',
  },
  planDiscountTitle: {
    id: 'app.status.planDiscountTitle',
    defaultMessage: '{discountAmountText} OFF',
  },
  promotionSubtitle: {
    id: 'app.status.promotionSubtitle',
    defaultMessage: 'Promoción hasta el {date}',
  },
  paymentsTitle: {
    id: 'app.status.paymentsTitle',
    defaultMessage: 'Historial de pagos',
  },
  paymentsSubtitle: {
    id: 'app.status.paymentsSubtitle',
    defaultMessage: 'Podés descargar la factura a tu dispositivo.',
  },
  paymentsSubtitleSend: {
    id: 'app.status.paymentsSubtitleSend',
    defaultMessage: 'Podés enviar la factura a tu cuenta de e-mail.',
  },
  paymentsContact: {
    id: 'app.status.paymentsContact',
    defaultMessage: 'Contactanos en caso de necesitar facturas previas',
  },
  paymentsSendError: {
    id: 'app.status.paymentsSendError',
    defaultMessage: 'No pudimos enviarte la factura',
  },
  paymentDocumentName: {
    id: 'app.status.paymentDocumentName',
    defaultMessage: 'Factura',
  },
  addPaymentInstrumentToChange: {
    id: 'app.status.detail.addPaymentInstrumentToChange',
    defaultMessage: 'Añadí una nueva tarjeta en tu próximo pedido para cambiar de método de pago.',
  },
});
