import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import logger from '@app/logger';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { useDeviceInfo } from '@app/contexts/deviceInfo';
import { SurveyContainer } from './styled-components';

export const Survey = ({ id, survey, onClose, onCompleted, loader, errorComponent }) => {
  const { locale, environment, country } = usePublicEnv();
  const [surveyLoaded, setSurveyLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const deviceInfo = useDeviceInfo();
  const isWebviewIos = deviceInfo.isWebview() && deviceInfo.isIos();

  useEffect(() => {
    let instance = null;
    let completed = false;
    const trackingHandler = (event) => {
      if (event.id === 'loaded') {
        setSurveyLoaded(true);
      } else if (event.id === 'error.loaded') {
        logger.error('Error loading feedback sdk');
        setHasError(true);
      }
    };

    const eventHandler = (event) => {
      if (event.event === 'close') {
        instance.umount();
        if (completed) return onCompleted();
        else {
          return onClose();
        }
      } else if (event.event === 'completed') {
        completed = true;
      }
    };

    import('@feedback/sdk').then(({ default: Feedback }) => {
      Feedback.config({
        environment: environment === 'production' ? 'production' : 'staging',
        origin: 'microsite-loyalty-program',
        language: locale,
        mode: 'full',
        customProxyHost: `${deviceInfo.isWebview() ? '/loyalty' : ''}/feedback/api`,
      });

      instance = Feedback.render({
        nodeId: id,
        survey: survey.surveyId,
        resource: survey.resourceId,
        country: country.shortName,
      });

      instance.on('TRACKING', trackingHandler);
      instance.on('EVENT', eventHandler);
    });

    return () => {
      // Remove event handlers on unmount
      if (instance) {
        instance.off('TRACKING', trackingHandler);
        instance.off('EVENT', eventHandler);
      }
    };
  }, []);

  return (
    <>
      {!surveyLoaded && loader}
      {surveyLoaded && hasError && errorComponent}
      <SurveyContainer
        data-testid={id}
        id={id}
        loaded={surveyLoaded}
        hasError={hasError}
        isWebviewIos={isWebviewIos}
      />
    </>
  );
};

Survey.propTypes = {
  id: PropTypes.string.isRequired,
  survey: PropTypes.shape({
    surveyId: PropTypes.string,
    resourceId: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func,
  onCompleted: PropTypes.func,
  loader: PropTypes.node,
  errorComponent: PropTypes.node,
};
