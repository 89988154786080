import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Tag from '@pedidosya/web-fenix/atoms/Tag';
import { DISCOUNT_TYPE } from '@commons/models/discountTypeEnum';
import messages from '../plan/messages';

const TagDeal = ({ discountAmount, discountType }) => {
  const getDiscountText = (amount, discountType) => {
    switch (discountType) {
      case DISCOUNT_TYPE.PERCENTAGE:
        return `${amount}%`;
      default:
        amount;
    }
  };

  return (
    <Tag
      category="deals-and-discounts"
      size="small"
      label={
        <FormattedMessage
          {...messages.discountFooter}
          values={{
            discountAmountText: getDiscountText(Math.floor(discountAmount), discountType),
            discountAmount,
          }}
        />
      }
    />
  );
};

TagDeal.propTypes = {
  discountAmount: PropTypes.number,
  discountType: PropTypes.string,
};

export default TagDeal;
