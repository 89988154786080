import axios from 'axios';
import logger from '@app/logger';

export const acceptTermsAndConditions = async ({ planId, countryId }) => {
  try {
    const { data } = await axios.post(`/loyalty/api/subscription/terms`, { planId, countryId });
    return data;
  } catch (error) {
    logger.error(
      `Error accepting terms and conditions for plan: ${planId} and country: ${countryId}`,
    );
    throw error;
  }
};
