import styled from 'styled-components';
import BasePromotionItemContainer from './PromotionItemContainer';

const PROMOTION_ICON_SIZE = '48px';
const PROMOTION_OPEN_ICON_SIZE = '16px';

export const PromotionsListTitle = styled.div`
  text-align: center;
  padding: ${({ theme }) => `${theme.space('spacing-03')} 0`};

  > [aria-label='term-and-conditions'] {
    display: flex;
    align-items: center;
    justify-content: center;

    > svg:first-child {
      position: absolute;
      left: 16px;
    }
  }
`;

export const Container = styled.div`
  padding: ${({ theme }) => `0 ${theme.space('spacing-12')}`};
  width: 100%;
`;

export const SkeletonContainer = styled.div`
  padding: ${({ theme }) => `0 ${theme.space('spacing-12')} ${theme.space('spacing-14')}`};
  width: 100%;
`;

export const PromotionItemContainer = styled(BasePromotionItemContainer)`
  display: grid;
  /* icon - promotion data - chevron right */
  grid-template-columns: ${PROMOTION_ICON_SIZE} 1fr ${PROMOTION_OPEN_ICON_SIZE};
  grid-gap: 8px;
  padding: 16px 0;
`;

export const TCContainer = styled.div`
  padding: ${({ theme }) => theme.space('spacing-08')};

  ${({ isWebviewIos, theme }) =>
    `${
      isWebviewIos
        ? `
      @media ${theme.mediaQueries.mobileIosNotch} {
        padding-bottom: ${({ theme }) => theme.space('spacing-15')};
      }
    `
        : ''
    }`}
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DescriptionText = styled.label`
  margin: ${({ theme }) => `${theme.space('spacing-04')} 0`};
`;

export const Paragraph = styled.div`
  margin-bottom: ${({ theme }) => theme.space('spacing-10')};
  width: 100%;

  > * {
    margin-bottom: ${({ theme }) => theme.space('spacing-04')};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
