import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { fetchStatusBill } from '@app/services/loyalty/fetchStatusBill';
import Image from '@pedidosya/web-fenix/foundations/Image';
import logger from '@app/logger';
import DownloadIcon from '@app/assets/download.svg';
import Button from '@pedidosya/web-fenix/atoms/Button';
import { BillButtonWrapper } from './styled-components';

const DownloadBillButton = ({ billId, planId, preventShare = false }) => {
  const filename = `${billId}.pdf`;

  const fetchStatusBillMutation = useMutation(fetchStatusBill);
  const downloadBill = async () => {
    try {
      const blob = await fetchStatusBillMutation.mutateAsync({ billId, planId });
      const file = new File([blob], filename, { type: 'application/pdf' });
      const shareData = {
        title: filename,
        files: [file],
      };

      if (!preventShare && navigator.canShare && navigator.canShare(shareData)) {
        await navigator.share(shareData);
      } else {
        const URL = window.URL || window.webkitURL;
        const bloblUrl = URL.createObjectURL(blob);
        const anchor = document.createElement('a');

        document.body.appendChild(anchor);

        anchor.style = 'display: none';
        anchor.href = bloblUrl;
        anchor.download = filename;
        anchor.rel = 'noopener'; // tabnabbing
        anchor.click();

        URL.revokeObjectURL(bloblUrl);
      }
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <BillButtonWrapper>
      <Button
        onClick={downloadBill}
        icon={<Image src={DownloadIcon} alt="download" height={16} width={16} />}
        label="Descargar"
        size="medium"
        anatomy="right-icon"
        hierarchy="tertiary"
        state={fetchStatusBillMutation.isLoading ? 'disabled' : 'enabled'}
      />
    </BillButtonWrapper>
  );
};

DownloadBillButton.propTypes = {
  billId: PropTypes.string,
  planId: PropTypes.number,
  amount: PropTypes.number,
  date: PropTypes.string,
  onError: PropTypes.func,
};

export default DownloadBillButton;
