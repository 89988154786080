import React from 'react';
import FenixSkeleton from '@pedidosya/web-fenix/atoms/Skeleton';
import { SkeletonContainer, Header, RowContainer, ContactText } from './styled-components';

const ROWS = 4;

const Skeleton = () => {
  return (
    <SkeletonContainer role="progressbar">
      <Header>
        <FenixSkeleton shape="square" height="shape-size-action-4" width="shape-size-action-65" />
      </Header>
      <Header>
        <FenixSkeleton shape="square" height="shape-size-action-3" width="shape-size-action-40" />
      </Header>

      <FenixSkeleton shape="square" height="shape-size-action-1" />
      {Array.apply(0, Array(ROWS)).map((x, idx) => (
        <div key={idx}>
          <RowContainer>
            <FenixSkeleton
              shape="square"
              height="shape-size-action-3"
              width="shape-size-action-20"
            />
            <FenixSkeleton shape="square" height="shape-size-action-3" width="80px" />
            <FenixSkeleton shape="square" height="shape-size-action-3" width="80px" />
          </RowContainer>
        </div>
      ))}
      <FenixSkeleton shape="square" height="shape-size-action-1" />
      <ContactText>
        <FenixSkeleton shape="square" height="shape-size-action-2" width="shape-size-action-40" />
      </ContactText>
    </SkeletonContainer>
  );
};

Skeleton.propTypes = {};

export default Skeleton;
