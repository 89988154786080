import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from '@app/components/Accordion';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

export const FAQ = ({ questions, onQuestionClick = Function.prototype }) => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (id) => setActiveAccordion(activeAccordion === id ? null : id);

  const handleAccordionToggle = (id, trackId) => {
    onQuestionClick(id, { open: Boolean(activeAccordion === id ? null : id), trackId });
    toggleAccordion(id);
  };

  return (
    <>
      <div>
        {questions.map(({ id, question, answer, trackId }) => (
          <Accordion
            key={id}
            expanded={activeAccordion === id}
            title={question}
            onToggle={() => handleAccordionToggle(id, trackId)}
          >
            <Typography token="text-base-large" color="text-color-tertiary">
              {answer}
            </Typography>
          </Accordion>
        ))}
      </div>
    </>
  );
};

FAQ.propTypes = {
  planId: PropTypes.number,
  question: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      question: PropTypes.string,
      answer: PropTypes.string,
    }),
  ),
  termsAndConditionsTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onQuestionClick: PropTypes.func,
  showTermsAndConditions: PropTypes.bool,
  onCloseTermsAndConditions: PropTypes.func,
};
