export const HOME_LINKS = {
  WEB: '/',
  IOS: 'pedidosya://launcher',
  ANDROID: 'pedidosya://',
};

export const VENDOR_CHANNEL_LINKS = {
  WEB: (id, citySlug) => `/restaurantes/${citySlug}?channels=${id}`,
  IOS: (id) => `pedidosya://restaurantes?channels=${id}`,
  ANDROID: (id) => `pedidosya://restaurantes?channels=${id}`,
};

const WEB = 'web';
const HOME_CONFIG = {
  ios: HOME_LINKS.IOS,
  android: HOME_LINKS.ANDROID,
  web: HOME_LINKS.WEB,
};

export const getHomeLink = ({ os = '', isWebview = () => false }) => {
  const option = (!isWebview() && WEB) || os;
  return HOME_CONFIG[option] || '';
};
