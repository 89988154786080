import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: ${({ theme }) =>
    `${theme.space('spacing-component-2xlarge')} ${theme.space(
      'layout-margin-mobile',
    )} ${theme.space('spacing-component-4xlarge')}`};
`;
