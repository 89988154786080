import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import FenixBottomSheet from '@pedidosya/web-fenix/organisms/BottomSheet';
import { ButtonLink } from '@app/components/ButtonLink';
import { BenefitDetailIcon } from '@app/components/BenefitDetailIcon';
import { decorateTitle } from '@app/utils/benefits';
import { ConditionsWrapper, BenefitTitle, DescriptionContainer } from './styled-components';
import { BenefitConditions } from './BenefitConditions';

export const BenefitDetail = ({ benefit, onTermsAndConditionsClick = Function.prototype }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { description } = benefit;

  const openTermsAndConditions = () => {
    onTermsAndConditionsClick();
    setIsOpen(true);
  };

  return (
    <div>
      <BenefitDetailIcon type={benefit.type} />
      <BenefitTitle>
        <Typography token="text-title-medium">{decorateTitle(benefit.title)}</Typography>
      </BenefitTitle>
      <DescriptionContainer aria-label="benefit-detail-description">
        <Typography
          token="text-base-large"
          color="shape-color-surface-secondary"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </DescriptionContainer>
      {benefit.fenix_terms_and_conditions && (
        <>
          <ConditionsWrapper>
            <ButtonLink onClick={openTermsAndConditions}>
              <Typography token="text-utility-underline-medium">Ver condiciones</Typography>
            </ButtonLink>
          </ConditionsWrapper>

          <FenixBottomSheet
            handler={true}
            open={isOpen}
            title="Condiciones"
            scrollEnabled={true}
            onClickOut={() => {
              setIsOpen(!isOpen);
            }}
            onClose={() => {
              setIsOpen(!isOpen);
            }}
          >
            <BenefitConditions
              title={benefit.fenix_terms_and_conditions.title}
              icon={benefit.fenix_terms_and_conditions.img}
              bullets={benefit.fenix_terms_and_conditions.benefits}
            />
          </FenixBottomSheet>
        </>
      )}
    </div>
  );
};

BenefitDetail.propTypes = {
  benefit: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    terms_and_conditions: PropTypes.string,
  }),
  onTermsAndConditionsClick: PropTypes.func,
};
