/* eslint-disable no-console */
import axios from 'axios';
import logger from '@app/logger';

export const sendBillMail = async ({ billId, planId, amount, date }) => {
  try {
    const subscription = await axios.post(`/loyalty/api/status/bill/mail`, {
      billId,
      planId,
      amount,
      date,
    });
    return subscription.data;
  } catch (error) {
    logger.error(`Error sending bill mail`);
    throw error;
  }
};
