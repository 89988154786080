import React from 'react';
import PropTypes from 'prop-types';
import semverValid from 'semver/functions/valid';
import semverCoerce from 'semver/functions/coerce';

const DeviceInfoContext = React.createContext();

export const useDeviceInfo = () => {
  const value = React.useContext(DeviceInfoContext);

  return {
    ...value,
    isWebview: () => value.isWebview,
    isAndroid: () => value.os === 'android',
    isIos: () => value.os === 'ios',
    appVersion: semverValid(semverCoerce(value.appVersion)),
  };
};

export const DeviceInfoProvider = ({ value, children }) => (
  <DeviceInfoContext.Provider value={value}>{children}</DeviceInfoContext.Provider>
);

DeviceInfoProvider.propTypes = {
  value: PropTypes.shape({
    type: PropTypes.string,
    os: PropTypes.string,
    isWebview: PropTypes.bool,
  }),
  children: PropTypes.node.isRequired,
};

export default DeviceInfoContext;
