import React from 'react';
import { ButtonLinkContainer, ButtonLinkWrapper } from './styled-components';

const ButtonLink = ({ onClick, children }) => {
  return (
    <ButtonLinkContainer>
      <ButtonLinkWrapper label={children} size="large" hierarchy="tertiary" onClick={onClick} />
    </ButtonLinkContainer>
  );
};

export default ButtonLink;
