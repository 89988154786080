import axios from 'axios';
import logger from '@app/logger';

export const fetchFavoriteVendors = async () => {
  try {
    const vendorsResponse = await axios.get('/loyalty/api/favoriteVendors');

    return vendorsResponse.data;
  } catch (error) {
    logger.error('Error getting favorite vendors data');
    throw error;
  }
};
