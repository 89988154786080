import React from 'react';

function Terms({ isIos }) {
  return (
    <div
      data-testid="upcoming-tyc-ar"
      style={{
        marginTop: isIos && '64px',
        marginLeft: '12px',
        marginRight: '12px',
        marginBottom: '12px',
      }}
    >
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html: `\n * { font-size: 13.5px;\n font-family: Segma, sans-serif;\n  }   p.p1 {\n        margin: 0.0px 0.0px 0.0px 0.0px;\n        text-align: center;\n             background-color: #ffffff\n    }\n\n    p.p2 {\n        margin: 0.0px 0.0px 0.0px 0.0px;\n        text-align: center;\n           background-color: #ffffff;\n        min-height: 16.0px\n    }\n\n    li.li3 {\n        margin: 0.0px 0.0px 0.0px 0.0px;\n        text-align: justify;\n                color: #000000\n    }\n\n    li.li4 {\n        margin: 0.0px 0.0px 0.0px 0.0px;\n           color: #000000\n    }\n\n    span.s1 {\n        background-color: #ffffff\n    }\n\n    span.s2 {\n        text-decoration: underline;\n        color: #0000ff\n    }\n\n    ol.ol1 {\n        list-style-type: decimal\n    }\n\n    ol.ol2 {\n        list-style-type: lower-alpha\n    }\n\n    ol.ol3 {\n        list-style-type: lower-roman\n   }\n`,
        }}
      />
      <p>
        <strong>Términos &amp; Condiciones – Programa PEDIDOSYA Plus</strong>
      </p>
      <p>
        <br />
      </p>
      <ol>
        <li style={{ 'list-style-position': 'inside' }}>
          Administrador: DELIVERY HERO E- COMMERCE S.A., CUIT 30-71198576-6 (en adelante,
          “PEDIDOSYA” o el “Administrador”), con domicilio en Av. del Libertador 7208, Piso 20 de la
          Ciudad Autónoma de Buenos Aires, Argentina, llevará a cabo el Programa de fidelización
          para los clientes de PEDIDOSYA denominado “PedidosYa Plus” (en adelante, el “Programa”),
          que permite a los interesados que se suscriban al mismo, acceder a beneficios, descuentos
          y promociones especiales en la utilización de los servicios de PEDIDOSYA.
        </li>
        <li style={{ 'list-style-position': 'inside' }}>
          Vigencia: El Programa tendrá vigencia exclusivamente en las ciudades de la República
          Argentina, donde esté disponible el servicio de logística de PEDIDOSYA (en adelante, el
          “Territorio”), y se regirá por los presentes términos y condiciones (en adelante, los
          “Términos”). Sin perjuicio de lo mencionado anteriormente, el Usuario deberá estar ubicado
          en una ciudad que haya sido seleccionada por PEDIDOSYA (en adelante, “Ciudad
          Participante”) para tener activo el servicio de PedidosYa Plus. La Ciudad Participante
          será comunicada en el momento que el Usuario se suscriba al Servicio a través del Portal
          de PEDIDOSYA Plus.
        </li>
        <li style={{ 'list-style-position': 'inside' }}>
          Participantes: Podrán suscribirse a este Programa, todas las personas humanas, en forma
          individual, con domicilio y/o residencia en el Territorio, siempre que sean usuarios de
          PEDIDOSYA y cuenten con una tarjeta de crédito o débito habilitada para funcionar en el
          Territorio (en adelante, los “Participantes”).
        </li>
        <li style={{ 'list-style-position': 'inside' }}>
          Aceptación de los Términos: La participación en el Programa, como Participante, implica
          como condición el conocimiento y la aceptación de los presentes Términos. Serán además de
          aplicación los{' '}
          <a href="https://www.pedidosya.com.ar/about/tyc_generals">
            Términos y Condiciones Generales de PedidosYa
          </a>{' '}
          y su{' '}
          <a href="https://www.pedidosya.com.ar/about/privacy_policies">
            Política de Tratamiento de Datos Personales
          </a>
          .
        </li>
        <li style={{ 'list-style-position': 'inside' }}>
          Beneficios: Los Participantes que cuenten con su suscripción activa podrán acceder a los
          siguientes beneficios:
          <ol>
            <li style={{ 'list-style-type': 'lower-alpha', marginLeft: '30px' }}>
              Beneficio de envíos gratuitos o costo de envío reducido en Comercios Adheridos que
              serán identificados en el portal de PedidosYa con la bandera de PEDIDOSYA PLUS (“P+").
              Para acceder a este beneficio, los participantes deberán cumplir con los siguientes
              requisitos:
              <ol>
                <li style={{ 'list-style-type': 'lower-roman', marginLeft: '40px' }}>
                  La orden deberá efectuarse en un Comercio Adherido que cuente con envío a cargo de
                  PedidosYa. El servicio de “PedidosYa Envíos” queda excluido del presente Programa,
                  como así también, aquellos comercios que no cuenten con envío a cargo de PedidosYa
                  (es decir, aquellos que se encuentran identificados en el Portal con el botón
                  “Envío del Local”).
                </li>
                <li style={{ 'list-style-type': 'lower-roman', marginLeft: '40px' }}>
                  La orden deberá ser pagada mediante pago en efectivo u online (es decir, con
                  tarjeta de crédito o débito).
                </li>
                <li style={{ 'list-style-type': 'lower-roman', marginLeft: '40px' }}>
                  El monto mínimo de la orden será el que se indica en la sección del{' '}
                  <a href="https://www.pedidosya.com.ar/loyalty">Programa</a>, dentro del Portal de
                  PEDIDOSYA. Podrán existir ciertos Comercios Adheridos que exijan un monto mínimo
                  para poder procesar sus órdenes. En tal caso, si el monto de compra mínima del
                  Comercio Adherido es superior al monto anteriormente indicado, deberán de
                  cumplirse ambos requisitos para que la orden pueda ser procesada.&nbsp;
                </li>
                <li style={{ 'list-style-type': 'lower-roman', marginLeft: '40px' }}>
                  El descuento se aplicará de manera automática en el checkout de la orden siempre y
                  cuando que la misma cumpla con las restricciones aquí mencionadas.
                </li>
                <li style={{ 'list-style-type': 'lower-roman', marginLeft: '40px' }}>
                  La bonificación del envío sólo alcanza al “Costo de Envío”, no quedando cubiertos
                  por el Programa aquellos cargos por servicio/service fee que PedidosYa pudiera
                  cobrar o asimismo, propinas que el Participante desee otorgarle al repartidor, de
                  manera voluntaria.
                </li>
              </ol>
            </li>
            <li style={{ 'list-style-type': 'lower-alpha', marginLeft: '30px' }}>
              Beneficios especiales: Ocasionalmente PedidosYa podrá ofrecer a su entera discreción,
              ciertos beneficios a los Participantes del Programa PedidosYa Plus. Estos beneficios
              estarán sujetos a estos mismos Términos, salvo que se indique lo contrario en la
              oferta promocional específica.
            </li>
          </ol>
        </li>
        <li style={{ 'list-style-position': 'inside' }}>
          Suscripción:
          <ol>
            <li style={{ 'list-style-type': 'lower-alpha', marginLeft: '30px' }}>
              Los interesados que reúnan los requisitos antes mencionados podrán suscribirse al
              Programa PedidosYA Plus, abonando el costo de suscripción mensual que se informa en la
              sección del <a href="https://www.pedidosya.com.ar/loyalty">Programa</a>, dentro del
              Portal de PEDIDOSYA y que será realizado por adelantado (en adelante, la “Tarifa”).
            </li>
            <li style={{ 'list-style-type': 'lower-alpha', marginLeft: '30px' }}>
              El pago de la suscripción a PedidosYa Plus deberá realizarse con una tarjeta de
              crédito o débito del Participante (en adelante, el “Método de pago”). Podrá incluso,
              mediante la aceptación en el Portal,&nbsp;&nbsp;dar su consentimiento para que
              PEDIDOSYA pueda utilizar cualquiera de las tarjetas guardadas en el perfil del
              Participante como Método de pago, donde se le debitará la Tarifa del Programa
              PedidosYa Plus.
            </li>
            <li style={{ 'list-style-type': 'lower-alpha', marginLeft: '30px' }}>
              Una vez realizado el pago de la suscripción, se dará inicio al período de suscripción
              (en adelante, el “Período de Suscripción”) que será de un mes calendario. Asimismo, el
              Participante recibirá al correo electrónico asociado a su cuenta de PedidosYa la
              factura correspondiente al mes de suscripción.
            </li>
            <li style={{ 'list-style-type': 'lower-alpha', marginLeft: '30px' }}>
              Vencido el plazo mensual de la suscripción, la misma se renovará automáticamente, de
              modo mensual.{' '}
              <strong>
                En caso de no querer continuar con la suscripción, el Participante deberá de
                proceder a solicitar la baja de la misma, tal como se menciona en el apartado 9 de
                los presentes Términos.
              </strong>{' '}
              En el supuesto que el débito de la tarjeta de crédito o débito no pueda realizarse, el
              servicio de suscripción se cancelará, informando tal situación al Participante.
            </li>
          </ol>
        </li>
        <li style={{ 'list-style-position': 'inside' }}>
          Pago fallido: si el pago de la suscripción no es debitado con éxito, debido al vencimiento
          de su Método de pago, fondos insuficientes u otros, no se renovará la suscripción.
        </li>
        <li style={{ 'list-style-position': 'inside' }}>
          El costo de suscripción es informado al momento de la suscripción y cualquier modificación
          en el mismo será indicada en el apartado de PedidosYa Plus que se encuentra en la sección
          “Mi Perfil”. Los usuarios podrán dar de baja la suscripción sin costo y en cualquier
          momento. En caso de que se den de baja antes de que se haga efectiva la modificación del
          costo de la suscripción, el Programa no se activará ese período.
        </li>
        <li style={{ 'list-style-position': 'inside' }}>
          Baja de la suscripción: La suscripción mensual podrá darse de baja en cualquier momento y
          sin cargo, mediante el botón de cancelación que se visualiza dentro del apartado del
          Portal correspondiente a PedidosYa Plus. El Participante gozará de los beneficios por los
          días restantes del mes, no reintegrándose monto alguno proporcional por los días
          restantes. PedidosYa comprende y acepta que la cancelación pueda tardar hasta 72 horas
          hábiles.
        </li>
        <li style={{ 'list-style-position': 'inside' }}>
          Datos Personales: El titular de los datos personales tiene la facultad de ejercer el
          derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a seis (6)
          meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el
          artículo 14, inciso 3 de la Ley Nº 25.326. La Agencia de Acceso a la Información Pública,
          Órgano de Control de la Ley Nº 25.326, tiene la atribución de atender las denuncias y
          reclamos que se interpongan con relación al cumplimiento de las normas sobre datos
          personales. Al momento de facilitar al Administrador sus datos, los Participantes prestan
          expreso consentimiento para que tales datos puedan ser utilizados por el Organizador con
          fines publicitarios y de marketing en general. La información será tratada en los términos
          previstos por la Ley Nacional de Protección de Datos Personales Nº 25.326. El titular del
          dato podrá solicitar el retiro o bloqueo total o parcial de su nombre de las bases de
          datos comunicándose al correo electrónico datospersonales@pedidosya.com. El retiro o
          bloqueo parcial de los datos implica la no participación en el Entretenimiento, en tanto
          los mismos son requeridos para su participación.
        </li>
        <li style={{ 'list-style-position': 'inside' }}>
          Responsabilidad: La participación y permanencia en este Programa es intransferible y será
          libre y voluntaria para los Participantes. El Administrador se reserva el derecho de
          admisión y/o participación y/o permanencia de los Participantes, quedando el Administrador
          facultado para dar de baja, anular, descalificar y/o eliminar a los Participantes, si
          detectare que algún Participante se hubiera conducido de manera contraria a estos Términos
          o hubiere intentando alguna conducta fraudulenta respecto del Programa y su
          funcionamiento.
        </li>
        <li style={{ 'list-style-position': 'inside' }}>
          Derecho aplicable y jurisdicción: Serán de aplicación en el supuesto de controversias, las
          leyes vigentes en la República Argentina y serán competentes los Tribunales Ordinarios con
          asiento en la Ciudad de Buenos Aires o los del domicilio del Usuario, pudiendo los
          Usuarios optar por alguna de esas opciones.
        </li>
      </ol>
    </div>
  );
}

export default Terms;
